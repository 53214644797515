import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Collapse,
  CardHeader,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import UserList from "./UserList";
import listUsersRequest from "../../api/user/listUsersRequest";
import ListFilterOptions from "../../api/ListFilterOptions";
import useSearch from "../../hooks/useSearch";
import SearchCities from "../../api/search/SearchCities";
import uniqueMergeArrays from "../../utils/uniqueMergeArrays";
import customFilterForSelect from "../../utils/customFilterForSelect";
import SearchFederations from "../../api/search/SearchFederations";
import SearchAssociations from "../../api/search/SearchAssociations";

const StyledSelect = styled(Select)`
  .select__control {
  }
  .select__value-container {
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;

const SearchPage = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Arama", link: "#" },
    { title: "Detaylı Arama", link: "#" },
  ]);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [associations, setAssociations] = useState([]);
  const [federations, setFederations] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [regions, setRegions] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [companyName, setCompanyName] = useState("");

  //city
  const [citySearching, setCitySearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [citiesOptions, setCitiesOptions] = useState([]);
  useSearch(searchText, () => citySearchWithDelay());

  //federation
  const [federationSearching, setFederationSearching] = useState(false);
  const [searchFedText, setSearchFedText] = useState("");
  const [federationOptions, setFederationOptions] = useState([]);
  useSearch(searchFedText, () => federationSearchWithDelay());

  //association
  const [associationSearching, setAssociationSearching] = useState(false);
  const [searchAssocText, setSearchAssocText] = useState("");
  const [associationOptions, setAssociationOptions] = useState([]);
  useSearch(searchAssocText, () => associationSearchWithDelay());

  const citySearch = (searchQuery) => {
    if (!searchQuery) return;
    setCitySearching(true);
    setSearchText(searchQuery);
  };

  const citySearchWithDelay = () => {
    SearchCities({
      title: searchText,
    }).then((r) => {
      setCitySearching(false);

      if (r.status !== "success") return;
      setCitiesOptions(uniqueMergeArrays(citiesOptions, r.data));
    });
  };

  const federationSearch = (searchQuery) => {
    if (!searchQuery) return;
    setFederationSearching(true);
    setSearchFedText(searchQuery);
  };

  const federationSearchWithDelay = () => {
    SearchFederations({
      title: searchFedText,
    }).then((r) => {
      setFederationSearching(false);

      if (r.status !== "success") return;
      setFederationOptions(uniqueMergeArrays(federationOptions, r.data));
    });
  };

  const associationSearch = (searchQuery) => {
    if (!searchQuery) return;
    setAssociationSearching(true);
    setSearchAssocText(searchQuery);
  };

  const associationSearchWithDelay = () => {
    SearchAssociations({
      title: searchAssocText,
    }).then((r) => {
      setAssociationSearching(false);

      if (r.status !== "success") return;
      setAssociationOptions(uniqueMergeArrays(associationOptions, r.data));
    });
  };

  useEffect(() => {
    ListFilterOptions().then((r) => {
      if (r.status === "success") {
        setAssociations(r.data.associations);
        setFederations(r.data.federations);
        setCountries(r.data.countries);
        setSectors(r.data.sectors);
        setRegions(r.data.regions);
      }
    });

    SearchCities({
      title: "",
    }).then((r) => {
      if (r.status !== "success") return;
      setCitiesOptions(uniqueMergeArrays(citiesOptions, r.data));
    });

    SearchFederations({
      title: "",
    }).then((r) => {
      if (r.status !== "success") return;
      setFederationOptions(uniqueMergeArrays(federationOptions, r.data));
    });

    SearchAssociations({
      title: "",
    }).then((r) => {
      if (r.status !== "success") return;
      setAssociationOptions(uniqueMergeArrays(associationOptions, r.data));
    });
  }, [props]);
  useEffect(() => {
    setIsLoading(true);
    listUsersRequest(currentPage, filters).then((r) => {
      if (r.status === "success") {
        const { data, ...pagination } = r.data;
        setUsers(data);
        setPagination(
          pagination.links.map((link) => {
            link.page_number = null;
            if (link.label == "&laquo; Previous") {
              link.label = "Önceki";
            }

            if (link.label == "Next &raquo;") {
              link.label = "Sonraki";
            }

            if (!link.url) {
              return link;
            }

            const page_number = link.url.split("=")[1];
            link.page_number = page_number;
            return link;
          })
        );
        setIsLoading(false);
      }
    });
  }, [props, currentPage]);

  const [regionsCollapse, setRegionsCollapse] = useState(false);
  const [sectorsCollapse, setSectorsCollapse] = useState(false);
  const [citiesCollapse, setCitiesCollapse] = useState(false);
  const [associationsCollapse, setAssociationsCollapse] = useState(true);
  const [federationsCollapse, setFederationsCollapse] = useState(false);
  const [countriesCollapse, setCountriesCollapse] = useState(false);
  const [filterToggle, setFilterToggle] = useState(true);
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    title: "",
    company_name: "",
    association_ids: null,
    federation_ids: null,
    region_ids: null,
    country_id: "",
    city_ids: null,
    sector_ids: null,
  });

  const updateFilter = (key, value) => {
    filters[key] = value;
    setFilters(filters);
    setIsLoading(true);
    listUsersRequest(currentPage, filters).then((r) => {
      if (r.status === "success") {
        const { data, ...pagination } = r.data;
        setUsers(data);
        setPagination(
          pagination.links.map((link) => {
            link.page_number = null;
            if (link.label == "&laquo; Previous") {
              link.label = "Önceki";
            }

            if (link.label == "Next &raquo;") {
              link.label = "Sonraki";
            }

            if (!link.url) {
              return link;
            }

            const page_number = link.url.split("=")[1];
            link.page_number = page_number;
            return link;
          })
        );
        setCurrentPage(1);
        setIsLoading(false);
      }
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Detaylı Arama"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl={filterToggle ? 3 : 2} lg={filterToggle ? 3 : 2}>
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <h5
                    className="mb-0"
                    onClick={() => setFilterToggle(!filterToggle)}
                    style={{ cursor: "pointer" }}
                  >
                    Filtreler{" "}
                    <span className="font-size-12">
                      {filterToggle ? "(gizle)" : "(görüntüle)"}
                    </span>
                  </h5>
                </CardHeader>
                {filterToggle && (
                  <>
                    <Col md={12}>
                      <Input
                        className="form-control mt-4"
                        type="text"
                        defaultValue=""
                        placeholder="İsim Soyisim"
                        id="name-text-input"
                        onChange={(e) => updateFilter("name", e.target.value)}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        className="form-control mt-2"
                        type="email"
                        defaultValue=""
                        placeholder="E-posta"
                        id="email-text-input"
                        onChange={(e) => updateFilter("email", e.target.value)}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        className="form-control mt-2"
                        type="text"
                        defaultValue=""
                        placeholder="Unvan"
                        id="title-text-input"
                        onChange={(e) => updateFilter("title", e.target.value)}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        className="form-control mt-2 mb-4"
                        type="text"
                        defaultValue=""
                        placeholder="Firma"
                        id="example-text-input"
                        onChange={(e) =>
                          updateFilter("company_name", e.target.value)
                        }
                      />
                    </Col>
                    <Col md={12} className="mt-1">
                      <Label htmlFor="city" className="col-form-label">
                        Bölge
                      </Label>
                      <StyledSelect
                        id="city_error"
                        classNamePrefix="select"
                        value={
                          filters.region_ids &&
                          regions.filter((option) =>
                            filters.region_ids.some(
                              (regionId) => option.id == regionId
                            )
                          )
                        }
                        isMulti={true}
                        placeholder={"Seçiniz"}
                        noOptionsMessage={() =>
                          "arama kriterlerinize uygun sonuç bulunamadı"
                        }
                        loadingMessage={() => "arama yapılıyor..."}
                        isSearchable={true}
                        //isLoading={citySearching}
                        //onInputChange={(e) => citySearch(e)}
                        /* 
                    filterOption={(option, searchText) =>
                      customFilterForSelect(option, searchText, "title")
                    } 
                    */
                        options={[...regions, { id: null, title: "Hiçbiri" }]}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.title}
                        onChange={(selection) => {
                          if (!selection) {
                            updateFilter("region_ids", null);
                            return;
                          }
                          updateFilter(
                            "region_ids",
                            selection.map((item) => item.id)
                          );
                        }}
                        isClearable={true}
                      />
                    </Col>

                    <Col md={12} className="mt-1">
                      <Label htmlFor="city" className="col-form-label">
                        Şehir
                      </Label>
                      <StyledSelect
                        id="city_error"
                        classNamePrefix="select"
                        value={
                          filters.city_ids &&
                          citiesOptions.filter((option) =>
                            filters.city_ids.some(
                              (cityId) => option.id == cityId
                            )
                          )
                        }
                        isMulti={true}
                        placeholder={"Seçiniz"}
                        noOptionsMessage={() =>
                          "arama kriterlerinize uygun sonuç bulunamadı"
                        }
                        loadingMessage={() => "arama yapılıyor..."}
                        isSearchable={true}
                        isLoading={citySearching}
                        onInputChange={(e) => citySearch(e)}
                        filterOption={(option, searchText) =>
                          customFilterForSelect(option, searchText, "title")
                        }
                        name="city_error"
                        options={[
                          ...citiesOptions,
                          { id: null, title: "Hiçbiri" },
                        ]}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.title}
                        onChange={(selection) => {
                          if (!selection) {
                            updateFilter("city_ids", null);
                            return;
                          }
                          updateFilter(
                            "city_ids",
                            selection.map((item) => item.id)
                          );
                        }}
                        isClearable={true}
                      />
                    </Col>
                    <Col md={12} className="mt-1">
                      <Label htmlFor="federation" className="col-form-label">
                        Federasyon
                      </Label>
                      <StyledSelect
                        id="federation"
                        classNamePrefix="select"
                        value={
                          filters.federation_ids &&
                          federationOptions.filter((option) =>
                            filters.federation_ids.some(
                              (federationId) => option.id == federationId
                            )
                          )
                        }
                        isMulti={true}
                        placeholder={"Seçiniz"}
                        noOptionsMessage={() =>
                          "arama kriterlerinize uygun sonuç bulunamadı"
                        }
                        loadingMessage={() => "arama yapılıyor..."}
                        isSearchable={true}
                        isLoading={federationSearching}
                        onInputChange={(e) => federationSearch(e)}
                        filterOption={(option, searchText) =>
                          customFilterForSelect(option, searchText, "title")
                        }
                        name="federation"
                        options={[
                          ...federationOptions,
                          { id: null, title: "Hiçbiri" },
                        ]}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.title}
                        onChange={(selection) => {
                          if (!selection) {
                            updateFilter("federation_ids", null);
                            return;
                          }
                          updateFilter(
                            "federation_ids",
                            selection.map((item) => item.id)
                          );
                        }}
                        isClearable={true}
                      />
                    </Col>
                    <Col md={12} className="mt-1">
                      <Label htmlFor="association" className="col-form-label">
                        Dernek
                      </Label>
                      <StyledSelect
                        id="association"
                        classNamePrefix="select"
                        value={
                          filters.association_ids &&
                          associationOptions.filter((option) =>
                            filters.association_ids.some(
                              (associationId) => option.id == associationId
                            )
                          )
                        }
                        isMulti={true}
                        placeholder={"Seçiniz"}
                        noOptionsMessage={() =>
                          "arama kriterlerinize uygun sonuç bulunamadı"
                        }
                        loadingMessage={() => "arama yapılıyor..."}
                        isSearchable={true}
                        isLoading={associationSearching}
                        onInputChange={(e) => associationSearch(e)}
                        filterOption={(option, searchText) =>
                          customFilterForSelect(option, searchText, "title")
                        }
                        name="association"
                        options={[
                          ...associationOptions,
                          { id: null, title: "Hiçbiri" },
                        ]}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.title}
                        onChange={(selection) => {
                          if (!selection) {
                            updateFilter("association_ids", null);
                            return;
                          }
                          updateFilter(
                            "association_ids",
                            selection.map((item) => item.id)
                          );
                        }}
                        isClearable={true}
                      />
                    </Col>
                    <Col md={12} className="mt-1 mb-4">
                      <Label htmlFor="sector" className="col-form-label">
                        Sektör
                      </Label>
                      <StyledSelect
                        id="sector"
                        classNamePrefix="select"
                        value={
                          filters.sector_ids &&
                          sectors.filter((option) =>
                            filters.sector_ids.some(
                              (sectorId) => option.id == sectorId
                            )
                          )
                        }
                        isMulti={true}
                        placeholder={"Seçiniz"}
                        noOptionsMessage={() =>
                          "arama kriterlerinize uygun sonuç bulunamadı"
                        }
                        loadingMessage={() => "arama yapılıyor..."}
                        isSearchable={true}
                        /*   isLoading={associationSearching}
                    onInputChange={(e) => associationSearch(e)}
                    filterOption={(option, searchText) =>
                      customFilterForSelect(option, searchText, "title")
                    } */
                        name="sector"
                        options={[...sectors, { id: null, title: "Hiçbiri" }]}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.title}
                        onChange={(selection) => {
                          if (!selection) {
                            updateFilter("sector_ids", null);
                            return;
                          }
                          updateFilter(
                            "sector_ids",
                            selection.map((item) => item.id)
                          );
                        }}
                        isClearable={true}
                      />
                    </Col>
                  </>
                )}
              </Card>
            </Col>

            <Col lg={filterToggle ? 9 : 12} md={filterToggle ? 12 : 12}>
              <Card>
                <CardBody>
                  {isLoading && (
                    <Container fluid>
                      <Row>
                        <Col lg={12}>
                          <Spinner className="font-size-20" color="primary" />
                        </Col>
                      </Row>
                    </Container>
                  )}
                  {!isLoading && (
                    <UserList users={users.length > 0 ? users : []} />
                  )}
                  {!isLoading && pagination && pagination.length > 3 && (
                    <Container fluid>
                      <Row>
                        <Col lg={12}>
                          <Pagination aria-label="Page navigation example">
                            {pagination.map((page) => (
                              <PaginationItem
                                onClick={() => {
                                  if (!page.page_number) return;
                                  if (page.page_number == currentPage) return;

                                  setCurrentPage(page.page_number);
                                }}
                                disabled={!page.page_number}
                                className={
                                  page.page_number == currentPage && `active`
                                }
                              >
                                <PaginationLink>{page.label}</PaginationLink>
                              </PaginationItem>
                            ))}
                          </Pagination>
                        </Col>
                      </Row>
                    </Container>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchPage;
