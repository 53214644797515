import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardImg,
  UncontrolledTooltip,
  Button,
  Table
} from "reactstrap";

import styled from "styled-components";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";
import "moment/locale/tr";

import history from "../../history";

//dummy images
import img4 from "../../assets/images/bia-logo-son.png";
import DefaultFileIcon from "../../assets/images/default-file-icon.svg";
import isYoutubeLink from "../../utils/isYoutubelink";
import hasAccess from "../../utils/hasAccess";
import DeletePost from "../../api/posts/DeletePost";
import isSuperAdmin from "../../utils/isSuperAdmin";
import PostAvatar from "./PostAvatar";
import ViewPost from "../../pages/Posts/ViewPost";

const UserPendingPosts = (data) => {
  const [posts, setPosts] = useState([]);
  const [showMore, setshowMore] = useState(false);
  const [images, setImages] = useState([]);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [user, setUser] = useState();

  const [isUserPost, setIsUserPost] = useState(false);

  //lightbox states
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const userobj = JSON.parse(localStorage.getItem("authUser"));



  const handleDelete = (id) => {
    const confirmation = window.confirm(
      "Veri kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );
    if (!confirmation) return;

    DeletePost(id).then((r) => {
      alert(r.message);
      if (r.status === "success") {
        window.location.reload();
      }
    });
  };


  useEffect(() => {
        if (data) {
      setPosts(data.data);
    }

  }, []);

  return (
      <Row>
    <Col lg={12}>
    <Card>
        <CardBody>
            <h4 className="card-title">Onay Bekleyen Duyurularınız</h4>
            <p className="card-title-desc">
            Bireysel duyurularınız, TÜRKONFED BİA platformu yöneticilerinin onayından sonra herkes tarafından görünür olmaktadır.
<br/> Onay bekleyen duyurularınız aşağıda listelenmektedir.
</p>            
            <div className="table-responsive">
                <Table className="mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Duyuru Başlığı</th>
                            <th>Oluşturulma Tarihi</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      {posts && posts.map((post, index) => (
                          <tr className="table-warning" key={index}>
                          <th scope="row">{post.id}</th>
                          <td>{post.title}</td>
                          <td>{post.created_at.slice(0, 10)}</td>
                          <td><Button 

                           size="sm"
                           onClick={() => handleDelete(post.id)} 
                            id="delete-pending">
                            <i className="mdi mdi-delete-outline font-size-14"></i></Button>
                            <UncontrolledTooltip placement="top" target="delete-pending">
                                Sil
                            </UncontrolledTooltip ></td>
                      </tr>))
                        }
                      
                    </tbody>
                </Table>

            </div>

        </CardBody>
    </Card>
</Col>
</Row>
  );
};

export default UserPendingPosts;
