import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Button,
  Spinner,
  Input,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import img5 from "../../assets/images/companies/img-5.png";

//api
import ListEducationPaginate from "../../api/education/ListEducationPaginate";

import { AvForm, AvField } from "availity-reactstrap-validation";
import useSearch from "../../hooks/useSearch";
import EducationPost from "../../components/Common/EducationPost";
import isSuperAdmin from "../../utils/isSuperAdmin";

import CreateUserPostModal from "./CreateUserPostModal";
import ListUserPosts from "../../api/posts/ListUserPosts";
import ListUserPendingPosts from "../../api/posts/ListUserPendingPosts";
import FeedPost from "../../components/Common/FeedPost";
import UserPendingPosts from "../../components/Common/UserPendingPosts";

const UserPosts = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Bireysel Duyurular", link: "#" },
  ]);
  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [PendingPosts, setPendingPosts] = useState([]);

  const [isCreateUserPostModalOpen, setIsCreateUserPostModalOpen] =
    useState(false);

  const [educationSearching, setEducationSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  useSearch(searchText, () => educationSearchWithDelay());

  const educationSearch = (searchQuery) => {
    if (!searchQuery) {
      loadEducation(1, searchQuery);
    }
    setEducationSearching(true);
    setSearchText(searchQuery);
  };

  const educationSearchWithDelay = () => {
    if (!searchText) {
      return;
    }

    loadEducation(1, searchText);
  };

  useEffect(() => {
    loadEducation(currentPage, searchText);
  }, []);

  const loadEducation = (page = 1, title = "") => {
    setIsLoading(true);
    ListUserPendingPosts()
      .then((r) => {
        if (r.status == "success") {
          setPendingPosts(r.data);
        }
      })
      .catch((error) => {
        alert(error);
      });

    ListUserPosts(page, { title: title }).then((r) => {
      setIsLoading(false);
      if (r.status != "success") {
        alert(r.message);
      }
      setPosts(r.data.data);
      setCurrentPage(page);
      setPagination(
        r.data.links.map((link) => {
          link.page_number = null;
          if (link.label == "&laquo; Previous") {
            link.label = "Önceki";
          }

          if (link.label == "Next &raquo;") {
            link.label = "Sonraki";
          }

          if (!link.url) {
            return link;
          }

          const page_number = link.url.split("=")[1];
          link.page_number = page_number;
          return link;
        })
      );
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Bireysel Duyurular"
            breadcrumbItems={breadcrumbItems}
          />
          {PendingPosts.length>0 && <UserPendingPosts data={PendingPosts} />}

          <Row>
            <Col>
              <div>
                <Button
                  color="primary"
                  onClick={() => setIsCreateUserPostModalOpen(true)}
                  className="mb-2"
                >
                  <i className="mdi mdi-plus mr-2"></i>Bireysel Duyuru Oluştur
                </Button>
              </div>
            </Col>
          </Row>

          {!isLoading &&
            posts &&
            posts.length > 0 &&
            posts.map((item, index) => (
              <>
                <FeedPost key={index} {...item} />
              </>
            ))}
          {isLoading && (
            <Row className="justify-content-center mb-3">
              <Spinner className="font-size-20" color="primary" />
            </Row>
          )}
          {!isLoading && pagination && pagination.length > 3 && (
            <Row>
              <Pagination aria-label="Page navigation example">
                {pagination.map((page) => (
                  <PaginationItem
                    onClick={() => {
                      if (!page.page_number) return;
                      if (page.page_number == currentPage) return;
                      loadEducation(page.page_number, searchText);
                    }}
                    disabled={!page.page_number}
                    className={page.page_number == currentPage && `active`}
                  >
                    <PaginationLink>{page.label}</PaginationLink>
                  </PaginationItem>
                ))}
              </Pagination>
            </Row>
          )}
          <CreateUserPostModal
            data={null}
            isOpen={isCreateUserPostModalOpen}
            toggle={() =>
              setIsCreateUserPostModalOpen(!isCreateUserPostModalOpen)
            }
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserPosts;
