import api from "../../config/API";

const ConfirmPendingPost = async (id = 0) => {
  return api
    .post(`/confirmPendingPost/${id}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ConfirmPendingPost;
