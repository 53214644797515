import api from "../../config/API";

const UpdateCity = async ({ id, ...payload }) => {
  return api
    .patch(`/cities/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default UpdateCity;
