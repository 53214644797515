const hasAccess = (admins) => {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"));

    return admins.some((admin) => admin.id == user.id);
  } catch (error) {
    return false;
  }
};

export default hasAccess;
