import { useEffect } from "react";

const useSearch = (searchTerm, callback) =>
  useEffect(() => {
    if (!searchTerm) return;

    const delayDebounceFn = setTimeout(() => {
      callback();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

export default useSearch;
