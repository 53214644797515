import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import history from "../../../history";
import Select from "react-select";
import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";

import ViewTitle from "./ViewTitle";
//api
import CreateTitle from "../../../api/titles/CreateTitle";
import ListTitles from "../../../api/titles/ListTitles";
import DeleteTitle from "../../../api/titles/DeleteTitle";
// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../../store/actions";

const StyledSelect = styled(Select)`
  .select__control {
    border-color: ${(props) => (props.hasError ? "red" : "#ced4da")};
  }
  .select__value-container {
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;

class Title extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      order: 1,
      selected_type_error: false,
      selected_type: null,
      viewed: {},
      typesOptions: [],
      titles: [],
      isViewOpen: false,
      pagination: null,
      currentPage: 1,
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.loadPage(this.state.currentPage);
  }
  loadPage(nextPage) {
    this.setState({ isLoading: true });
    ListTitles(nextPage).then((r) => {
      if (r.status === "success") {
        this.setState({
          isLoading: false,
          currentPage: nextPage,
          titles: r.data.titles.data,
          typesOptions: r.data.titleTypes,
          pagination: r.data.titles.links.map((link) => {
            link.page_number = null;
            if (link.label == "&laquo; Previous") {
              link.label = "Önceki";
            }

            if (link.label == "Next &raquo;") {
              link.label = "Sonraki";
            }

            if (!link.url) {
              return link;
            }

            const page_number = link.url.split("=")[1];
            link.page_number = page_number;
            return link;
          }),
        });
      }
    });
  }
  handleViewOpen(row) {
    this.setState({
      viewed: row,
      isViewOpen: true,
    });
  }
  handleDelete(id) {
    const confirmation = window.confirm(
      "Veri kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );
    if (!confirmation) return;

    DeleteTitle(id).then((r) => {
      alert(r.message);
      if (r.status === "success") {
        window.location.reload();
      }
    });
  }
  hasInputError() {
    let errors = 0;

    if (!this.state.selected_type) {
      errors++;
      this.setState({
        selected_type_error: true,
      });
    }
    return errors == 0;
  }

  handleSubmit(event, values) {
    if (!this.hasInputError()) {
      return;
    }

    if (this.state.label && this.state.selected_type) {
      const data = {
        label: this.state.label,
        type: this.state.selected_type,
        order: this.state.order,
      };

      CreateTitle(data).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          window.location.reload();
        }
      });
    }
  }

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    const columns = [
      {
        name: "İşlemler",
        cell: (row) => (
          <>
            <Button
              color="warning"
              type="button"
              className="waves-effect waves-light mr-1"
              size="sm"
              onClick={() => this.handleViewOpen(row)}
            >
              <i className="ri-eye-line align-middle"></i>
            </Button>
            <Button
              color="primary"
              type="button"
              className="waves-effect waves-light mr-1"
              size="sm"
              onClick={() => this.handleDelete(row.id)}
            >
              <i className="ri-close-circle-line align-middle"></i>
            </Button>
          </>
        ),
        grow: 0,
      },
      { name: "ID", selector: "id", sortable: true, width: "100px" },
      {
        name: "Tür",
        selector: "type",
        sortable: true,
        cell: (row) => (
          <>
            <span>{row.type == "turkonfed" && "TÜRKONFED Görevi"}</span>
            <span>{row.type == "federation" && "Federasyon Görevi"}</span>
            <span>{row.type == "association" && "Dernek Görevi"}</span>
          </>
        ),
      },
      { name: "Görev", selector: "label", sortable: true },
      { name: "Sıra", selector: "order", sortable: true },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/*  <Breadcrumbs
          title="ŞEHİR SEÇENEKLERİ"
          breadcrumbItems={breadcrumbItems}
        /> */}
            {/* //todoo */}
            <Row>
              <Col>
                <AvForm
                  onValidSubmit={this.handleSubmit}
                  className="form-horizontal"
                >
                  <Row>
                    <Card style={{ width: "100%" }}>
                      <CardBody>
                        <FormGroup>
                          <Label
                            htmlFor="label"
                            className="col-md-12 col-form-label"
                          >
                            Görev
                          </Label>
                          <Col md={12}>
                            <AvField
                              name="label"
                              value={this.state.label}
                              validate={{ required: true }}
                              type="text"
                              className="form-control"
                              id="label"
                              placeholder=""
                              onChange={(e) =>
                                this.setState({
                                  label: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Label
                            htmlFor="selected_type"
                            className="col-md-6 col-form-label"
                          >
                            Tür
                          </Label>
                          <Col md={12}>
                            <StyledSelect
                              hasError={this.state.selected_type_error}
                              id="selected_type"
                              classNamePrefix="select"
                              value={
                                this.state.selected_type &&
                                this.state.typesOptions.filter(
                                  (option) =>
                                    option === this.state.selected_type
                                )
                              }
                              isSearchable={true}
                              name="selected_type"
                              placeholder=""
                              options={this.state.typesOptions}
                              getOptionValue={(option) => option}
                              getOptionLabel={(option) => {
                                if (option == "turkonfed") {
                                  return "TÜRKONFED Görevi";
                                }
                                if (option == "federation") {
                                  return "Federasyon Görevi";
                                }
                                if (option == "association") {
                                  return "Dernek Görevi";
                                }
                              }}
                              onChange={(option) =>
                                this.setState({
                                  selected_type: option,
                                  selected_type_error: false,
                                })
                              }
                            />
                          </Col>
                          <br />
                          <Col md={12}>
                            <FormGroup className="mb-4">
                              <Label htmlFor="billing-name">Sıra</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="billing-name"
                                placeholder="Unvan giriniz"
                                value={this.state.order}
                                onChange={(e) =>
                                  this.setState({ order: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <Button
                              color="primary"
                              className=" waves-effect waves-light mr-1 mt-3"
                              type="submit"
                            >
                              Ekle
                            </Button>{" "}
                          </Col>{" "}
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Row>
                </AvForm>
                <Row>
                  <Card style={{ width: "100%" }}>
                    <CardBody>
                      {this.state.isLoading && (
                        <Row>
                          <Spinner className="font-size-20" color="primary" />
                        </Row>
                      )}
                      {!this.state.isLoading && (
                        <Table
                          noHeader
                          showTable={true}
                          title="Sektörler"
                          columns={columns}
                          data={this.state.titles}
                          defaultSortField="type"
                          progressPending={false}
                        />
                      )}
                      <ViewTitle
                        data={this.state.viewed}
                        isOpen={this.state.isViewOpen}
                        types={this.state.typesOptions}
                        toggle={() =>
                          this.setState({
                            isViewOpen: !this.state.isViewOpen,
                          })
                        }
                      />
                    </CardBody>
                  </Card>
                </Row>
                {this.state.pagination && this.state.pagination.length > 3 && (
                  <Row>
                    <Pagination aria-label="Page navigation example">
                      {this.state.pagination.map((page) => (
                        <PaginationItem
                          onClick={() => {
                            if (!page.page_number) return;
                            if (page.page_number == this.state.currentPage)
                              return;

                            this.loadPage(page.page_number);
                          }}
                          disabled={!page.page_number}
                          className={
                            page.page_number == this.state.currentPage &&
                            `active`
                          }
                        >
                          <PaginationLink>{page.label}</PaginationLink>
                        </PaginationItem>
                      ))}
                    </Pagination>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Title);
