import React from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import GMap from "./GMap";
import SpecText from "./SpecText";
const Footer = (props) => (
  <>
    <Col md={3}>
      <p className="">
        Çatma Mescit Mah. Refik Saydam Cad.
        <br /> Akarca Sok. No.41 Tepebaşı,
        <br />
        Beyoğlu, İstanbul
      </p>
      <p>
        <b>T:</b> +90 212 251 73 00
      </p>
      <p>
        <b>F:</b> +90 212 251 58 77
      </p>
      <p>
        <b>E:</b> info@turkonfed.org
      </p>
      <p>
        <b>E:</b> bia@turkonfed.org
      </p>
    </Col>
    <Col md={9}>
      <GMap />
    </Col>
    <Col>
      <SpecText />
    </Col>
  </>
);

export default Footer;
