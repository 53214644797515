import api from "../../config/API";

const UpdateFederation = async (payload, id) => {
  return api
    .patch(`/federations/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default UpdateFederation;
