import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import "./datatables.scss";

const UserList = ({ users }) => {
  const [userDataList, setUserDataList] = useState([]);

  useEffect(() => {
    setUserDataList(users.data);
  }, [users]);

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody className="pt-0">
                {
                  <MDBDataTable
                    displayEntries={false}
                    searching={false}
                    noBottomColumns={true}
                    paging={false}
                    responsive
                    data={{
                      columns: [
                        {
                          label: "",
                          field: "action",
                          sort: "asc",
                          width: 109,
                        },
                        {
                          label: "İsim Soyisim",
                          field: "name",
                          sort: "asc",
                          width: 109,
                        },
                        {
                          label: "E-posta",
                          field: "email",
                          sort: "asc",
                          width: 48,
                        },
                        {
                          label: "Dernek",
                          field: "association",
                          sort: "asc",
                          width: 135,
                        },
                        {
                          label: "Federasyon",
                          field: "federation",
                          sort: "asc",
                          width: 135,
                        },
                        {
                          label: "Şehir",
                          field: "city",
                          sort: "asc",
                          width: 135,
                        },
                        {
                          label: "Firma",
                          field: "company_name",
                          sort: "asc",
                          width: 135,
                        },
                        {
                          label: "Sektör",
                          field: "sector",
                          sort: "asc",
                          width: 135,
                        },
                      ],
                      rows: users.map((user) => ({
                        action: (
                          <>
                            <Link to={"/kullanici/" + user.id} target="_blank">
                              <Button
                                id={"show-user-button-" + user.id}
                                color="primary"
                                type="button"
                                className="waves-effect waves-light mr-1"
                                size="sm"
                              >
                                <i className="ri-eye-line align-middle"></i>
                              </Button>
                              <UncontrolledTooltip
                                target={"show-user-button-" + user.id}
                                placement="top"
                              >
                                Görüntüle
                              </UncontrolledTooltip>
                            </Link>
                          </>
                        ),
                        name: user.name || "",
                        email: user.email || "",
                        company_name: user.company_name || "",
                        association: (
                          <>
                            {user.associations.map((a, key) => (
                              <div>
                                <div
                                  className="badge badge-soft-primary font-size-12"
                                  key={key}
                                >
                                  {a.title.slice(0, 10)}
                                  {a.title.length > 10 && "..."}
                                </div>{" "}
                              </div>
                            ))}
                          </>
                        ),
                        federation: (() => {
                          const federations = new Set();

                          user.associations.map((a) =>
                            federations.add(JSON.stringify(a.federation))
                          );

                          return (
                            <>
                              {[...federations].map((federation, key) => (
                                <div>
                                  <div
                                    className="badge badge-soft-danger font-size-12"
                                    key={key}
                                  >
                                    {JSON.parse(federation).title.slice(0, 10)}
                                    {JSON.parse(federation).title.length > 10 &&
                                      "..."}
                                  </div>{" "}
                                </div>
                              ))}
                            </>
                          );
                        })(),
                        city: (
                          <div className="badge badge-soft-info font-size-12">
                            {user.city ? user.city.title : ""}
                          </div>
                        ),
                        sector: (
                          <div className="badge badge-soft-success font-size-12">
                            {user.sector.title.slice(0, 10)}
                            {user.sector.title.length > 10 && "..."}
                          </div>
                        ),
                      })),
                    }}
                    className="mt-4"
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UserList;
