import React, { Component } from "react";
import styled from "styled-components";
import history from "../../history";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import Select from "react-select";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//api
import ListOptions from "../../api/ListOptions";
import SearchCities from "../../api/search/SearchCities";
import ListOptionsWithParam from "../../api/ListOptionsWithParam";
import UserRegister from "../../api/UserRegister";



//utils
import uniqueMergeArrays from "../../utils/uniqueMergeArrays";
import customFilterForSelect from "../../utils/customFilterForSelect";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";
import { useHistory } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Images
import BiaLogo from "../../assets/images/bia-logo.png";

const CustomButton = styled(Button)`
  background: #5a2d91;
  border-color: #5a2d91;
  border-radius: 0;
  &:hover,
  :active {
    background: #5a2d91 !important;
    border-color: #5a2d91 !important;
  }
`;

const StyledSelect = styled(Select)`
  .select__control {
    height: 60px !important;
    border-color: ${(props) => (props.hasError ? "red" : "#ced4da")};
  }
  .select__value-container {
    margin-left: 60px;
    top: 10px;
    padding: 0;
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;
const StyledLabel = styled(Label)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 14.4px;
`;

class SolidRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      title: null,
      email: null,
      phone: null,
      password: null,
      password_confirmation: null,

      company_name: null,
      company_sector: null,
      sub_sector: null,
      company_city: null,
      company_country: null,


      company_sector_error: false,
      company_country_error: false,
      company_city_error: false,

      sectorOptions: [],
      countriesOptions: [],
      citiesOptions: [],
      citySearching: false,

      is_loading: false,
      federation_name: null,
      association_name: null,
      federation_title_id: null,
      association_title_id: null,
      confirmed_kvkk: false,
      confirmed_kvkk_abroad: false,
      confirmed_privacy: false,

      confirmed_kvkk_error: false,
      confirmed_kvkk_abroad_error: false,
      confirmed_privacy_error: false,

      federationsOptions: [],
      associationsOptions: [],
      associationTitleOptions: [],
      federationTitleOptions: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    ListOptions().then((r) => {
        if (r.status == "success") {
          this.setState({
            sectorOptions: r.data.sectors,
            countriesOptions: r.data.countries,
            citiesOptions: r.data.cities,
          });
        }
      });
    ListOptionsWithParam().then((r) => {
        if (r.status === "success") {
          this.setState({
            associationsOptions: r.data.associations,
            associationTitleOptions: r.data.associationTitles,
            federationTitleOptions: r.data.federationTitles,
          });
        }
      });
  }

  hasInputError() {
    let errors = 0;

    if (!this.state.company_sector) {
      errors++;
      this.setState({
        company_sector_error: true,
      });
    }

    if (!this.state.company_country) {
      errors++;
      this.setState({
        company_country_error: true,
      });
    }
    if (!this.state.company_city) {
      errors++;
      this.setState({
        company_city_error: true,
      });
    }
    if (!this.state.association_name) {
        errors++;
        this.setState({
          association_name_error: true,
        });
      }
      if (!this.state.association_title_id) {
        errors++;
        this.setState({
          association_title_error: true,
        });
      }
      if (!this.state.confirmed_kvkk) {
        errors++;
        this.setState({
          confirmed_kvkk_error: true,
        });
      }
      if (!this.state.confirmed_kvkk_abroad) {
        errors++;
        this.setState({
          confirmed_kvkk_abroad_error: true,
        });
      }
  
      if (!this.state.confirmed_privacy) {
        errors++;
        this.setState({
          confirmed_privacy_error: true,
        });
      }

    return errors == 0;
  }

  citySearch(searchQuery) {
    if (!searchQuery) return;
    this.setState({
      citySearching: true,
    });
    this.waitforTypeEnd("sectorTimeout", 500, () => {
      SearchCities({
        title: searchQuery,
      }).then((r) => {
        this.setState({
          citySearching: false,
        });
        if (r.status !== "success") return;

        this.setState({
          citiesOptions: uniqueMergeArrays(this.state.citiesOptions, r.data),
        });
      });
    });
  }

  waitforTypeEnd(eventName, waitTime, callback) {
    if (this[eventName]) clearTimeout(this[eventName]);
    this[eventName] = setTimeout(() => {
      callback();
    }, waitTime);
  }

  handleSubmit(event, values) {
    const data = {
        //1
        name: this.state.name,
        title: this.state.title,
        email: this.state.email,
        phone: this.state.phone,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        //2
        sector_id: this.state.company_sector,
        city_id: this.state.company_city,
        company_name: this.state.company_name,
        sub_sector: this.state.sub_sector,
        country_id: this.state.company_country,
        //3
        association_id: this.state.association_name,
        association_title_id: this.state.association_title_id,
        federation_title_id: this.state.federation_title_id,
        confirmed_kvkk: this.state.confirmed_kvkk ? 1 : 0,
        confirmed_kvkk_abroad: this.state.confirmed_kvkk_abroad ? 1 : 0,
        confirmed_privacy: this.state.confirmed_privacy ? 1 : 0,
      };

    UserRegister(data).then((r) => {
        alert(r.message);
  
        this.setState({
          is_loading: false,
        });
        if (r.status === "success") {
          history.push("/");
          window.location.reload();
        }
      });
  }

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0" style={{ minHeight: "100vh" }}>
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100 h-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={BiaLogo} alt="bia" width="200" />
                                {/*  <img src={logodark} height="20" alt="logo" /> */}
                              </Link>
                            </div>
                            <h4 className="font-size-18 mt-4">Kayıt Formu</h4>
                          </div>

                          {this.props.user && (
                            <Alert color="success">
                              Registration Done Successfully.
                            </Alert>
                          )}

                          {this.props.registrationError && (
                            <Alert color="danger">
                              {this.props.registrationError}
                            </Alert>
                          )}

                          <div className="p-2 mt-5">
                            <div className="text-center">
                              <p className="text-muted">Kişisel Bilgiler</p>
                            </div>
                            <AvForm
                              onValidSubmit={this.handleSubmit}
                              className="form-horizontal"
                            >
                              <Row>
                                <Col lg={6}>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="name">İsim Soyisim *</Label>
                                    <AvField
                                      name="name"
                                      value={this.state.name}
                                      validate={{ required: true}}
                                      errorMessage={'Kayıt formunun gönderilmesi için bu alanın doldurulması gereklidir.'}
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          name: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-mail-line auti-custom-input-icon"></i>
                                    <Label htmlFor="email">E-posta *</Label>
                                    <AvField
                                      name="email"
                                      value={this.state.email}
                                      validate={{ email: true, required: true }}
                                      errorMessage={'Kayıt formunun gönderilmesi için lütfen geçerli bir e-posta adresi giriniz.'}
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      placeholder="example@example.com"
                                      onChange={(e) =>
                                        this.setState({
                                          email: e.target.value,
                                        })
                                      }
                                    />
                                    
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className=" ri-smartphone-line auti-custom-input-icon"></i>
                                    <Label htmlFor="phone">
                                      Cep Telefonu *
                                    </Label>
                                    <AvField
                                      name="phone"
                                      value={this.state.phone}
                                      validate={{ required: true }}
                                      errorMessage={'Kayıt formunun gönderilmesi için bu alanın doldurulması gereklidir.'}
                                      type="text"
                                      className="form-control"
                                      id="phone"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          phone: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="title">Unvan </Label>
                                    <AvField
                                      name="title"
                                      value={this.state.title}
                                      type="text"
                                      className="form-control"
                                      id="title"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          title: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="password">Şifre *</Label>
                                    <AvField
                                      name="password"
                                      value={this.state.password}
                                      validate={{ required: { value: true } }}
                                      errorMessage={'Kayıt formunun gönderilmesi için bu alanın doldurulması gereklidir.'}
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          password: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="password_confirmation">
                                      Tekrar Şifre *
                                    </Label>
                                    <AvField
                                      name="password_confirmation"
                                      value={this.state.password_confirmation}
                                      errorMessage={'Kayıt formunun gönderilmesi için bu alanın doğru bir şekilde doldurulması gereklidir.'}
                                      validate={{
                                        required: { value: true },
                                        match: { value: "password" },
                                      }}
                                      type="password"
                                      className="form-control"
                                      id="password_confirmation"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          password_confirmation: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <hr />

                              <div className="text-center">
                                <p className="text-muted">Firma Bilgileri</p>
                              </div>
                              <Row>
                                <Col lg={6}>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="company_name">
                                      Firma İsmi *
                                    </Label>
                                    <AvField
                                      name="company_name"
                                      value={this.state.company_name}
                                      validate={{ required: { value: true } }}
                                      errorMessage={'Kayıt formunun gönderilmesi için bu alanın doldurulması gereklidir.'}
                                      type="text"
                                      className="form-control"
                                      id="company_name"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          company_name: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="company_city">
                                      Şehir *
                                    </StyledLabel>
                                    <StyledSelect
                                      id="company_city"
                                      classNamePrefix="select"
                                      hasError={this.state.company_city_error}
                                      defaultValue={this.state.company_city}
                                      isSearchable={true}
                                      isLoading={this.state.citySearching}
                                      onInputChange={(e) => this.citySearch(e)}
                                      filterOption={(option, searchText) =>
                                        customFilterForSelect(
                                          option,
                                          searchText,
                                          "title"
                                        )
                                      }
                                      name="company_city"
                                      placeholder=""
                                      options={this.state.citiesOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        this.setState({
                                          company_city_error: false,
                                          company_city: option.id,
                                          company_country: option.country_id,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="company_sector">
                                      Sektör *
                                    </StyledLabel>
                                    <StyledSelect
                                      hasError={this.state.company_sector_error}
                                      id="company_sector"
                                      classNamePrefix="select"
                                      defaultValue={this.state.company_sector}
                                      isSearchable={true}
                                      name="company_sector"
                                      placeholder=""
                                      options={this.state.sectorOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        this.setState({
                                          company_sector_error: false,
                                          company_sector: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="sub_sector">
                                      Alt Sektör
                                    </Label>
                                    <AvField
                                      name="sub_sector"
                                      value={this.state.sub_sector}
                                      type="text"
                                      className="form-control"
                                      id="sub_sector"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          sub_sector: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <hr />

                              <div className="text-center">
                                <p className="text-muted">
                                  Federasyon/Dernek Bilgileri
                                </p>
                              </div>
                              <Row className="justify-content-center">
                                <Col lg={6}>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="association_name">
                                      Bağlı Olduğu Dernek *
                                    </StyledLabel>
                                    <StyledSelect
                                      hasError={
                                        this.state.association_name_error
                                      }
                                      id="association_name"
                                      classNamePrefix="select"
                                      defaultValue={this.state.association_name}
                                      isSearchable={true}
                                      name="association_name"
                                      placeholder=""
                                      options={this.state.associationsOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        this.setState({
                                          association_name_error: false,
                                          association_name: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="association_title">
                                      Dernekteki Görevi *
                                    </StyledLabel>
                                    <StyledSelect
                                      hasError={
                                        this.state.association_title_error
                                      }
                                      id="association_title"
                                      classNamePrefix="select"
                                      defaultValue={
                                        this.state.association_title_id
                                      }
                                      isSearchable={true}
                                      name="association_title"
                                      placeholder=""
                                      options={
                                        this.state.associationTitleOptions
                                      }
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(option) =>
                                        this.setState({
                                          association_title_error: false,
                                          association_title_id: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="federation_name">
                                      Bağlı Olduğu Federasyon
                                    </StyledLabel>
                                    <Input
                                      disabled
                                      value={
                                        this.state.association_name
                                          ? this.state.associationsOptions.filter(
                                              (a) =>
                                                a.id ==
                                                this.state.association_name
                                            )[0].federation.title
                                          : ""
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="federation_title">
                                      Federasyondaki Görevi
                                    </StyledLabel>
                                    <StyledSelect
                                      id="federation_title"
                                      classNamePrefix="select"
                                      defaultValue={
                                        this.state.federation_title_id
                                      }
                                      isSearchable={true}
                                      name="federation_title"
                                      placeholder=""
                                      options={
                                        this.state.federationTitleOptions
                                      }
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(option) =>
                                        this.setState({
                                          federation_title_id: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={12}>
                                    <hr/>
                                  <FormGroup
                                    className="auth-form-group-custom"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    
                                    <div className="text-center">
                                <p className="text-muted">
                                  Kişisel Verilerin İşlenmesine İlişkin Rıza Beyanı
                                </p>
                              </div>

                                    <span style={{ textAlign: "justify" }}>
                                      Kişisel verilerinizin ve özel nitelikli
                                      kişisel verilerinizin Türk Girişim ve İş
                                      Dünyası Konfederasyonu{" "}
                                      <b>(“TÜRKONFED”)</b> tarafından
                                      işlenmesine ilişkin Aydınlatma Metni için
                                      lütfen{" "}
                                      <u>
                                        <b>
                                          {" "}
                                          <a
                                            href="aydinlatma-metni.pdf"
                                            target="_blank"
                                            referrerPolicy="no-referrer"
                                            style={{
                                              textDecoration: "none",
                                              color: "inherit",
                                            }}
                                          >
                                            tıklayınız
                                          </a>
                                        </b>
                                      </u>
                                      .
                                    </span>
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      border: this.state.confirmed_kvkk_error
                                        ? "1px solid red"
                                        : "",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={this.state.confirmed_kvkk}
                                      onChange={() =>
                                        this.setState({
                                          confirmed_kvkk:
                                            !this.state.confirmed_kvkk,
                                          confirmed_kvkk_error: false,
                                        })
                                      }
                                      style={{
                                        marginTop: "4px",
                                        transform: "scale(1.4)",
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                       Özel nitelikli kişisel verilerimin
                                      Aydınlatma Metni’nde belirtilen amaçlarla
                                      ve kapsamda işlenmesine rıza veriyorum.
                                    </span>
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      border: this.state
                                        .confirmed_kvkk_abroad_error
                                        ? "1px solid red"
                                        : "",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={this.state.confirmed_kvkk_abroad}
                                      onChange={() =>
                                        this.setState({
                                          confirmed_kvkk_abroad:
                                            !this.state.confirmed_kvkk_abroad,
                                          confirmed_kvkk_abroad_error: false,
                                        })
                                      }
                                      style={{
                                        marginTop: "4px",
                                        transform: "scale(1.4)",
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                       Kişisel verilerimin ve özel nitelikli
                                      kişisel verilerimin Aydınlatma Metni’nde
                                      belirtilen amaçlarla ve kapsamda yurt
                                      dışına aktarılmasına rıza veriyorum.
                                    </span>
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      border: this.state
                                        .confirmed_kvkk_abroad_error
                                        ? "1px solid red"
                                        : "",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={this.state.confirmed_privacy}
                                      onChange={() =>
                                        this.setState({
                                          confirmed_privacy:
                                            !this.state.confirmed_privacy,
                                          confirmed_kvkk_abroad_error: false,
                                        })
                                      }
                                      style={{
                                        marginTop: "4px",
                                        transform: "scale(1.4)",
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                      {" "}
                                      <u>
                                        <b>
                                          {" "}
                                          <a
                                            href="platform_kullanim_sartlari_ve_gizlilik.pdf"
                                            target="_blank"
                                            referrerPolicy="no-referrer"
                                            style={{
                                              textDecoration: "none",
                                              color: "inherit",
                                            }}
                                          >
                                            Bölgesel İş Ağları Platformu
                                            Kullanım Koşulları ve Gizlilik
                                            Politikası
                                          </a>
                                        </b>
                                      </u>{" "}
                                      şartlarını kabul ediyorum.
                                    </span>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <div className="text-center">
                                <CustomButton
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  {this.props.loading
                                    ? "Yükleniyor ..."
                                    : "Kayıt Ol"}
                                </CustomButton>
                              </div>
                            </AvForm>
                          </div>

                         
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/*  <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(SolidRegister);
