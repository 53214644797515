import api from "../../config/API";

const ListFederationPosts = async (page, payload) => {
  return api
    .post(`/listFederationPosts?page=${page}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListFederationPosts;
