import api from "../../config/API";

const GetLandingPageData = async () => {
  return api
    .get(`/getLandingPageData`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default GetLandingPageData;
