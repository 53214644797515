import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import { Link } from "react-router-dom";
import simpleFilterRequest from "../../api/user/simpleFilterRequest";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";

//Import Megamenu
import MegaMenu from "./MegaMenu";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//Import logo Images
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.png";
import logosmlight from "../../assets/images/logo-sm-light.png";
import logolight from "../../assets/images/logo-light.png";

//Import Social Profile Images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import BiaLogo from "../../assets/images/bia-logo-son.png";

const CustomNav = styled.div`
  background: #fff !important;
`;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filters: {
        name: "",
        email: "",
        title: "",
        company_name: "",
      },
      isSearch: false,
      isSocialPf: false,
      isOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    // this.updateFilter = this.updateFilter.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    const updateFilter = (value) => {
      this.setState({
        isOpen: true,
      });
      this.setState({
        filters: {
          name: value,
          email: value,
          title: value,
          company_name: value,
        },
      });
      simpleFilterRequest(this.state.filters).then((r) => {
        if (r.status === "success") {
          const { data } = r.data;
          this.setState({
            users: data,
          });
        }
      });
    };

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <CustomNav className="navbar-brand-box">
                <Link to="#" className="logo logo-dark">
                  <span className="logo-sm">
                    {/*     <img src={logosmdark} alt="" height="22" /> */}
                  </span>
                  <span className="logo-lg">
                    {/*  <img src={logodark} alt="" height="20" /> */}
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      src={BiaLogo}
                      alt=""
                      height="15"
                      style={{ marginLeft: "-14px" }}
                    />
                  </span>
                  <span className="logo-lg">
                    <img src={BiaLogo} alt="" width="90" />
                  </span>
                </Link>
              </CustomNav>

              <Button
                size="sm"
                color="none"
                type="button"
                onClick={this.toggleMenu}
                className="px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="ri-menu-2-line align-middle"></i>
              </Button>
            </div>
            <div className="d-flex">
              <Form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Arama"
                    onChange={(e) => updateFilter(e.target.value)}
                  />
                  <span className="ri-search-line"></span>
                </div>
                <Dropdown
                  style={{ position: "absolute", top: "38px" }}
                  isOpen={this.state.isOpen}
                  toggle={() => this.setState({ isOpen: !this.state.isOpen })}
                  className="dropdown chat-noti-dropdown"
                >
                  <DropdownToggle
                    tag="button"
                    className="btn dropdown-toggle"
                    type="button"
                  ></DropdownToggle>
                  <DropdownMenu>
                    {this.state.users &&
                      this.state.users.map((item, i) => (
                        <DropdownItem key={i} href="#">
                          {" "}
                          <Link to={"/kullanici/" + item.id}>
                            <div className="badge badge-soft-dark font-size-14">
                              {item.name} ( {item.company_name} -{" "}
                              <i>{item.title}</i> ) {item.email}
                            </div>
                          </Link>
                        </DropdownItem>
                      ))}
                    <DropdownItem href="/arama" style={{ display: "flex" }}>
                      Detaylı Arama{" "}
                      <i className="ri-external-link-line ml-2"></i>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Form>
            </div>
            <div className="d-flex">
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withNamespaces()(Header)
);
