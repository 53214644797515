import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import styled from "styled-components";
import Select from "react-select";

import UpdateTitle from "../../../api/titles/UpdateTitle";

const StyledSelect = styled(Select)`
  .select__control {
    border-color: ${(props) => (props.hasError ? "red" : "#ced4da")};
  }
  .select__value-container {
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;

function ViewTitle({ data, types, isOpen, toggle, ...props }) {
  const [label, setLabel] = useState("");
  const [order, setOrder] = useState(1);
  const [selectedCountryID, setSelectedCountryID] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [typesOptions, setTypesOptions] = useState("");

  const handleSubmit = (event, values) => {
    if (!label || label == "") {
      alert("Lütfen Görev giriniz.");
      return;
    }
    if (!selectedType || selectedType == "") {
      alert("Lütfen Tür seçiniz.");
      return;
    }
    if (label && selectedType) {
      UpdateTitle({
        id: data.id,
        label,
        type: selectedType,
        order: order,
      }).then((r) => {
        if (r.status === "success") {
          alert(r.message);
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (data && data.label) {
      setLabel(data.label);
      setOrder(data.order);
      let type = typesOptions.find((a) => a === data.type);
      if (type) {
        setSelectedType(type);
        setSelectedCountryID(type);
      }
    }
  }, [typesOptions, data]);

  useEffect(() => {
    setTypesOptions(types);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Görev Güncelle</ModalHeader>
      <ModalBody>
        <Col md="12">
          <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
            <FormGroup>
              <Col md={12}>
                <Label>Görev</Label>
                <AvField
                  name="label"
                  value={label}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  onChange={(e) => setLabel(e.target.value.trim())}
                />
              </Col>
              <Col md={12}>
                <Label>Tür</Label>
                <StyledSelect
                  id="selected_type"
                  classNamePrefix="select"
                  value={
                    selectedType &&
                    typesOptions.filter((option) => option === selectedType)
                  }
                  isSearchable={true}
                  name="selected_type"
                  placeholder=""
                  options={typesOptions}
                  getOptionValue={(option) => option}
                  getOptionLabel={(option) => {
                    if (option == "turkonfed") {
                      return "TÜRKONFED Görevi";
                    }
                    if (option == "federation") {
                      return "Federasyon Görevi";
                    }
                    if (option == "association") {
                      return "Dernek Görevi";
                    }
                  }}
                  onChange={(option) => {
                    setSelectedType(option);
                  }}
                />
              </Col>
              <br />
              <Col md={12}>
                <Label>Sıra</Label>
                <AvField
                  name="order"
                  value={order}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  onChange={(e) => setOrder(e.target.value.trim())}
                />
              </Col>
              <Col md={6}>
                <Button
                  color="primary"
                  className=" waves-effect waves-light mr-1 mt-3"
                  type="submit"
                >
                  Güncelle
                </Button>{" "}
              </Col>{" "}
            </FormGroup>
          </AvForm>
        </Col>
      </ModalBody>
    </Modal>
  );
}
export default ViewTitle;
