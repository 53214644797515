import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Initial from "./global/reducer";

// Authentication Module
import Account from "./auth/register/reducer";
import Login from "./auth/login/reducer";
import Forget from "./auth/forgetpwd/reducer";
import Reset from "./auth/resetpwd/reducer";

const rootReducer = combineReducers({
  // public
  Initial,
  Layout,

  // Authentication
  Account,
  Login,
  Forget,
  Reset,
});

export default rootReducer;
