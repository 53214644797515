import api from "../../config/API";

const CreateEducation = async (payload) => {
  return api
    .post(`/education`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default CreateEducation;
