import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//AUTH related methods

//api

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import registerRequest from "../../../api/user/registerUserRequest";
// initialize firebase Auth

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(registerRequest, user);
    if (response.status === "success") {
      toastr.success("Kaydınız başarıyla oluştu.");
      /* localStorage.setItem("authUser", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.access_token)); 
      yield put(registerUserSuccessful(response.data));
      history.push("/platform"); */
      history.push("/giris");
    } else {
      alert(response.message);
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
