import api from "../../config/API";

const DeleteTitle = async (id = 0) => {
  return api
    .delete(`/titles/${id}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default DeleteTitle;
