import api from "../../config/API";

const SearchFederationUsers = async (payload) => {
  return api
    .post(`/search/federationUsers`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default SearchFederationUsers;
