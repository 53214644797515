import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import "../users/datatables.scss";
import ListSectoralFederations from "../../api/federations/ListSectoralFederations";
import { Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
const FederationList = () => {
  const [federations, setFederations] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ListSectoralFederations(currentPage).then((r) => {
      if (r.status != "success") {
        alert(r.message);
        return;
      }
      setFederations(r.data.data);
      setPagination(
        r.data.links.map((link) => {
          link.page_number = null;
          if (link.label == "&laquo; Previous") {
            link.label = "Önceki";
          }

          if (link.label == "Next &raquo;") {
            link.label = "Sonraki";
          }

          if (!link.url) {
            return link;
          }

          const page_number = link.url.split("=")[1];
          link.page_number = page_number;
          return link;
        })
      );
      setIsLoading(false);
    });
  }, [currentPage]);

  return (
    <>
      <Col>
        <MDBDataTable
          displayEntries={false}
          searching={false}
          noBottomColumns={true}
          responsive
          paging={false}
          sortable={false}
          data={{
            columns: [
              {
                label: "Federasyon",
                field: "title",
                //sort: "asc",
              },
            ],
            rows: federations.map((federation) => ({
              title: <>{federation.title}</>,
            })),
          }}
          className="mt-4"
        />
      </Col>
      {pagination && pagination.length > 3 && (
        <Col>
          <Pagination aria-label="Page navigation example">
            {pagination.map((page) => (
              <PaginationItem
                onClick={() => {
                  if (!page.page_number) return;
                  if (page.page_number == currentPage) return;

                  setCurrentPage(page.page_number);
                }}
                disabled={!page.page_number}
                className={page.page_number == currentPage && `active`}
              >
                <PaginationLink>{page.label}</PaginationLink>
              </PaginationItem>
            ))}
          </Pagination>
        </Col>
      )}
    </>
  );
};

export default FederationList;
