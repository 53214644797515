import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Alert, Container, Spinner } from "reactstrap";

import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logodark from "../../assets/images/logo-dark.png";
import BiaLogo from "../../assets/images/bia-logo.png";

import verifyAccountRequest from "../../api/user/verifyAccountRequest";

const useQuery = () => new URLSearchParams(useLocation().search);

const Verify = (props) => {
  const query = useQuery();
  const id = query.get("id");
  const expires = query.get("expires");
  const hash = query.get("hash");
  const signature = query.get("signature");

  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    verifyAccountRequest({
      expires,
      hash,
      id,
      signature,
    }).then((response) => {
      setStatus(response.status);
      setMessage(response.message);
      setIsLoading(false);
    });
  }, [expires, hash, id, signature]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/">
          <i className="mdi mdi-home-variant h2 text-white"></i>
        </Link>
      </div>
      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              {/* <img src={logodark} height="20" alt="logo" /> */}
                              <img src={BiaLogo} alt="bia" width="200" />
                            </Link>
                          </div>
                          <h4 className="font-size-18 mt-4">Hesap Doğrulama</h4>
                        </div>

                        <div className="p-2 mt-5">
                          {isLoading && (
                            <Row className="justify-content-center">
                              <Spinner color="primary" />
                            </Row>
                          )}
                          {!isLoading && status == "success" && (
                            <Alert color="success" className="mb-4">
                              {message || "Başarılı"}
                            </Alert>
                          )}
                          {!isLoading && status == "error" && (
                            <Alert color="danger" className="mb-4">
                              {message || "Hata"}
                            </Alert>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Verify;
