import api from "../../config/API";

export default async function verifyAccountRequest(data) {
  const params = Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join("&");
  return api
    .post(`/verify?${params}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
}
