import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import styled from "styled-components";
import Select from "react-select";

import UpdateCity from "../../../api/cities/UpdateCity";
import ListAllCountries from "../../../api/countries/ListAllCountries";

const StyledSelect = styled(Select)`
  .select__control {
    border-color: ${(props) => (props.hasError ? "red" : "#ced4da")};
  }
  .select__value-container {
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;

function ViewCity({ data, isOpen, toggle, ...props }) {
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("");
  const [selectedCountryID, setSelectedCountryID] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countriesOptions, setCountriesOptions] = useState("");

  const handleSubmit = (event, values) => {
    if (!title || title == "") {
      alert("Lütfen Şehir girin.");
      return;
    }
    if (!selectedCountryID || selectedCountryID == "") {
      alert("Lütfen Ülke seçin.");
      return;
    }
    if (title && selectedCountryID) {
      UpdateCity({
        id: data.id,
        title,
        country_id: selectedCountryID,
        order: order,
      }).then((r) => {
        if (r.status === "success") {
          alert(r.message);
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (data && data.title) {
      setTitle(data.title);
      setOrder(data.order);
      let country = countriesOptions.find((a) => a.id === data.country_id);
      if (country) {
        setSelectedCountry(country);
        setSelectedCountryID(country.id);
      }
    }
  }, [countriesOptions, data]);

  useEffect(() => {
    ListAllCountries().then((r) => {
      if (r.status === "success") {
        setCountriesOptions(...countriesOptions, r.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Şehir Güncelle</ModalHeader>
      <ModalBody>
        <Col md="12">
          <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
            <FormGroup>
              <Col md={12}>
                <Label>Şehir</Label>
                <AvField
                  name="title"
                  value={title}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value.trim())}
                />
              </Col>
              <Col md={12}>
                <Label>Ülke</Label>
                <StyledSelect
                  id="selected_country_id"
                  classNamePrefix="select"
                  defaultValue={selectedCountry}
                  isSearchable={true}
                  name="selected_country_id"
                  placeholder=""
                  options={countriesOptions}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.title}
                  onChange={(option) => setSelectedCountryID(option.id)}
                />
              </Col>
              <br />
              <Col md={12}>
                <Label>Sıra</Label>
                <AvField
                  name="order"
                  value={order}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  onChange={(e) => setOrder(e.target.value.trim())}
                />
              </Col>
              <Col md={6}>
                <Button
                  color="primary"
                  className=" waves-effect waves-light mr-1 mt-3"
                  type="submit"
                >
                  Güncelle
                </Button>{" "}
              </Col>{" "}
            </FormGroup>
          </AvForm>
        </Col>
      </ModalBody>
    </Modal>
  );
}
export default ViewCity;
