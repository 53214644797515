import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardImg,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

import styled from "styled-components";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";
import "moment/locale/tr";

import history from "../../history";

//dummy images
import img4 from "../../assets/images/bia-logo-son.png";
import DefaultFileIcon from "../../assets/images/default-file-icon.svg";
import isYoutubeLink from "../../utils/isYoutubelink";
import hasAccess from "../../utils/hasAccess";
import DeletePost from "../../api/posts/DeletePost";
import isSuperAdmin from "../../utils/isSuperAdmin";
import PostAvatar from "./PostAvatar";
import ViewPost from "../../pages/Posts/ViewPost";

const FeedPost = ({
  id = null,
  title = null,
  content = null,
  postable = null,
  postable_id = null,
  postable_type = null,
  author = null,
  created_at = null,
  files = [],
  thumbnail_link = null,
  weblink = null,
}) => {
  const [showMore, setshowMore] = useState(false);
  const [images, setImages] = useState([]);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [user, setUser] = useState();

  const [isUserPost, setIsUserPost] = useState(false);

  //lightbox states
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const userobj = JSON.parse(localStorage.getItem("authUser"));

  const generateLink = () => {
    if (postable_type.toLocaleLowerCase().includes("federation")) {
      return `federasyon/${postable_id}`;
    }
    if (postable_type.toLocaleLowerCase().includes("association"))
      return `dernek/${postable_id}`;

      if (postable_type.toLocaleLowerCase().includes("user")) {
        return `kullanici/${postable_id}`;
      }
  };

  const handleDelete = (id) => {
    const confirmation = window.confirm(
      "Veri kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );
    if (!confirmation) return;

    DeletePost(id).then((r) => {
      alert(r.message);
      if (r.status === "success") {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    if (postable_type.toLocaleLowerCase().includes("user")) {
      setIsUserPost(true);
    }

    
  }, []);

  useEffect(() => {
    const tmp_images = [];
    if (thumbnail_link) {
      tmp_images.push({
        id: 0,
        post_id: postable_id,
        name: "Kapak görseli",
        path: "",
        size: 0,
        type: "image",
        created_at: "2",
        updated_at: "",
        link: thumbnail_link,
      });
    }
    if (files && files.length > 0) {
      files.filter((f) => f.type == "image").map((f) => tmp_images.push(f));
      setImages(tmp_images);
    }
  }, [files]);

  return (
    <Row>
      <Col lg={12}>
        <div className="mb-3">
          <Card>
            {/* email toolbar */}

            <CardBody>
              <Row>
                <Col xs={11}>
                  <Link to={generateLink()} className="waves-effect">
                    <Media className="mb-4">
                      {!isUserPost ? ( 
                      <PostAvatar
                        cover={postable.cover_link|| img4}
                        onClick={() => history.push(generateLink())}
                      /> ) : (
                        <PostAvatar
                        cover={author.avatar_link || img4}
                        onClick={() => history.push(generateLink())}
                      />
                      )}
                      <Media body>
                        <h5 className="font-size-14 my-1">
                          {postable && !isUserPost && postable.title}
                          {postable && isUserPost && author.name}
                        </h5>
                        {postable && isUserPost && (
                          <small className="text-muted">
                            {author.title}
                          </small>
                        )}
                        {postable && (isUserPost && author.title && author.company_name) && (" - ")}
                        {postable && isUserPost && (
                          <>
                            <small className="text-muted">
                              {author.company_name}
                            </small>
                            <br />
                          </>
                        )}

                        {created_at && (
                          <>
                            <small
                              id={"post-date-" + id}
                              className="text-muted"
                            >
                              {moment(created_at).fromNow()}
                            </small>
                            <UncontrolledTooltip
                              target={"post-date-" + id}
                              placement="bottom"
                            >
                              {moment(created_at).format("DD.MM.YYYY HH:MM")}
                            </UncontrolledTooltip>
                          </>
                        )}
                      </Media>
                    </Media>
                  </Link>
                </Col>

                {postable &&
                  ((postable.admins && hasAccess(postable.admins)) ||
                    isSuperAdmin()) && !isUserPost && (
                    <Col xs={1}>
                       <Button
                        id={"patch" + id}
                        color="secondary"
                        type="button"
                        className="waves-effect waves-light mr-1"
                        size="sm"
                        onClick={() => setIsViewOpen(true)}
                      >
                        <i className="mdi mdi-pencil"></i>
                      </Button>
                      <UncontrolledTooltip
                        target={"patch" + id}
                        placement="top"
                      >
                        Düzenle
                      </UncontrolledTooltip>
                      
                      <Button
                        id={"delete" + id}
                        color="secondary"
                        type="button"
                        className="waves-effect waves-light mr-1"
                        size="sm"
                        onClick={() => handleDelete(id)}
                      >
                        <i className="mdi mdi-trash-can"></i>
                      </Button>
                      <UncontrolledTooltip
                        target={"delete" + id}
                        placement="top"
                      >
                        Sil
                      </UncontrolledTooltip>
                    </Col>
                  )}
                  {(isUserPost && isSuperAdmin()) && (
                    <Col xs={1}>
                      <Button
                        id={"delete" + id}
                        color="secondary"
                        type="button"
                        className="waves-effect waves-light mr-1"
                        size="sm"
                        onClick={() => handleDelete(id)}
                      >
                        <i className="mdi mdi-trash-can"></i>
                      </Button>
                      <UncontrolledTooltip
                        target={"delete" + id}
                        placement="top"
                      >
                        Sil
                      </UncontrolledTooltip>
                    </Col>
                  )}
                     {(isUserPost && userobj && (userobj.id === postable_id)) && (
                    <Col xs={1}>
                      <Button
                        id={"delete" + id}
                        color="secondary"
                        type="button"
                        className="waves-effect waves-light mr-1"
                        size="sm"
                        onClick={() => handleDelete(id)}
                      >
                        <i className="mdi mdi-trash-can"></i>
                      </Button>
                      <UncontrolledTooltip
                        target={"delete" + id}
                        placement="top"
                      >
                        Sil
                      </UncontrolledTooltip>
                    </Col>
                  )}
                 
              </Row>
              {thumbnail_link && !(weblink && isYoutubeLink(weblink)) && (
                <p>
                  <img
                    className="img-fluid img-thumbnail"
                    src={thumbnail_link}
                    alt="Kapak görseli"
                    style={{
                      width: "auto",
                      maxWidth: "500px",
                      maxHeight: "300px",
                      cursor: "pointer",
                      padding: "20px",
                    }}
                    onClick={() => {
                      setPhotoIndex(0);
                      setIsGalleryOpen(true);
                    }}
                  />
                </p>
              )}
              {weblink && isYoutubeLink(weblink) && (
                <div
                  className="embed-responsive embed-responsive-16by9"
                  style={{ maxWidth: "500px", maxHeight: "300px" }}
                >
                  <iframe
                    title="video4"
                    className="embed-responsive-item"
                    src={weblink}
                  ></iframe>
                </div>
              )}
              <h4 className="mt-0 font-size-16">{title}</h4>
              <p>
                {content.length > 120 && !showMore && (
                  <>
                    {content.slice(0, 120)}
                    <span
                      onClick={() => setshowMore(true)}
                      style={{
                        cursor: "pointer",
                        color: "#5a2d91",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      devamı...
                    </span>
                  </>
                )}
                {(content.length < 120 || showMore) && content}
              </p>
              {weblink && !isYoutubeLink(weblink) && (
                <p>
                  <Button color="light">
                    <a
                      href={weblink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <i className="ri-link font-size-20"></i>&nbsp;
                      {weblink}
                    </a>
                  </Button>
                </p>
              )}
              {files && files.length > 0 && (
                <>
                  <h5 className="mt-0 font-size-14">Dosya Ekleri</h5>
                  <Row>
                    {/* images list first */}
                    {images.map((f, i) => (
                      <Col xl={1} lg={2} md={2} sm={3} xs={4} key={i}>
                        <img
                          id={"file-title-" + f.id}
                          className="img-thumbnail"
                          alt="Nazox"
                          src={f.link}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPhotoIndex(i);
                            setIsGalleryOpen(true);
                          }}
                        />
                        <span>
                          {f.name.length > 14
                            ? f.name.slice(0, 14).concat("...")
                            : f.name}
                        </span>
                        <UncontrolledTooltip
                          target={"file-title-" + f.id}
                          placement="bottom"
                        >
                          {f.name}
                        </UncontrolledTooltip>
                      </Col>
                    ))}

                    {/* other files after  */}
                    {files
                      .filter((f) => f.type != "image")
                      .map((f, i) => (
                        <Col xl={1} lg={2} md={2} sm={3} xs={4} key={i}>
                          <a
                            id={"file-title-" + f.id}
                            href={f.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <img
                              className="img-thumbnail"
                              alt="Nazox"
                              src={DefaultFileIcon}
                            />
                            <span>
                              {f.name.length > 14
                                ? f.name.slice(0, 14).concat("...")
                                : f.name}
                            </span>
                            <UncontrolledTooltip
                              target={"file-title-" + f.id}
                              placement="bottom"
                            >
                              {f.name}
                            </UncontrolledTooltip>
                          </a>
                        </Col>
                      ))}
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </Col>

      {/* Lightbox */}
      {isGalleryOpen && images.length > 0 && (
        <Lightbox
          toolbarButtons={[
            <a
              href={images[photoIndex].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ backgroundColor: "unset", border: "unset" }}>
                <i className="ri-download-line font-size-20"></i>
              </Button>
            </a>,
          ]}
          mainSrc={images[photoIndex].link}
          nextSrc={images[(photoIndex + 1) % images.length].link}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].link
          }
          enableZoom={true}
          onCloseRequest={() => setIsGalleryOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageCaption={images[photoIndex].name}
          clickOutsideToClose={true}
        />
      )}
      <ViewPost
        data={{
          id: id,
          title: title,
          content: content,
          postable: postable,
          postable_id: postable_id,
          created_at: created_at,
          selectedFiles: files,
          thumbnail_link: thumbnail_link,
          weblink: weblink,
        }}
        isOpen={isViewOpen}
        toggle={() => setIsViewOpen(!isViewOpen)}
      />
    </Row>
  );
};

export default FeedPost;
