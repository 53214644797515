import api from "../../config/API";

const ListAssociationPosts = async (page, payload) => {
  return api
    .post(`/listAssociationPosts?page=${page}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListAssociationPosts;
