import React, { useState, useEffect } from "react";
import ListNews from "../../api/news/ListNews";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Button,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import "moment/locale/tr";
import DeleteNews from "../../api/news/DeleteNews";

const NewsList = ({ editable = false }) => {
  const [news, setNews] = useState([]);
  const [currentNewsPage, setCurrentNewsPage] = useState(1);
  const [lasNewsPage, setLasNewsPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = (id) => {
    const confirmation = window.confirm(
      "Veri kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );
    if (!confirmation) return;

    DeleteNews(id).then((r) => {
      alert(r.message);
      if (r.status === "success") {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    ListNews(currentNewsPage).then((r) => {
      setIsLoading(false);
      if (r.status != "success") {
        alert(r.message);
      }
      setNews([...news, ...r.data.data]);
      setCurrentNewsPage(r.data.current_page);
      setLasNewsPage(r.data.last_page);
    });
  }, [currentNewsPage]);
  return (
    <>
      <Row>
        {news &&
          news.length > 0 &&
          news.map((item, index) => (
            <Col lg={12} key={index}>
              <Card>
                <Row className="no-gutters align-items-center">
                  {item.cover_link && (
                    <Col md={4}>
                      <CardImg
                        className="img-fluid"
                        src={item.cover_link}
                        alt="Skote"
                      />
                    </Col>
                  )}
                  <Col md={item.cover_link ? 8 : 12}>
                    <CardBody>
                      <Row>
                        <Col xs={!editable ? 12 : 11}>
                          <CardTitle>{item.title}</CardTitle>
                        </Col>
                        {editable && (
                          <Col xs={1}>
                            <Button
                              id={"delete" + item.id}
                              color="secondary"
                              type="button"
                              className="waves-effect waves-light mr-1"
                              size="sm"
                              onClick={() => handleDelete(item.id)}
                            >
                              <i className="mdi mdi-trash-can"></i>
                            </Button>
                            <UncontrolledTooltip
                              target={"delete" + item.id}
                              placement="top"
                            >
                              Sil
                            </UncontrolledTooltip>
                          </Col>
                        )}
                      </Row>
                      <CardText>
                        {item.description}
                        {item.weblink && (
                          <>
                            <br />
                            <br />
                            <a
                              href={item.weblink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button size={"sm"}>Linke git</Button>
                            </a>
                          </>
                        )}
                      </CardText>
                      <CardText>
                        <small className="text-muted">
                          {moment(item.created_at).fromNow()}
                        </small>
                      </CardText>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
      </Row>
      {isLoading && (
        <Row className="justify-content-center">
          <Spinner className="font-size-20" color="primary" />
        </Row>
      )}
      {currentNewsPage != lasNewsPage && (
        <Row className="justify-content-center">
          <Button onClick={() => setCurrentNewsPage(currentNewsPage + 1)}>
            daha fazla
          </Button>
        </Row>
      )}
    </>
  );
};

export default NewsList;
