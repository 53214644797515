import React, { Component } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";

import checkPasswordTokenRequest from "../../api/user/checkPasswordTokenRequest";
import resetPasswordRequest from "../../api/user/resetPasswordRequest";

//Images
import BiaLogo from "../../assets/images/bia-logo.png";

const CustomButton = styled(Button)`
  background: #5a2d91;
  border-color: #5a2d91;
  border-radius: 0;
  &:hover,
  :active {
    background: #5a2d91 !important;
    border-color: #5a2d91 !important;
  }
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      password: "",
      password_confirmation: "",
      tokenError: null,
      pwdError: false,
      isLoading: true,
      status: "",
      response_message: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search);

    let token = query.get("token");

    if (!token) {
      this.setState({
        tokenError: "Şifre sıfırlama bağlantınız geçerli değil.",
        isLoading: false,
      });
    } else {
      checkPasswordTokenRequest({ token }).then((r) => {
        if (r.status == "error") {
          this.setState({
            tokenError: r.message,
            isLoading: false,
          });
        }

        this.setState({
          token: token,
          isLoading: false,
        });
      });
    }
  }

  handleChange(event) {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  resetErrors() {
    this.setState({
      pwdError: false,
    });
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.resetErrors();
    if (this.state.password != this.state.password_confirmation) {
      this.setState({
        pwdError: true,
      });
      return false;
    }

    this.setState({
      isLoading: true,
    });
    resetPasswordRequest({
      token: this.state.token,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    }).then((r) => {
      this.setState({
        isLoading: false,
        status: r.status,
        response_message: r.message,
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={3}>
                        {this.state.isLoading && (
                          <Row className="justify-content-center">
                            <Spinner color="primary" />
                          </Row>
                        )}
                        {this.state.tokenError && !this.state.isLoading && (
                          <Alert color="danger" className="mb-4">
                            {this.state.tokenError ||
                              "Sıfırlama bağlantınız hatalı"}
                          </Alert>
                        )}
                        {!this.state.tokenError && !this.state.isLoading && (
                          <div>
                            <div className="text-center">
                              <div>
                                <Link to="/" className="logo">
                                  {/* <img src={logodark} height="20" alt="logo" /> */}
                                  <img src={BiaLogo} alt="bia" width="200" />
                                </Link>
                              </div>

                              <h4 className="font-size-18 mt-4">
                                Şifre Değiştirme
                              </h4>
                              <p className="text-muted">
                                Aşağıdaki kısımdan şifreni güncelleyebilirsin
                              </p>
                            </div>

                            <div className="p-2 mt-5">
                              {this.props.forgetError &&
                              this.props.forgetError ? (
                                <Alert color="danger" className="mb-4">
                                  {this.props.forgetError}
                                </Alert>
                              ) : null}
                              {this.props.message ? (
                                <Alert color="success" className="mb-4">
                                  {this.props.message}
                                </Alert>
                              ) : null}
                              <AvForm
                                className="form-horizontal"
                                onValidSubmit={this.handleValidSubmit}
                              >
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-mail-line auti-custom-input-icon"></i>
                                  <Label htmlFor="password">Şifre</Label>
                                  <AvField
                                    name="password"
                                    value={this.state.password}
                                    type="password"
                                    validate={{ required: true }}
                                    className="form-control"
                                    id="password"
                                    placeholder="Şifre"
                                    errorMessage="Geçerli bir veri girin!"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </FormGroup>
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-mail-line auti-custom-input-icon"></i>
                                  <Label htmlFor="password_confirmation">
                                    Şifre Tekrar
                                  </Label>
                                  <AvField
                                    name="password_confirmation"
                                    value={this.state.password_confirmation}
                                    type="password"
                                    validate={{
                                      match: { value: "password" },
                                      required: true,
                                    }}
                                    className="form-control"
                                    id="password_confirmation"
                                    placeholder="Şifre Tekrar"
                                    errorMessage="Şifreler birbirinden farklı olmamalıdır!"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </FormGroup>
                                {this.state.pwdError && (
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: "11px", color: "red" }}
                                  >
                                    Şifreler uyuşmuyor.
                                  </p>
                                )}
                                {this.state.status == "error" && (
                                  <Alert color="danger" className="mb-4">
                                    {this.state.response_message ||
                                      "Sorun oluştu, lütfen tekrar deneyiniz"}
                                  </Alert>
                                )}

                                {this.state.status == "success" && (
                                  <Alert color="success" className="mb-4">
                                    {this.state.response_message ||
                                      "Şifreniz başarıyla değiştirildi."}
                                  </Alert>
                                )}
                                {!this.state.isLoading &&
                                  this.state.status != "success" && (
                                    <div className="mt-4 text-center">
                                      <CustomButton
                                        color="primary"
                                        className="w-md waves-effect waves-light"
                                        type="submit"
                                      >
                                        Şifre değiştir
                                      </CustomButton>
                                    </div>
                                  )}
                              </AvForm>
                            </div>

                            <div className="mt-5 text-center">
                              <p>
                                Hesabın var mı?{" "}
                                <Link
                                  to="/giris"
                                  className="font-weight-medium text-primary"
                                >
                                  {" "}
                                  Giriş Yap{" "}
                                </Link>{" "}
                              </p>
                              {/*      <p>
                              © 2020 Nazox. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger"></i> by
                              Themesdesign
                            </p> */}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/*  <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, forgetError, loading } = state.Forget;
  return { message, forgetError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { forgetUser })(ResetPassword)
);
