import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Alert,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

//api
import ListPendingApprovalUsers from "../../api/adminUser/ListPendingApprovalUsers";
import confirmUserRequest from "../../api/user/confirmUserRequest";
import DeleteAdminUser from "../../api/adminUser/DeleteAdminUser";


// Redux
import { connect } from "react-redux";

import ConfirmationMenu from "../../components/VerticalLayout/ConfirmationMenu";
import ViewUser from "../users/ViewUser";
class UserConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Yetkilendirme", link: "#" },
        { title: "Onay Bekleyen Kullanıcılar", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      waiting_admin_users: [],
      waiting_email_users: [],
      isViewOpen: false,
      viewed: {},
    };
    this.handleConfirmation.bind(this);
    this.handleDelete.bind(this);


    ListPendingApprovalUsers().then((r) => {
      if (r.status === "success") {
        console.log(r);
        this.setState({
          waiting_admin_users: r.data.waiting_admin_confirmation,
          waiting_email_users: r.data.waiting_email_confirmation,
        });
      }
    });
  }

  handleConfirmation = (user, confirmation) => {
    const answer = window.confirm(
      confirmation == 1
        ? "Seçilen kullanıcının, sistemi kullanması için onay veriyor musunuz?"
        : "Seçilen kullanıcının, sistemi kullanması engellenecektir ve tekrar aynı e-posta adresi ile giriş yapamayacaktır. Bu işlemi onaylıyor musunuz?"
    );

    if (!answer) {
      return;
    }

    if (user) {
      console.log(user, confirmation);

      confirmUserRequest({ id: user.id, confirmation: confirmation }).then(
        (r) => {
          alert(r.message);
          if (r.status === "success") {
            var array = [...this.state.waiting_admin_users];
            this.setState({
              waiting_admin_confirmation: array.filter(
                (arr, i) => arr.id !== user.id
              ),
            });
            window.location.reload();
          }
        }
      );
    }
  };

  handleViewOpen = (row) => {
    this.setState({
      viewed: row,
      isViewOpen: true,
    });
  };

  handleDelete = (user) => {
    const answer = window.confirm(
      "Seçilen başvuru, e-posta onayı beklenmeden kalıcı olarak silinecektir. Bu işlemi onaylıyor musunuz?"
    );

    if (!answer) {
      return;
    }

    if (user) {
      DeleteAdminUser({ id: user.id }).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          var array = [...this.state.waiting_admin_users];
          this.setState({
            waiting_admin_confirmation: array.filter(
              (arr, i) => arr.id !== user.id
            ),
          });
          window.location.reload();
        }
      });
    }
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="ONAY BEKLEYEN KULLANICILAR"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive mt-3">
                      <Table
                        className="table-centered datatable dt-responsive nowrap "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "137px" }}>İşlemler</th>
                            <th>İsim Soyisim</th>
                            <th>E-posta</th>
                            <th>Telefon</th>
                            <th>Federasyon</th>
                            <th>Dernek</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.waiting_admin_users.map(
                            (customerData, key) => (
                              <tr key={key}>
                                <td style={{}}>
                                  <Button
                                    id={"view" + key}
                                    color="warning"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleViewOpen(customerData)
                                    }
                                  >
                                    <i className="ri-eye-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"view" + key}
                                    placement="top"
                                  >
                                    Görüntüle
                                  </UncontrolledTooltip>
                                  <Button
                                    id={"confirm1" + key}
                                    color="success"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleConfirmation(customerData, 1)
                                    }
                                  >
                                    <i className=" ri-check-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"confirm1" + key}
                                    placement="top"
                                  >
                                    Onayla
                                  </UncontrolledTooltip>
                                  <Button
                                    id={"reject" + key}
                                    color="primary"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleConfirmation(customerData, 0)
                                    }
                                  >
                                    <i className="ri-close-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"reject" + key}
                                    placement="top"
                                  >
                                    Reddet
                                  </UncontrolledTooltip>
                                </td>

                                <td>{customerData.name || ""}</td>
                                <td>{customerData.email || ""}</td>
                                <td>{customerData.phone || ""}</td>
                                <td>
                                  {customerData.associations.length > 0
                                    ? customerData.associations.filter(
                                        (a) => a.pivot.is_main == 1
                                      )[0].federation.title
                                    : ""}
                                </td>
                                <td>
                                  {customerData.associations.length > 0
                                    ? customerData.associations.filter(
                                        (a) => a.pivot.is_main == 1
                                      )[0].title
                                    : ""}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
              >
                Türkonfed Yetkili Kullanıcısı Ekle
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.addCustomer}>
                  <Row>
                    <Col lg={12}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Başarıyla Eklendi...!
                      </Alert>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="name">İsim Soyisim</Label>
                        <AvField
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder=""
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="email">E-posta</Label>
                        <AvField
                          name="email"
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder=""
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      İptal
                    </Button>
                    <Button type="submit" color="primary">
                      Ekle
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
          <br />
          <br />
          <br />
          <br />
          <Container fluid>
            <Breadcrumbs
              title="E-POSTA DOĞRULAMASI BEKLEYEN KULLANICILAR"
              breadcrumbItems={[
                { title: "Yetkilendirme", link: "#" },
                {
                  title: "E-posta Doğrulaması Bekleyen Kullanıcılar",
                  link: "#",
                },
              ]}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive mt-3">
                      <Table
                        className="table-centered datatable dt-responsive nowrap "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "137px" }}>İşlemler</th>
                            <th>İsim Soyisim</th>
                            <th>E-posta</th>
                            <th>Telefon</th>
                            <th>Federasyon</th>
                            <th>Dernek</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.waiting_email_users.map(
                            (customerData, key) => (
                              <tr key={key}>
                                <td style={{}}>
                                  <Button
                                    id={"view2" + key}
                                    color="warning"
                                    type="button"
                                    className="waves-effect waves-light mr-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleViewOpen(customerData)
                                    }
                                  >
                                    <i className="ri-eye-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"view2" + key}
                                    placement="top"
                                  >
                                    Görüntüle
                                  </UncontrolledTooltip>
                                  {/*  <ConfirmationMenu
                                    onConfirm={() =>
                                      this.handleConfirmation(customerData, 1)
                                    }
                                    onReject={() =>
                                      this.handleConfirmation(customerData, 0)
                                    }
                                  /> */}
                                  <Button
                                    id={"confirm" + key}
                                    color="info"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleConfirmation(customerData, 1)
                                    }
                                  >
                                    <i className="ri-check-double-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"confirm" + key}
                                    placement="top"
                                  >
                                    E-posta Beklemeden Onayla
                                  </UncontrolledTooltip>
                                  <Button
                                    id={"delete" + key}
                                    color="danger"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleDelete(customerData)
                                    }
                                  >
                                    <i className="ri-delete-bin-2-fill align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"delete" + key}
                                    placement="top"
                                  >
                                    E-posta Beklemeden Reddet
                                  </UncontrolledTooltip>
                                </td>

                                <td>{customerData.name || ""}</td>
                                <td>{customerData.email || ""}</td>
                                <td>{customerData.phone || ""}</td>
                                <td>
                                  {customerData.associations.length > 0
                                    ? customerData.associations.filter(
                                        (a) => a.pivot.is_main == 1
                                      )[0].federation.title
                                    : ""}
                                </td>
                                <td>
                                  {customerData.associations.length > 0
                                    ? customerData.associations.filter(
                                        (a) => a.pivot.is_main == 1
                                      )[0].title
                                    : ""}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <ViewUser
              data={this.state.viewed}
              isOpen={this.state.isViewOpen}
              toggle={() =>
                this.setState({ isViewOpen: !this.state.isViewOpen })
              }
            />
            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
              >
                Türkonfed Yetkili Kullanıcısı Ekle
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.addCustomer}>
                  <Row>
                    <Col lg={12}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Başarıyla Eklendi...!
                      </Alert>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="name">İsim Soyisim</Label>
                        <AvField
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder=""
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="email">E-posta</Label>
                        <AvField
                          name="email"
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder=""
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      İptal
                    </Button>
                    <Button type="submit" color="primary">
                      Ekle
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(UserConfirmation);
