import {
  RESET_USER,
  RESET_USER_SUCCESSFUL,
  RESET_PASSWORD_API_FAILED,
} from "./actionTypes";

export const resetUser = (user, history) => {
  return {
    type: RESET_USER,
    payload: { user, history },
  };
};

export const resetUserSuccessful = (message) => {
  return {
    type: RESET_USER_SUCCESSFUL,
    payload: message,
  };
};

export const userResetPasswordError = (error) => {
  return {
    type: RESET_PASSWORD_API_FAILED,
    payload: error,
  };
};
