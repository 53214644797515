import React, { Component } from "react";
import styled from "styled-components";
import history from "../../history";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ListOptions from "../../api/ListOptions";
import uniqueMergeArrays from "../../utils/uniqueMergeArrays";
import SearchCities from "../../api/search/SearchCities";
import customFilterForSelect from "../../utils/customFilterForSelect";

//Images
import BiaLogo from "../../assets/images/bia-logo.png";

const CustomButton = styled(Button)`
  background: #5a2d91;
  border-color: #5a2d91;
  border-radius: 0;
  &:hover,
  :active {
    background: #5a2d91 !important;
    border-color: #5a2d91 !important;
  }
`;

const StyledSelect = styled(Select)`
  .select__control {
    height: 60px !important;
    border-color: ${(props) => (props.hasError ? "red" : "#ced4da")};
  }
  .select__value-container {
    margin-left: 60px;
    top: 10px;
    padding: 0;
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;
const StyledLabel = styled(Label)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 14.4px;
`;

class Register2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number_employees: null,
      website: null,
      company_name: null,
      company_sector: null,
      sub_sector: null,
      company_address: null,
      company_country: null,
      company_city: null,
      company_post_code: null,

      company_sector_error: false,
      company_country_error: false,
      company_city_error: false,

      sectorOptions: [],
      countriesOptions: [],
      citiesOptions: [],
      citySearching: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    ListOptions().then((r) => {
      if (r.status == "success") {
        this.setState({
          sectorOptions: r.data.sectors,
          countriesOptions: r.data.countries,
          citiesOptions: r.data.cities,
        });
      }
    });
  }

  hasInputError() {
    let errors = 0;

    if (!this.state.company_sector) {
      errors++;
      this.setState({
        company_sector_error: true,
      });
    }

    if (!this.state.company_country) {
      errors++;
      this.setState({
        company_country_error: true,
      });
    }
    if (!this.state.company_city) {
      errors++;
      this.setState({
        company_city_error: true,
      });
    }

    return errors == 0;
  }

  handleSubmit(event, values) {
    if (!this.hasInputError()) {
      return;
    }

    if (values) {
      const data = {
        ...this.props.location.state,
        sector_id: this.state.company_sector,
        country_id: this.state.company_country,
        city_id: this.state.company_city,
        employee_count: this.state.number_employees,
        website: this.state.website,
        company_name: this.state.company_name,
        postal_code: this.state.company_post_code,
        address: this.state.company_address,
        sub_sector: this.state.sub_sector,
      };
      this.props.history.push("/kayit-formu-3", data);
    }
  }

  citySearch(searchQuery) {
    if (!searchQuery) return;
    this.setState({
      citySearching: true,
    });
    this.waitforTypeEnd("sectorTimeout", 500, () => {
      SearchCities({
        title: searchQuery,
      }).then((r) => {
        this.setState({
          citySearching: false,
        });
        if (r.status !== "success") return;

        this.setState({
          citiesOptions: uniqueMergeArrays(this.state.citiesOptions, r.data),
        });
      });
    });
  }

  waitforTypeEnd(eventName, waitTime, callback) {
    if (this[eventName]) clearTimeout(this[eventName]);
    this[eventName] = setTimeout(() => {
      callback();
    }, waitTime);
  }

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0" style={{ minHeight: "100vh" }}>
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100 h-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={BiaLogo} alt="bia" width="200" />
                                {/*  <img src={logodark} height="20" alt="logo" /> */}
                              </Link>
                            </div>
                            <p className="text-muted">2/3</p>
                            <h4 className="font-size-18 mt-4">
                              Kullanıcı Kayıt Formu
                            </h4>
                            <p className="text-muted">Firma Bilgileri</p>
                          </div>

                          {this.props.user && (
                            <Alert color="success">
                              Registration Done Successfully.
                            </Alert>
                          )}

                          {this.props.registrationError && (
                            <Alert color="danger">
                              {this.props.registrationError}
                            </Alert>
                          )}

                          <div className="p-2 mt-5">
                            <AvForm
                              onValidSubmit={this.handleSubmit}
                              className="form-horizontal"
                            >
                              <Row>
                                <Col lg={6}>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="company_name">
                                      Firma İsmi *
                                    </Label>
                                    <AvField
                                      name="company_name"
                                      value={this.state.company_name}
                                      validate={{ required: { value: true } }}
                                      type="text"
                                      className="form-control"
                                      id="company_name"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          company_name: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="number_employees">
                                      Çalışan Sayısı
                                    </Label>
                                    <AvField
                                      name="number_employees"
                                      value={this.state.number_employees}
                                      type="text"
                                      className="form-control"
                                      id="number_employees"
                                      maxLength="10"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          number_employees: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="website">Web Sitesi</Label>
                                    <AvField
                                      name="website"
                                      value={this.state.website}
                                      type="text"
                                      className="form-control"
                                      id="website"
                                      placeholder="Başında http:// olmadan giriş yapınız. (Örnek: www.turkonfed.org)"
                                      onChange={(e) =>
                                        this.setState({
                                          website: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="company_sector">
                                      Sektör *
                                    </StyledLabel>
                                    <StyledSelect
                                      hasError={this.state.company_sector_error}
                                      id="company_sector"
                                      classNamePrefix="select"
                                      defaultValue={this.state.company_sector}
                                      isSearchable={true}
                                      name="company_sector"
                                      placeholder=""
                                      options={this.state.sectorOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        this.setState({
                                          company_sector_error: false,
                                          company_sector: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  {" "}
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="company_address">
                                      Adres *
                                    </Label>
                                    <AvField
                                      name="company_address"
                                      value={this.state.company_address}
                                      validate={{ required: { value: true } }}
                                      type="text"
                                      className="form-control"
                                      id="company_address"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          company_address: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="company_post_code">
                                      Posta Kodu *
                                    </Label>
                                    <AvField
                                      name="company_post_code"
                                      value={this.state.company_post_code}
                                      validate={{ required: { value: true } }}
                                      type="text"
                                      className="form-control"
                                      id="company_post_code"
                                      maxLength="10"
                                      placeholder=""
                                      onChange={(e) => {
                                        this.setState({
                                          company_post_code: e.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="company_city">
                                      Şehir *
                                    </StyledLabel>
                                    <StyledSelect
                                      id="company_city"
                                      classNamePrefix="select"
                                      hasError={this.state.company_city_error}
                                      defaultValue={this.state.company_city}
                                      isSearchable={true}
                                      isLoading={this.state.citySearching}
                                      onInputChange={(e) => this.citySearch(e)}
                                      filterOption={(option, searchText) =>
                                        customFilterForSelect(
                                          option,
                                          searchText,
                                          "title"
                                        )
                                      }
                                      name="company_city"
                                      placeholder=""
                                      options={this.state.citiesOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        this.setState({
                                          company_city_error: false,
                                          company_city: option.id,
                                          company_country: option.country_id,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-information-line auti-custom-input-icon"></i>
                                    <Label htmlFor="sub_sector">Alt Sektör</Label>
                                    <AvField
                                      name="sub_sector"
                                      value={this.state.sub_sector}
                                      type="text"
                                      className="form-control"
                                      id="sub_sector"
                                      placeholder=""
                                      onChange={(e) =>
                                        this.setState({
                                          sub_sector: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                </Col>
                              </Row>

                              <div className="text-center">
                                <CustomButton
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  {this.props.loading
                                    ? "Yükleniyor ..."
                                    : "İleri"}
                                </CustomButton>
                              </div>

                              {/* <div className="mt-4 text-center">
                                <p className="mb-0">
                                  By registering you agree to the Nazox{" "}
                                  <Link to="#" className="text-primary">
                                    Terms of Use
                                  </Link>
                                </p>
                              </div> */}
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              Zaten bir hesabınız var mı?{" "}
                              <Link
                                to="/giris"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Giriş Yap
                              </Link>{" "}
                            </p>
                            {/*   <p>
                              © 2020 Nazox. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger"></i> by
                              Themesdesign
                            </p> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/*  <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register2);
