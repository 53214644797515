import api from "../../config/API";

const SearchUsers = async (payload) => {
  return api
    .post(`/search/users`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default SearchUsers;
