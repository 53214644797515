import React from "react";
import styled from "styled-components";
import DataTable from "react-data-table-component";

const TableDiv = styled(DataTable)`
  width: 100%;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  & .rdt_Table {
    display: ${(props) => (props.showTable ? "flex" : "none")};
  }
`;

const customStyles = {
  headRow: {
    style: {
      //border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      borderRadius: "25px",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const Table = (props) => <TableDiv customStyles={customStyles} {...props} />;

export default Table;
