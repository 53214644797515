import api from "../../config/API";

const listUsersRequest = async (page, payload) => {
  return api
    .post(`/listUsers?page=${page}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default listUsersRequest;
/*
"id"                -- (int)
"name"              -- //User's name
"email"             --
"title"             --
"company_name"      --
"sector_id"         -- (int)
"region_id"         -- (int)
"country_id"        -- (int)
"city_id"           -- (int)
"federation_id"     -- (int)
"association_id"    -- (int)
*/
