import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardImg,
} from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import isSuperAdmin from "../../../utils/isSuperAdmin";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    let username = "Admin";
    let avatar_link = "";
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const uNm = obj.email.split("@")[0];
      const useremailfirst = uNm.charAt(0).toUpperCase() + uNm.slice(1);
      username = obj.name;
      avatar_link = obj.avatar_link;
    }

    return (
      <React.Fragment>
         <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
        {avatar_link != null ? (
        <CardImg
          src={avatar_link}
          className="rounded-circle img-fluid mr-2"
          style={{ width: 50, height: 50 }}
        />
        ) : null}
          
            {/*  <img className="rounded-circle header-profile-user mr-1" src={avatar2} alt="Header Avatar"/> */}
            <span className="ml-1 text-transform">{username}</span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            {!isSuperAdmin() && (
              <>
                <DropdownItem href="/profil">
                  <i className="ri-user-line align-middle mr-1"></i>{" "}
                  {this.props.t("Profil Düzenleme")}
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}

            <DropdownItem className="text-danger" href="/logout">
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              Çıkış
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
