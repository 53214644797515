import React, { useEffect, useState } from "react";
import ListAssociationPosts from "../../api/posts/ListAssociationPosts";
import FeedPost from "../../components/Common/FeedPost";
import { Row, Button, Spinner } from "reactstrap";

const AssociationPostList = ({ association_id }) => {
  const [posts, setPosts] = useState([]);
  const [currentPostPage, setCurrentPostPage] = useState(1);
  const [lastPostPage, setLastPostPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const loadPost = (nextPage = 1, fed_id = null) => {
    setIsLoading(true);
    ListAssociationPosts(nextPage, { association_id: association_id }).then(
      (r) => {
        setIsLoading(false);
        if (r.status != "success") {
          alert(r.message);
          return;
        }
        setPosts([...posts, ...r.data.data]);
        setCurrentPostPage(r.data.current_page);
        setLastPostPage(r.data.last_page);
      }
    );
  };
  useEffect(() => {
    if (!association_id) return;
    loadPost(currentPostPage, association_id);
  }, [association_id]);
  return (
    <>
      {posts && posts.map((post, index) => <FeedPost key={index} {...post} />)}
      {isLoading && (
        <Row className="justify-content-center mb-3">
          <Spinner className="font-size-20" color="primary" />
        </Row>
      )}
      {currentPostPage != lastPostPage && (
        <Row className="justify-content-center mb-4">
          <Button onClick={() => loadPost(currentPostPage + 1, association_id)}>
            daha fazla
          </Button>
        </Row>
      )}
    </>
  );
};

export default AssociationPostList;
