import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

//Cookie Permission
import CookiePermissions from "./components/Common/CookiePermissions";

// Import scss
import "./theme.scss";

import history from "./history";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <CookiePermissions />
        <Router history={history}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                title={route.title || ""}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => {
              if (route.homepage == true) {
                return (
                  <AppRoute
                    path={route.path}
                    title={route.title || ""}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                  />
                );
              }
              return (
                <AppRoute
                  path={route.path}
                  title={route.title || ""}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  onlySuperAdmin={route.onlySuperAdmin}
                />
              );
            })}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
