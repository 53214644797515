import api from "../../config/API";

const GetAdminUser = async () => {
  return api
    .get(`/adminUsers`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default GetAdminUser;
