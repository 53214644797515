import api from "../../config/API";

const updateUserRequest = async (payload) => {
  return api
    .post(`/updateUser`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default updateUserRequest;
