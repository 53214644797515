// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { CALL_DATA } from "./actionTypes";

import { updateData } from "./actions";

//import getInitialData from "../../api/getInitialData";

import api, { setToken } from "../../config/API";

function* setTokenSaga() {
  if (!api) {
    return;
  }
  const token = window.localStorage.getItem("token");
  yield call(setToken, token);
}

function* updateInitialData() {
  try {
    /*  const response = yield call(getInitialData);
      if (response) {
        yield put(updateData(response));
      } */
  } catch (error) {}
}

function* watchRequestInitialData() {
  yield takeEvery(CALL_DATA, updateInitialData);
}

function* InitialSaga() {
  yield all([
    fork(watchRequestInitialData),
    call(setTokenSaga),
    call(updateInitialData),
  ]);
}

export default InitialSaga;
