import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  CardImg,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
//Import Breadcrumb
import ViewFederation from "./ViewFederation";
import Table from "../../../components/Common/Table";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";

//Dropzone
import Dropzone from "react-dropzone";

//api
import CreateFederation from "../../../api/federations/CreateFederation";
import DeleteFederation from "../../../api/federations/DeleteFederation";
import ListAllRegions from "../../../api/regions/ListAllRegions";
import ListFederationsPaginate from "../../../api/federations/ListFederationsPaginate";
// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../../store/actions";
import SearchCities from "../../../api/search/SearchCities";
import SearchDistricts from "../../../api/search/SearchDistricts";
import uniqueMergeArrays from "../../../utils/uniqueMergeArrays";
import customFilterForSelect from "../../../utils/customFilterForSelect";

class Federation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      viewed: {},
      federations: [],
      isViewOpen: false,
      regions: [],
      cities: [],
      citySearching: false,
      districts: [],
      districtSearching: false,
      country_id: null,
      region_id: null,
      city_id: null,
      district_id: null,
      country_error: false,
      district_error: false,
      company_city_error: false,
      foundation_date: null,
      modal_static: false,
      address: null,
      district: null,
      postal_code: null,
      email: null,
      phone: null,
      mobile_phone: null,
      fax: null,
      website: null,
      president_name: null,
      secretary_name: null,
      is_sectoral: false,
      selectedLogo: null,
      pagination: null,
      currentPage: 1,
      isLoading: false,
    };
    this.getPaginatedFederations = this.getPaginatedFederations.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);

    SearchCities({
      title: "",
    }).then((r) => {
      if (r.status !== "success") return;
      this.setState({
        cities: uniqueMergeArrays(this.state.cities, r.data),
      });
    });

    ListAllRegions().then((r) => {
      if (r.status !== "success") return;
      this.setState({
        regions: r.data,
      });
    });

    this.getPaginatedFederations(this.state.currentPage);
  }

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedLogo: files[0] });
  };

  getPaginatedFederations = (currPage) => {
    this.setState({ isLoading: true, currentPage: currPage });
    ListFederationsPaginate(currPage).then((r) => {
      if (r.status === "success") {
        this.setState({
          federations: r.data.data,
          pagination: r.data.links.map((link) => {
            link.page_number = null;
            if (link.label == "&laquo; Previous") {
              link.label = "Önceki";
            }

            if (link.label == "Next &raquo;") {
              link.label = "Sonraki";
            }

            if (!link.url) {
              return link;
            }

            const page_number = link.url.split("=")[1];
            link.page_number = page_number;
            return link;
          }),
          isLoading: false,
        });
      }
    });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  resetState() {
    this.setState({
      title: "",
      viewed: {},
      isViewOpen: false,
      country_id: null,
      region_id: null,
      city_id: null,
      district_id: null,
      country_error: false,
      company_city_error: false,
      foundation_date: null,
      modal_static: false,
      address: null,
      district: null,
      postal_code: null,
      email: null,
      phone: null,
      mobile_phone: null,
      fax: null,
      website: null,
      president_name: null,
      secretary_name: null,
      is_sectoral: false,
      selectedLogo: null,
    });
  }
  handleViewOpen(row) {
    this.setState({
      viewed: row,
      isViewOpen: true,
    });
  }

  handleDelete(id) {
    const confirmation = window.confirm(
      "Veri kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );
    if (!confirmation) return;

    DeleteFederation(id).then((r) => {
      if (r.status === "success") {
        alert(r.message);
        var array = [...this.state.federations];
        this.setState({
          federations: array.filter((arr, i) => arr.id !== id),
        });
      }
    });
  }
  hasInputError() {
    let errors = 0;

    if (!this.state.country_id) {
      errors++;
      this.setState({
        country_error: true,
      });
    }

    if (!this.state.city_id) {
      errors++;
      this.setState({
        city_error: true,
      });
    }
    return errors == 0;
  }
  handleSubmit(event, values) {
    /* if (!this.hasInputError()) {
      return;
    } */
    values.country_id = this.state.country_id;
    values.region_id = this.state.region_id;
    values.city_id = this.state.city_id;
    values.district_id = this.state.district_id;
    values.foundation_date = this.state.foundation_date;
    values.is_sectoral = this.state.is_sectoral ? 1 : 0;

    if (values) {
      const formData = new FormData();
      for (var key in values) {
        if (values[key]) formData.append(key, values[key]);
        else if (key == "is_sectoral") {
          formData.append(key, values[key]);
        }
      }

      if (this.state.selectedLogo != null) {
        formData.append("cover_image", this.state.selectedLogo);
      }

      CreateFederation(formData).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          this.setState({
            federations: [...this.state.federations, r.data],
            title: "",
          });
          window.location.reload();
        }
      });
    }
  }

  citySearch(searchQuery) {
    if (!searchQuery) return;
    this.setState({
      citySearching: true,
    });
    this.waitforTypeEnd("sectorTimeout", 500, () => {
      SearchCities({
        title: searchQuery,
      }).then((r) => {
        this.setState({
          citySearching: false,
        });
        if (r.status !== "success") return;

        this.setState({
          cities: uniqueMergeArrays(this.state.cities, r.data),
        });
      });
    });
  }

  districtSearch(searchQuery) {
    if (!searchQuery) return;
    this.setState({
      districtSearching: true,
    });
    this.waitforTypeEnd("districtTimeout", 500, () => {
      SearchDistricts({
        title: searchQuery,
        city_id: this.state.city_id,
      }).then((r) => {
        this.setState({
          districtSearching: false,
        });
        if (r.status !== "success") return;

        this.setState({
          districts: uniqueMergeArrays(this.state.districts, r.data),
        });
      });
    });
  }

  waitforTypeEnd(eventName, waitTime, callback) {
    if (this[eventName]) clearTimeout(this[eventName]);
    this[eventName] = setTimeout(() => {
      callback();
    }, waitTime);
  }

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    const columns = [
      {
        name: "İşlemler",
        cell: (row) => (
          <>
            <Button
              color="warning"
              type="button"
              className="waves-effect waves-light mr-1"
              size="sm"
              onClick={() => this.handleViewOpen(row)}
            >
              <i className="ri-eye-line align-middle"></i>
            </Button>
            <Button
              color="primary"
              type="button"
              className="waves-effect waves-light mr-1"
              size="sm"
              onClick={() => this.handleDelete(row.id)}
            >
              <i className="ri-close-circle-line align-middle"></i>
            </Button>
          </>
        ),
        grow: 0,
      },
      { name: "ID", selector: "id", sortable: true, width: "100px" },
      { name: "Federasyon", selector: "title", sortable: true },
      {
        name: "Bölge",
        selector: "region",
        sortable: true,
        cell: (row) => row.region && <>{row.region.title}</>,
      },
      {
        name: "Sektörel mi ?",
        selector: "is_sectoral",
        sortable: true,
        cell: (row) => <>{row.is_sectoral == 1 ? "Sektörel" : "-"}</>,
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/*  <Breadcrumbs
          title="Federasyon SEÇENEKLERİ"
          breadcrumbItems={breadcrumbItems}
        /> */}
            {/* //todoo */}
            <Row>
              <Col>
                <div>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.resetState();
                      this.setState({
                        modal_static: true,
                        isAlertOpen: false,
                      });
                    }}
                    className="mb-2"
                  >
                    <i className="mdi mdi-plus mr-2"></i>Federasyon Ekle
                  </Button>
                </div>
              </Col>
            </Row>
            {this.state.isLoading && (
              <Row className="mt-2 mb-2">
                <Spinner className="font-size-20" color="primary" />
              </Row>
            )}

            <Row>
              <Col>
                <Row>
                  <Card style={{ width: "100%" }}>
                    <CardBody>
                      <Table
                        noHeader
                        showTable={true}
                        title="Sektörler"
                        columns={columns}
                        data={this.state.federations}
                        defaultSortField="name"
                        progressPending={false}
                      />
                      <ViewFederation
                        data={this.state.viewed}
                        regions={this.state.regions}
                        isOpen={this.state.isViewOpen}
                        toggle={() =>
                          this.setState({
                            isViewOpen: !this.state.isViewOpen,
                          })
                        }
                      />
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>
            {this.state.pagination && this.state.pagination.length > 3 && (
              <Row>
                <Pagination aria-label="Page navigation example">
                  {this.state.pagination.map((page) => (
                    <PaginationItem
                      onClick={() => {
                        if (!page.page_number) return;
                        if (page.page_number == this.state.currentPage) return;

                        this.getPaginatedFederations(page.page_number);
                      }}
                      disabled={!page.page_number}
                      className={
                        page.page_number == this.state.currentPage && `active`
                      }
                    >
                      <PaginationLink>{page.label}</PaginationLink>
                    </PaginationItem>
                  ))}
                </Pagination>
              </Row>
            )}
          </Container>
        </div>
        <Modal
          unmountOnClose={true}
          isOpen={this.state.modal_static}
          centered
          size="lg"
        >
          <ModalHeader toggle={() => this.setState({ modal_static: false })}>
            Federasyon Ekle
          </ModalHeader>
          <ModalBody>
            <AvForm
              onValidSubmit={this.handleSubmit}
              className="form-horizontal"
            >
              <Row>
                <Card style={{ width: "100%" }}>
                  <CardBody>
                    <FormGroup>
                      <Label
                        htmlFor="title"
                        className="col-md-12 col-form-label"
                      >
                        Federasyon Adı
                      </Label>
                      <Col md={12}>
                        <AvField
                          name="title"
                          value={this.state.title}
                          validate={{ required: true }}
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder=""
                          onChange={(e) =>
                            this.setState({
                              title: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col md={12}>
                        <Label htmlFor="is_sectoral" className="col-form-label">
                          Sektörel Federasyon mu?
                        </Label>
                        <div className="d-flex">
                          <Switch
                            onColor="#38a4f8"
                            onChange={() =>
                              this.setState({
                                is_sectoral: !this.state.is_sectoral,
                              })
                            }
                            checked={this.state.is_sectoral}
                            className="mr-1 mt-1"
                          />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col>
                        <Label
                          htmlFor="city"
                          className="col-md-12 col-form-label"
                        >
                          Bölge
                        </Label>
                        <Select
                          id="region_error"
                          classNamePrefix="select"
                          placeholder={"Bölge seçebilirsiniz"}
                          noOptionsMessage={() =>
                            "arama kriterlerinize uygun sonuç bulunamadı"
                          }
                          loadingMessage={() => "arama yapılıyor..."}
                          isSearchable={true}
                          filterOption={(option, searchText) =>
                            customFilterForSelect(option, searchText, "title")
                          }
                          name="region_error"
                          options={this.state.regions}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.title}
                          onChange={(option) =>
                            this.setState({
                              region_id: option.id,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col>
                        <Label
                          htmlFor="city"
                          className="col-md-12 col-form-label"
                        >
                          Şehir
                        </Label>
                        <Select
                          id="city_error"
                          classNamePrefix="select"
                          hasError={this.state.city_error}
                          placeholder={"Aramak istediğiniz şehri giriniz"}
                          noOptionsMessage={() =>
                            "arama kriterlerinize uygun sonuç bulunamadı"
                          }
                          loadingMessage={() => "arama yapılıyor..."}
                          isSearchable={true}
                          isLoading={this.state.citySearching}
                          onInputChange={(e) => this.citySearch(e)}
                          filterOption={(option, searchText) =>
                            customFilterForSelect(option, searchText, "title")
                          }
                          name="city_error"
                          options={this.state.cities}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.title}
                          onChange={(option) =>
                            this.setState({
                              city_error: false,
                              city_id: option.id,
                              country_id: option.country_id,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    {this.state.city_id && (
                      <FormGroup>
                        <Col>
                          <Label
                            htmlFor="districts"
                            className="col-md-12 col-form-label"
                          >
                            İlçe
                          </Label>
                          <Select
                            id="districts_error"
                            classNamePrefix="select"
                            hasError={this.state.district_error}
                            placeholder={"Aramak istediğiniz ilçeyi giriniz"}
                            noOptionsMessage={() =>
                              "arama kriterlerinize uygun sonuç bulunamadı"
                            }
                            loadingMessage={() => "arama yapılıyor..."}
                            isSearchable={true}
                            isLoading={this.state.districtSearching}
                            onInputChange={(e) => this.districtSearch(e)}
                            filterOption={(option, searchText) =>
                              customFilterForSelect(option, searchText, "title")
                            }
                            name="district_error"
                            options={this.state.districts}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.title}
                            onChange={(option) =>
                              this.setState({
                                district_error: false,
                                district_id: option.id,
                              })
                            }
                          />
                        </Col>
                      </FormGroup>
                    )}
                    <Label
                      htmlFor="address"
                      className="col-md-12 col-form-label"
                    >
                      Adres
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="address"
                        value={this.state.address}
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            address: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label
                      htmlFor="postal_code"
                      className="col-md-12 col-form-label"
                    >
                      Posta Kodu
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="postal_code"
                        value={this.state.postal_code}
                        type="text"
                        className="form-control"
                        id="postal_code"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            postal_code: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <FormGroup>
                      <Label
                        htmlFor="example-date-input"
                        className="col-md-2 col-form-label"
                      >
                        Kuruluş Tarihi
                      </Label>
                      <Col md={12}>
                        <Input
                          className="form-control"
                          type="date"
                          id="example-date-input"
                          onChange={(e) => {
                            console.log(e.target.value);
                            this.setState({
                              foundation_date: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <Label htmlFor="email" className="col-md-12 col-form-label">
                      E-posta
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="email"
                        value={this.state.email}
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            email: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label htmlFor="phone" className="col-md-12 col-form-label">
                      Telefon
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="phone"
                        value={this.state.phone}
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            phone: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label
                      htmlFor="mobile_phone"
                      className="col-md-12 col-form-label"
                    >
                      Cep Telefonu
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="mobile_phone"
                        value={this.state.mobile_phone}
                        type="text"
                        className="form-control"
                        id="mobile_phone"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            mobile_phone: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label htmlFor="fax" className="col-md-12 col-form-label">
                      Fax
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="fax"
                        value={this.state.fax}
                        type="text"
                        className="form-control"
                        id="fax"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            fax: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label
                      htmlFor="website"
                      className="col-md-12 col-form-label"
                    >
                      Web Adresi
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="website"
                        value={this.state.website}
                        type="text"
                        className="form-control"
                        id="website"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            website: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label
                      htmlFor="president_name"
                      className="col-md-12 col-form-label"
                    >
                      Federasyon Yönetim Kurulu Başkanı
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="president_name"
                        value={this.state.president_name}
                        type="text"
                        className="form-control"
                        id="president_name"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            president_name: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label
                      htmlFor="secretary_name"
                      className="col-md-12 col-form-label"
                    >
                      Federasyon Genel Sekreter
                    </Label>
                    <Col md={12}>
                      <AvField
                        name="secretary_name"
                        value={this.state.secretary_name}
                        type="text"
                        className="form-control"
                        id="secretary_name"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({
                            secretary_name: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Label
                      htmlFor="secretary_name"
                      className="col-md-12 col-form-label"
                    >
                      Logo
                    </Label>
                    <Col md={12}>
                      <Dropzone
                        accept="image/*"
                        multiple={false}
                        onDrop={(acceptedFiles) =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dropzone"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="dz-message needsclick mt-2"
                              style={{ padding: "10px" }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>
                                Görseli yüklemek için tıklayın veya sürükleyin
                              </h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {this.state.selectedLogo && (
                        <CardImg
                          src={this.state.selectedLogo.preview}
                          className="img-fluid"
                        />
                      )}
                    </Col>
                    <Col md={6}>
                      <Button
                        color="primary"
                        className=" waves-effect waves-light mr-1 mt-2"
                        type="submit"
                      >
                        Ekle
                      </Button>{" "}
                    </Col>{" "}
                  </CardBody>
                </Card>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Federation);
