import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Button,
  Spinner,
  Input,
  Form,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import img5 from "../../assets/images/companies/img-5.png";

//api
import ListAssociationsPaginate from "../../api/associations/ListAssociationsPaginate";
import { AvForm, AvField } from "availity-reactstrap-validation";
import useSearch from "../../hooks/useSearch";
import SmallAvatar from "../../components/Common/SmallAvatar";
const Association = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Dernekler", link: "#" },
  ]);
  const [associations, setAssociations] = useState([]);
  const [currentAssocPage, setCurrentAssocage] = useState(1);
  const [lasAssocPage, setLasAssocPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [associationSearching, setAssociationSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  useSearch(searchText, () => citySearchWithDelay());

  const associationSearch = (searchQuery) => {
    if (!searchQuery) {
      setCurrentAssocage(0);
      setAssociations([]);
    }
    setAssociationSearching(true);
    setSearchText(searchQuery);
  };

  const citySearchWithDelay = () => {
    if (!searchText) {
      return;
    }
    ListAssociationsPaginate(1, {
      title: searchText,
    }).then((r) => {
      setAssociationSearching(false);
      if (r.status !== "success") {
        alert(r.message);
        return;
      }
      setAssociations(r.data.data);
      setCurrentAssocage(r.data.current_page);
      setLasAssocPage(r.data.last_page);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    ListAssociationsPaginate(currentAssocPage, { title: searchText }).then(
      (r) => {
        setAssociations([...associations, ...r.data.data]);
        setIsLoading(false);
        setAssociationSearching(false);
        if (r.status != "success") {
          alert(r.message);
        }
        setCurrentAssocage(r.data.current_page);
        setLasAssocPage(r.data.last_page);
      }
    );
  }, [currentAssocPage]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dernekler" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col md={5}>
              <Form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Arama"
                    value={searchText}
                    onChange={(e) => associationSearch(e.target.value)}
                    style={{ backgroundColor: "white" }}
                  />
                  {associationSearching && "Arama yapılıyor..."}
                  <span className="ri-search-line"></span>
                </div>
              </Form>
            </Col>
          </Row>
          <Row>
            {associations.map((association, key) => (
              <Col xl={3} sm={6} key={key}>
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <SmallAvatar cover={association.cover_link} />
                      <Media body>
                        <h5 className="text-truncate">
                          <Link
                            to={`/dernek/${association.id}`}
                            className="text-dark"
                          >
                            {association.title}
                          </Link>
                        </h5>
                      </Media>
                    </div>

                    <hr className="my-4" />

                    <Row className="text-center">
                      <Col xs={12}>
                        <p className="text-muted mb-2">Üyeler</p>
                        <h5>
                          {association.users_count > 0
                            ? association.users_count
                            : "-"}
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          {isLoading && (
            <Row className="justify-content-center mb-3">
              <Spinner className="font-size-20" color="primary" />
            </Row>
          )}
          {currentAssocPage != lasAssocPage && (
            <Row className="justify-content-center">
              <Button onClick={() => setCurrentAssocage(currentAssocPage + 1)}>
                daha fazla
              </Button>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Association;
