import styled from "styled-components";
const SpecialSection = styled.section`
  width: 100%;
  background-color: white;
  border-top: 1px solid #5a2d91;
  border-bottom: 1px solid #5a2d91;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SpecialSection;
