import React from "react";
import { Route, Redirect } from "react-router-dom";
import isSuperAdmin from "../utils/isSuperAdmin";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  onlySuperAdmin = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/giris", state: { from: props.location } }}
          />
        );
      }

      if (isAuthProtected && onlySuperAdmin && !isSuperAdmin()) {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }

      return (
        <Layout title={rest.title || ""}>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
