import api from "../../config/API";

const checkPasswordTokenRequest = async (data) => {
  return api
    .post(`forgotten/password/checkToken`, data)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default checkPasswordTokenRequest;
