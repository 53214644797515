import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/tr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import img5 from "../../assets/images/companies/img-5.png";

//components
import EditAssociationModal from "./EditAssociationModal";

//api
import ShowAssociation from "../../api/associations/ShowAssociation";
import CreateFeedModal from "./CreateFeedModal";
import hasAccess from "../../utils/hasAccess";
import AssociationPostList from "./AssociationPostList";
import EditAssociationAdmins from "./EditAssociationAdmins";
import DetailAvatar from "../../components/Common/DetailAvatar";

const Details = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Dernek", link: "#" },
  ]);
  const [association, setAssociation] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateFeedModalOpen, setIsCreateFeedModalOpen] = useState(false);
  const [isEditAdminsModalOpen, setIsEditAdminsModalOpen] = useState(false);
  const [hasAdminPermissions, sethasAdminPermissions] = useState(false);

  useEffect(() => {
    ShowAssociation(props.match.params.id).then((r) => {
      setAssociation(r.data);
      sethasAdminPermissions(hasAccess(r.data.admins));
    });
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dernek" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <DetailAvatar cover={association.cover_link} />
                    <Media body>
                      <h5 className="text-truncate">{association.title}</h5>
                      <Link
                        className="text-dark"
                        to={`/federasyon/${
                          association.federation && association.federation.id
                        }`}
                      >
                        <h6>
                          {association &&
                            association.federation &&
                            association.federation.title}
                        </h6>
                      </Link>
                    </Media>
                  </div>
                  {hasAdminPermissions && (
                    <>
                      <hr className="my-4" />
                      <Row className="text-center">
                        <Col lg={12}>
                          <Button
                            color="primary"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => setIsEditModalOpen(true)}
                          >
                            <i className="mdi mdi-pencil mr-2"></i>Düzenle
                          </Button>
                          <Button
                            color="primary"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => setIsEditAdminsModalOpen(true)}
                          >
                            <i className="mdi mdi-pencil mr-2"></i>
                            Dernek Yetkilileri
                          </Button>
                          <Button
                            color="warning"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => setIsCreateFeedModalOpen(true)}
                          >
                            <i className="mdi mdi-bullhorn mr-2"></i>
                            Duyuru Yap
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  <hr className="my-4" />
                  <Row className="text-center">
                    <Col lg={12}>
                      <Label htmlFor="billing-name">Kuruluş Tarihi</Label>
                      <p className="card-title-desc">
                        {moment(association.foundation_date).format(
                          "DD.MM.YYYY"
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col lg={6}>
                      <Label htmlFor="billing-name">
                        Dernek Yönetim Kurulu Başkanı
                      </Label>
                      <p className="card-title-desc">
                        {association.president_name}
                      </p>
                    </Col>
                    <Col lg={6}>
                      <Label htmlFor="billing-name">
                        Dernek Genel Sekreteri
                      </Label>
                      <p className="card-title-desc">
                        {association.secretary_name}
                      </p>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <Row className="text-center">
                    <Col lg={3}>
                      <Label htmlFor="billing-name">E-posta</Label>
                      <p className="card-title-desc">
                      <a href={"mailto:"+association.email}>{association.email}</a></p>
                    </Col>
                    <Col lg={3}>
                      <Label htmlFor="billing-name">Ülke</Label>
                      <p className="card-title-desc">
                        {association &&
                          association.country &&
                          association.country.title}
                      </p>
                    </Col>
                    <Col lg={3}>
                      <Label htmlFor="billing-name">Şehir</Label>
                      <p className="card-title-desc">
                        {association &&
                          association.city &&
                          association.city.title}
                      </p>
                    </Col>
                    <Col lg={3}>
                      <Label htmlFor="billing-name">İlçe</Label>
                      <p className="card-title-desc">
                        {association &&
                          association.district &&
                          association.district.title}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4 text-center">
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Posta Kodu</Label>
                      <p className="card-title-desc">
                        {association.postal_code}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Dernek Adres Bilgisi</Label>
                      <p className="card-title-desc"> {association.address}</p>
                    </Col>

                    <Col lg={4}>
                      <Label htmlFor="billing-name">Telefon</Label>
                      <p className="card-title-desc">{association.phone}</p>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Cep Telefonu</Label>
                      <p className="card-title-desc">
                        {association.mobile_phone}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Fax</Label>
                      <p className="card-title-desc">{association.fax}</p>
                    </Col>
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Web Adresi</Label>
                      <p className="card-title-desc">
                      <a href={"http://"+association.website}>{association.website}</a></p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Breadcrumbs title="Duyurular" breadcrumbItems={[]} />
            </Col>
            <Col lg={12}>
              <AssociationPostList association_id={association.id} />
            </Col>
          </Row>
        </Container>
      </div>
      <EditAssociationModal
        data={association}
        isOpen={isEditModalOpen}
        toggle={() => setIsEditModalOpen(!isEditModalOpen)}
      />
      <CreateFeedModal
        data={association}
        isOpen={isCreateFeedModalOpen}
        toggle={() => setIsCreateFeedModalOpen(!isCreateFeedModalOpen)}
      />
      <EditAssociationAdmins
        data={association}
        isOpen={isEditAdminsModalOpen}
        toggle={() => setIsEditAdminsModalOpen(!isEditAdminsModalOpen)}
      />
    </React.Fragment>
  );
};

export default Details;
