import React, { Component } from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Simple bar
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";
const VerticalMenu = styled.div`
  background: #fff !important;
  & #sidebar-menu > ul > li:hover > a {
    background: #fff !important;
    color: #5a2d91 !important;
  }
  &#sidebar-menu .mm-active {
    color: #5a2d91 !important;
  }
  & #sidebar-menu .mm-active .active {
    color: #5a2d91 !important;
  }
  & #sidebar-menu .mm-active .active i {
    color: #5a2d91 !important;
  }
  & #sidebar-menu .mm-active > i {
    color: #5a2d91 !important;
  }

  & #sidebar-menu ul li ul.sub-menu li a:hover {
    color: #5a2d91 !important;

    & > i {
      color: #5a2d91 !important;
    }
  }
  &#sidebar-menu ul li a:hover {
    color: #5a2d91 !important;
    & i {
      color: #5a2d91 !important;
    }
  }
  #sidebar-menu ul li a:hover i {
    color: #5a2d91 !important;
  }
`;
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <VerticalMenu className="vertical-menu">
          <div data-simplebar className="h-100">
            {this.props.type !== "condensed" ? (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <SidebarContent />
              </SimpleBar>
            ) : (
              <SidebarContent />
            )}
          </div>
        </VerticalMenu>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
