import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ViewFederation from "./ViewFederation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../../store/actions";

//api
import listFederationForAuthorization from "../../../api/federations/listFederationForAuthorization";

// Redux

class Authorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Yetkilendirme", link: "#" },
        { title: "Federasyon Yetkili Kullanıcıları", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      data: [],
      federations: [],
      viewed: {},
      isViewOpen: false,
    };

    listFederationForAuthorization().then((r) => {
      if (r.status === "success") {
        this.setState({
          federations: r.data.federations,
        });
      }
    });
  }

  handleViewOpen(row) {
    this.setState({
      viewed: row,
      isViewOpen: true,
    });
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="FEDERASYON YETKİLİ KULLANICILARI"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive mt-3">
                      <Table
                        className="table-centered datatable dt-responsive nowrap "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>Federasyon Adı</th>
                            <th>Yetkililer</th>

                            <th style={{ width: "120px" }}>İşlemler</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.federations.map((federation, key) => (
                            <tr key={key}>
                              <td>{federation.title}</td>
                              <td>
                                {federation.admins.length > 0
                                  ? federation.admins
                                      .map((a) => a.name)
                                      .join(", ")
                                  : "---"}
                              </td>

                              <td>
                                <Button
                                  id={"update-federation-button-" + key}
                                  color="warning"
                                  type="button"
                                  className="waves-effect waves-light mr-1"
                                  size="sm"
                                  onClick={() =>
                                    this.handleViewOpen(federation)
                                  }
                                >
                                  <i className="ri-eye-line align-middle"></i>
                                </Button>
                                <UncontrolledTooltip
                                  target={"update-federation-button-" + key}
                                  placement="top"
                                >
                                  Federasyon Yetkililerini Güncelle
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <ViewFederation
                      data={this.state.viewed}
                      isOpen={this.state.isViewOpen}
                      toggle={() =>
                        this.setState({
                          isViewOpen: !this.state.isViewOpen,
                        })
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Authorization);
