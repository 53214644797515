import api from "../../config/API";

const ListSectors = async (page) => {
  return api
    .get(`/sectors?page=${page}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListSectors;
