import api from "../../config/API";

const UpdateTitle = async ({ id, ...payload }) => {
  return api
    .patch(`/titles/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default UpdateTitle;
