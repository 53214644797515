import api from "../config/API";

const ListOptions = async (payload) => {
  return api
    .get(`/getOptionList`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListOptions;
