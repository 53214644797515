import React from "react";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()} ©{" "}
              <a href="https://turkonfed.org/" target="_blank">
                TÜRKONFED
              </a>
            </Col>
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block">
                Crafted by{" "}
                <a href="https://plancode.net/" target="_blank">
                  Plancode
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
