import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Button,
  Spinner,
  Input,
  Form,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import img4 from "../../assets/images/companies/img-4.png";
import ListFederations from "../../api/federations/ListFederations";
import ListFederationsPaginate from "../../api/federations/ListFederationsPaginate";
import { AvForm, AvField } from "availity-reactstrap-validation";
import useSearch from "../../hooks/useSearch";
import SmallAvatar from "../../components/Common/SmallAvatar";

const Federation = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Federasyonlar", link: "#" },
  ]);
  const [federations, setFederations] = useState([]);
  const [currentFedPage, setCurrentFedPage] = useState(1);
  const [lasFedPage, setLasFedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [federationSearching, setFederationSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  useSearch(searchText, () => citySearchWithDelay());

  const federationSearch = (searchQuery) => {
    if (!searchQuery) {
      setCurrentFedPage(0);
      setFederations([]);
    }
    setFederationSearching(true);
    setSearchText(searchQuery);
  };

  const citySearchWithDelay = () => {
    if (!searchText) return;
    ListFederationsPaginate(1, {
      title: searchText,
    }).then((r) => {
      setFederationSearching(false);
      if (r.status !== "success") {
        alert(r.message);
        return;
      }
      setFederations(r.data.data);
      setCurrentFedPage(r.data.current_page);
      setLasFedPage(r.data.last_page);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    ListFederationsPaginate(currentFedPage, { title: searchText }).then((r) => {
      setFederations([...federations, ...r.data.data]);
      setIsLoading(false);
      setFederationSearching(false);
      if (r.status != "success") {
        alert(r.message);
      }
      setCurrentFedPage(r.data.current_page);
      setLasFedPage(r.data.last_page);
    });
  }, [currentFedPage]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Federasyonlar"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col>
              <Col md={5}>
                <Form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Arama"
                      value={searchText}
                      onChange={(e) => federationSearch(e.target.value)}
                      style={{ backgroundColor: "white" }}
                    />
                    {federationSearching && "Arama yapılıyor..."}
                    <span className="ri-search-line"></span>
                  </div>
                </Form>
              </Col>
            </Col>
          </Row>
          <Row>
            {federations.map((federation, key) => (
              <Col xl={3} sm={6} key={key}>
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <SmallAvatar cover={federation.cover_link} />
                      <Media body>
                        <h5 className="text-truncate">
                          <Link
                            to={`/federasyon/${federation.id}`}
                            className="text-dark"
                          >
                            {federation.title}
                          </Link>
                        </h5>
                      </Media>
                    </div>

                    <hr className="my-4" />

                    <Row className="text-center">
                      <Col>
                        <p className="text-muted mb-2">Dernekler</p>
                        <h5>
                          {federation.associations.length > 0
                            ? federation.associations.length
                            : "-"}
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          {isLoading && (
            <Row className="justify-content-center mb-3">
              <Spinner className="font-size-20" color="primary" />
            </Row>
          )}
          {currentFedPage != lasFedPage && (
            <Row className="justify-content-center">
              <Button onClick={() => setCurrentFedPage(currentFedPage + 1)}>
                daha fazla
              </Button>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Federation;
