import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Alert,
  Collapse,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

//api
import createAdminUser from "../../api/adminUser/CreateAdminUser";
import ListFederations from "../../api/federations/ListFederations";
import DeleteAdminUser from "../../api/adminUser/DeleteAdminUser";

// Redux
import { connect } from "react-redux";

class PageAuthorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Yetkilendirme", link: "#" },
        { title: "Federasyon/Dernek Yetkili Kullanıcılar", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      federations: [],
      associationsCollapse: true,
      data: [],
    };

    ListFederations().then((r) => {
      if (r.status === "success") {
        console.log(r);
        this.setState({
          federations: [...r.data],
        });
      }
    });
  }

  componentDidMount() {}

  render() {
    const columns = [
      {
        name: "İşlemler",
        cell: (row) => (
          <>
            <Button
              color="info"
              type="button"
              className="waves-effect waves-light mr-1"
              size="sm"
            >
              <i className="ri-eye-line align-middle"></i>
            </Button>
          </>
        ),
        grow: 0,
      },
      { name: "Title", selector: "associations.title", sortable: true },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="FEDERASYON/DERNEK YETKİLİ KULLANICILARI"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={12} lg={12}>
                <Card>
                  <div className="custom-accordion">
                    {this.state.federations &&
                      this.state.federations.map((item, i) => (
                        <CardBody className="border-top" key={i}>
                          <div>
                            <h5 className="font-size-14 mb-0">
                              <Link
                                to="#"
                                className="text-dark d-block"
                                onClick={() =>
                                  this.setState({
                                    discount: !this.state.discount,
                                  })
                                }
                              >
                                {item.title}
                                <i
                                  className={
                                    this.state.discount === true
                                      ? "mdi mdi-minus float-right accor-minus-icon"
                                      : "mdi mdi-plus float-right accor-plus-icon"
                                  }
                                ></i>
                              </Link>
                            </h5>

                            <Collapse
                              isOpen={this.state.discount}
                              id="collapseExample1"
                            >
                              <div className="mt-4">
                                <Row>
                                  {item.associations.map((association) => (
                                    <div>{association.title},</div>
                                  ))}
                                </Row>
                              </div>
                            </Collapse>
                          </div>
                        </CardBody>
                      ))}
                  </div>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
              >
                Yetkili Kullanıcısı Ekle
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.addCustomer}>
                  <Row>
                    <Col lg={12}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Başarıyla Eklendi...!
                      </Alert>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <Label htmlFor="name">İsim Soyisim</Label>
                        <AvField
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder=""
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      İptal
                    </Button>
                    <Button type="submit" color="primary">
                      Ekle
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(PageAuthorization);
