/*
https://stackoverflow.com/a/63967277/6593023
*/
const turkishCharacterRegex = (keyword) => {
  if (!keyword) return keyword;
  return keyword
    .replace(/[ıİiI]/g, "[ıİiI]")
    .replace(/[şŞsS]/g, "[şŞsS]")
    .replace(/[çÇcC]/g, "[çÇcC]")
    .replace(/[ğĞgG]/g, "[ğĞgG]")
    .replace(/[öÖoO]/g, "[öÖoO]")
    .replace(/[üÜuU]/g, "[üÜuU]");
};

const customFilterForSelect = (option, searchText, field) => {
  if (!searchText) return true;
  return turkishCharacterRegex(option.data[field])
    .toLowerCase()
    .includes(turkishCharacterRegex(searchText).toLowerCase());
};

export default customFilterForSelect;
