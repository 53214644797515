import api from "../../config/API";

const DeleteDistrict = async (id = 0) => {
  return api
    .delete(`/districts/${id}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default DeleteDistrict;
