import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  CardImg,
  Col,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserPostList from "../Posts/UserPostList";

import isSuperAdmin from "../../utils/isSuperAdmin";
import showUserRequest from "../../api/user/showUserRequest";
import DeleteUser from "../../api/adminUser/DeleteUser";


const UserProfile = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Profil", link: "#" },
    { title: "Görüntüleme", link: "#" },
  ]);
  const [user, setUser] = useState(null);
  const [canShow, setCanShow] = useState(isSuperAdmin());


  const handleDelete = (id) => {
    const answer = window.confirm(
      "Seçilen kullanıcı GERİ GETİRİLEMEZ şekilde sistemden silinecek, Onaylıyor musunuz?"
    );

    if (!answer) {
      return;
    }

    if (id) {
      DeleteUser({ id: id }).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          window.history.go(-1);
        }
      });
    }
  };

  useEffect(() => {
    showUserRequest({ id: props.match.params.id }).then((r) => {
      if (r.status != "success") {
        alert(r.message);
      }
      setUser(r.data);

      if (r.data.name) {
        document.title = r.data.name + " - " + document.title;
      }
    });
  }, [props.match.params.id]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profil" breadcrumbItems={breadcrumbItems} />
          {user && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div
                      id="checkout-nav-pills-wizard"
                      className="twitter-bs-wizard"
                    >
                    
                          {canShow && ( 
                              <Row className="text-center mb-5">
                              <Col lg={12}>
                          <Button 
                         
                            color="danger"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => handleDelete(user.id)}
                          >
                            <i className="ri-delete-bin-2-fill align-middle"></i>
                            Kullanıcıyı Kalıcı Olarak Sil
                          </Button></Col></Row>
                        )}
                       

                          <Row className="mt-5">
                            <Col lg={4}>
                            <h4 style={{ textAlign: "center" }}>{user.name}</h4>
                            {user.avatar_link && (
                              <div  className="text-center">
                                      <CardImg
                                        src={user.avatar_link}
                                        className="rounded-circle img-fluid mb-2"
                                        style={{ width: 200, height: 200,  }}
                                      />
                                      </div>
                                    )}
                            </Col>
                            <Col lg={4}>
                              <Label htmlFor="billing-name">E-posta</Label>
                              <p className="card-title-desc">
                              <a href={"mailto:"+user.email}>{user.email}</a> </p>
                              {canShow && (
                             <>
                                <Label htmlFor="billing-name">
                                  Cep Telefonu
                                </Label>
                                <p className="card-title-desc">{user.phone}</p>
                              </>
                            )}
                            {user.fax && (<>
                             <Label htmlFor="billing-name">Fax</Label>
                              <p className="card-title-desc">{user.fax}</p>
                              </>)}
                              {user.assistant_name && (<>
                              <Label htmlFor="billing-name">
                                Asistan İsim Soyisim
                              </Label>
                              <p className="card-title-desc">
                                {user.assistant_name}
                              </p></>)}
                              {user.assistant_email && (<>
                              <Label htmlFor="billing-name">
                                Asistan E-posta
                              </Label>
                              <p className="card-title-desc">
                                <a href={"mailto:"+user.assistant_email}>{user.assistant_email}</a>
                              </p>
                              </>)}
                            </Col>
                            <Col lg={4}>
                              <Label htmlFor="billing-name">Unvan</Label>
                              <p className="card-title-desc">{user.title}</p>
                              <Label htmlFor="billing-name"> İş Telefonu</Label>
                              <p className="card-title-desc">
                                {user.work_phone}
                              </p>
                              <Label htmlFor="billing-name">
                                Dahili Numarası
                              </Label>
                              <p className="card-title-desc">
                                {user.internal_phone}
                              </p>
                              
                            </Col>
                           
                          </Row>
                          {user.note && (<>
                          <hr />
                          <Row className="mb-5 text-center">
                           
                            <Col lg={12}>
                              <h5>Kullanıcı Profil Notu</h5>
                              <p>{user.note}</p>
                                     </Col>
                                     </Row>  </>)}                     
                          <hr/>
                       <div className="text-center mb-5">
                       <h5>Firma Bilgileri</h5>
                       </div>
                    
                          <Row>
                            <Col lg={4}>
                              <Label>Firma İsmi</Label>
                              <p className="card-title-desc">
                                {user.company_name}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label>Sektör</Label>
                              <p className="card-title-desc">
                                {user.sector && user.sector.title}
                              </p>
                            </Col>
                       
                            <Col lg={4}>
                              <Label>Alt Sektör</Label>
                              <p className="card-title-desc">
                                {user.sub_sector}
                              </p>
                            </Col>
                            </Row>
                           
                          
                          <Row>
                          {user.employee_count && (        
                            <Col lg={4}>
                              <Label>Çalışan Sayısı</Label>
                              <p className="card-title-desc">
                                {user.employee_count}
                              </p>
                            </Col>
                                  )}
                            <Col lg={4}>
                              <Label>Ülke</Label>
                              <p className="card-title-desc">
                                {user.country && user.country.title}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label> Şehir</Label>
                              <p className="card-title-desc">
                                {user.city && user.city.title}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <Label>Adres</Label>
                              <p className="card-title-desc">{user.address}</p>
                            </Col>
                            <Col lg={4}>
                              <Label>Posta Kodu</Label>
                              <p className="card-title-desc">
                                {user.postal_code}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label>Website</Label>
                              <p className="card-title-desc"><a target="_blank" href={"http://"+user.website}>{user.website}</a></p>
                            </Col>
                          </Row>
                        
                          <hr/>
                       <div className="text-center mb-5">
                       <h5>Federasyon/Dernek Bilgileri</h5>
                       </div>
                          <Row className="mt-5">
                            <Col lg={4}>
                              <Label> Federasyon</Label>
                              <p className="card-title-desc">
                                {user.associations.length > 0 &&
                                  user.associations.filter(
                                    (a) => a.is_main == 1
                                  )[0].federation.title}
                              </p>
                            </Col>

                            {user.associations.length > 0 &&
                              user.associations
                                .filter((a) => a.is_main == 1)
                                .map((a) =>
                                  a.federation_title ? (
                                    <Col lg={4}>
                                      <Label>Federasyon Görevi</Label>
                                      <p className="card-title-desc">
                                        {a.federation_title.label}
                                      </p>
                                    </Col>
                                  ) : (
                                    ""
                                  )
                                )}
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <Label>Dernek</Label>
                              <p className="card-title-desc">
                                {user.associations.length > 0 &&
                                  user.associations.filter(
                                    (a) => a.is_main == 1
                                  )[0].title}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label>Dernekteki Görevi</Label>
                              <p className="card-title-desc">
                                {user.associations.length > 0 &&
                                  user.associations
                                    .filter((a) => a.is_main == 1)
                                    .map((a) =>
                                      a.association_title
                                        ? a.association_title.label
                                        : ""
                                    )}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label>Bulunduğu Diğer Dernekler</Label>
                              <p className="card-title-desc">
                                <ul>
                                  {user.associations &&
                                    user.associations
                                      .filter(
                                        (assoc) => assoc.pivot.is_main != 1
                                      )
                                      .map((assoc, i) => (
                                        <li key={i}>{assoc.title}</li>
                                      ))}
                                </ul>
                              </p>
                            </Col>
                          </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            <Col lg={12}>
              <UserPostList user_id={user.id} />
            </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
