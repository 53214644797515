import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-size: 12px;
  margin-top: 20px;
`;

const SpecText = () => (
  <Wrapper>
    Bu web sitesi, Avrupa Birliği’nin maddi desteği ile oluşturulmuştur ve
    sürdürülmektedir. İçerik tamamıyla TÜRKONFED sorumluluğu altındadır ve
    Avrupa Birliği’nin görüşlerini yansıtmak zorunda değildir.
  </Wrapper>
);

export default SpecText;
