import React, { Component } from "react";
import styled from "styled-components";
import history from "../../history";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";
import { useHistory } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Images
import BiaLogo from "../../assets/images/bia-logo.png";

const CustomButton = styled(Button)`
  background: #5a2d91;
  border-color: #5a2d91;
  border-radius: 0;
  &:hover,
  :active {
    background: #5a2d91 !important;
    border-color: #5a2d91 !important;
  }
`;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      title: null,
      email: null,
      work_phone: null,
      internal_phone: null,
      phone: null,
      password: null,
      fax: null,
      password_confirmation: null,
      assistant_name: null,
      assistant_email: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    if (values) {
      this.props.history.push("/kayit-formu-2", values);
    }
  }

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0" style={{ minHeight: "100vh" }}>
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100 h-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={BiaLogo} alt="bia" width="200" />
                                {/*  <img src={logodark} height="20" alt="logo" /> */}
                              </Link>
                            </div>
                            <p className="text-muted">1/3</p>
                            <h4 className="font-size-18 mt-4">
                              Kullanıcı Kayıt Formu
                            </h4>
                            <p className="text-muted">Kişisel Bilgiler</p>
                          </div>

                          {this.props.user && (
                            <Alert color="success">
                              Registration Done Successfully.
                            </Alert>
                          )}

                          {this.props.registrationError && (
                            <Alert color="danger">
                              {this.props.registrationError}
                            </Alert>
                          )}

                          <div className="p-2 mt-5">
                            <AvForm
                              onValidSubmit={this.handleSubmit}
                              className="form-horizontal"
                            >
                              <Row>
                                <Col lg={6}>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="name">İsim Soyisim *</Label>
                                    <AvField
                                      name="name"
                                      value={this.state.name}
                                      validate={{ required: true }}
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      placeholder=""
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="title">Unvan *</Label>
                                    <AvField
                                      name="title"
                                      value={this.state.title}
                                      validate={{ required: true }}
                                      type="text"
                                      className="form-control"
                                      id="title"
                                      placeholder=""
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-mail-line auti-custom-input-icon"></i>
                                    <Label htmlFor="email">E-posta *</Label>
                                    <AvField
                                      name="email"
                                      value={this.state.email}
                                      validate={{ email: true, required: true }}
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      placeholder="example@example.com"
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className=" ri-phone-line auti-custom-input-icon"></i>
                                    <Label htmlFor="work_phone">
                                      İş Telefonu *
                                    </Label>
                                    <AvField
                                      name="work_phone"
                                      value={this.state.work_phone}
                                      validate={{
                                        required: { value: true },
                                      }}
                                      type="text"
                                      className="form-control"
                                      id="work_phone"
                                      placeholder=""
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="password">Şifre *</Label>
                                    <AvField
                                      name="password"
                                      value={this.state.password}
                                      validate={{ required: { value: true } }}
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder=""
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="password_confirmation">
                                      Tekrar Şifre *
                                    </Label>
                                    <AvField
                                      name="password_confirmation"
                                      value={this.state.password_confirmation}
                                      validate={{
                                        required: { value: true },
                                        match: { value: "password" },
                                      }}
                                      type="password"
                                      className="form-control"
                                      id="password_confirmation"
                                      placeholder=""
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  {" "}
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className=" ri-smartphone-line auti-custom-input-icon"></i>
                                    <Label htmlFor="phone">
                                      Cep Telefonu *
                                    </Label>
                                    <AvField
                                      name="phone"
                                      value={this.state.phone}
                                      validate={{ required: true }}
                                      type="text"
                                      className="form-control"
                                      id="phone"
                                      placeholder=""
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-phone-line auti-custom-input-icon"></i>
                                    <Label htmlFor="fax">Fax</Label>
                                    <AvField
                                      name="fax"
                                      value={this.state.fax}
                                      type="text"
                                      className="form-control"
                                      id="fax"
                                      placeholder=""
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-phone-line auti-custom-input-icon"></i>
                                    <Label htmlFor="internal_phone">
                                      Dahili Numarası 
                                    </Label>
                                    <AvField
                                      name="internal_phone"
                                      value={this.state.internal_phone}
                                      type="text"
                                      className="form-control"
                                      id="internal_phone"
                                      placeholder=""
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="assistant_name">
                                      Asistan İsim Soyisim
                                    </Label>
                                    <AvField
                                      name="assistant_name"
                                      value={this.state.assistant_name}
                                      type="text"
                                      className="form-control"
                                      id="assistant_name"
                                      placeholder=""
                                    />
                                  </FormGroup>
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-mail-line auti-custom-input-icon"></i>
                                    <Label htmlFor="assistant_email">
                                      Asistan E-posta
                                    </Label>
                                    <AvField
                                      name="assistant_email"
                                      value={this.state.assistant_email}
                                      validate={{ email: true }}
                                      type="email"
                                      className="form-control"
                                      id="assistant_email"
                                      placeholder="example@example.com"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <div className="text-center">
                                {/*    <Link
                                  to="/kayit-formu-1"
                                  className="font-weight-medium text-primary"
                                >
                                  {" "}
                                  İleri
                                </Link> */}{" "}
                                <CustomButton
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  {this.props.loading
                                    ? "Yükleniyor ..."
                                    : "İleri"}
                                </CustomButton>
                              </div>

                              {/* <div className="mt-4 text-center">
                                <p className="mb-0">
                                  By registering you agree to the Nazox{" "}
                                  <Link to="#" className="text-primary">
                                    Terms of Use
                                  </Link>
                                </p>
                              </div> */}
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              Zaten bir hesabınız var mı?{" "}
                              <Link
                                to="/giris"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Giriş Yap
                              </Link>{" "}
                            </p>
                            {/*   <p>
                              © 2020 Nazox. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger"></i> by
                              Themesdesign
                            </p> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/*  <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
