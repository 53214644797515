import React, { useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Label,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import NewsList from "../../components/Common/NewsList";
import CreateNewsModal from "./CreateNewsModal";
const NewsPage = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Anasayfa Haberleri", link: "#" },
  ]);

  const [isCreateNewsModalOpen, setIsCreateNewsModalOpen] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Haberler" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col>
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsCreateNewsModalOpen(true);
                  }}
                  className="mb-2"
                >
                  <i className="mdi mdi-plus mr-2"></i>Haber Ekle
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <NewsList editable={true} />
            </Col>{" "}
          </Row>
          <br />
        </Container>
      </div>
      <CreateNewsModal
        isOpen={isCreateNewsModalOpen}
        toggle={() => setIsCreateNewsModalOpen(!isCreateNewsModalOpen)}
      />
    </React.Fragment>
  );
};

export default NewsPage;
