import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { RESET_USER } from "./actionTypes";
import { resetUserSuccessful, userResetPasswordError } from "./actions";
import resetPassword from "../../../api/user/resetPasswordRequest";

//If user is login then dispatch redux action's are directly from here.
function* resetUser({ payload: { user, history } }) {
  try {
    const response = yield call(resetPassword, user);

    if (response.status === "success") {
      yield put(resetUserSuccessful(response.message));
    } else {
      yield put(userResetPasswordError(response.message));
    }
  } catch (error) {
    yield put(userResetPasswordError(error));
  }
}

export function* watchUserReset() {
  yield takeEvery(RESET_USER, resetUser);
}

function* resetSaga() {
  yield all([fork(watchUserReset)]);
}

export default resetSaga;
