import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

//api
import UpdateAssociationAdmins from "../../../api/associations/UpdateAssociationAdmins";
import useSearch from "../../../hooks/useSearch";
import SearchUsers from "../../../api/search/SearchUsers";

import uniqueMergeArrays from "../../../utils/uniqueMergeArrays";
import customFilterForSelect from "../../../utils/customFilterForSelect";

function ViewAssociation({ data, isOpen, toggle, ...props }) {
  const [title, setTitle] = useState("");
  const [admins, setAdmins] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [userSearching, setUserSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  useSearch(searchText, () => userSearchWithDelay());

  const userSearch = (searchQuery) => {
    if (!searchQuery) return;
    setUserSearching(true);
    setSearchText(searchQuery);
  };
  const userSearchWithDelay = () => {
    SearchUsers({
      name: searchText,
    }).then((r) => {
      setUserSearching(false);

      if (r.status !== "success") return;
      setUserOptions(uniqueMergeArrays(userOptions, r.data));
    });
  };

  const handleSubmit = (event, values) => {
    const user_ids = admins.map((admin) => admin.id);
    UpdateAssociationAdmins({
      association_id: data.id,
      user_ids: user_ids,
    }).then((r) => {
      alert(r.message);
      if (r.status == "success") {
        window.location.reload();
      }
    });
  };

  const handleSelection = (selectedAdmins) => {
    if (!selectedAdmins) return;

    setAdmins(selectedAdmins);
  };
  useEffect(() => {
    if (data && data.title) {
      setTitle(data.title);
    }
    if (data && data.admins) {
      setAdmins(data.admins);
      setUserOptions(data.admins);
    }
  }, [data, isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Dernek Yetkililerini Güncelle</ModalHeader>
      <ModalBody>
        <Col md="12">
          <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
            <FormGroup>
              <Col md={12}>
                <Label>Dernek</Label>
                <AvField
                  name="title"
                  value={title}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value.trim())}
                  disabled
                />
                <FormGroup className="select2-container">
                  <Label className="control-label">Yetkililer</Label>
                  <Select
                    value={admins}
                    isClearable={false}
                    isMulti={true}
                    onChange={handleSelection}
                    placeholder={"Aramak istediğiniz ismi giriniz"}
                    noOptionsMessage={() =>
                      "arama kriterlerinize uygun sonuç bulunamadı"
                    }
                    loadingMessage={() => "arama yapılıyor..."}
                    isSearchable={true}
                    isLoading={userSearching}
                    onInputChange={(e) => userSearch(e)}
                    filterOption={(option, searchText) =>
                      customFilterForSelect(option, searchText, "name")
                    }
                    options={userOptions}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                <Col md={6}>
                  <Button
                    color="primary"
                    className=" waves-effect waves-light mr-1 mt-3"
                    type="submit"
                  >
                    Güncelle
                  </Button>{" "}
                </Col>{" "}
              </Col>{" "}
            </FormGroup>
          </AvForm>
        </Col>
      </ModalBody>
    </Modal>
  );
}
export default ViewAssociation;
