import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Button,
  Spinner,
  Input,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import img5 from "../../assets/images/companies/img-5.png";

//api
import ListEducationPaginate from "../../api/education/ListEducationPaginate";

import { AvForm, AvField } from "availity-reactstrap-validation";
import useSearch from "../../hooks/useSearch";
import EducationPost from "../../components/Common/EducationPost";
import isSuperAdmin from "../../utils/isSuperAdmin";
import CreateEducationModal from "./CreateEducationModal";
const Education = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Eğitimler", link: "#" },
  ]);
  const [education, setEducation] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [isCreateEducationModalOpen, setIsCreateEducationModalOpen] =
    useState(false);

  const [educationSearching, setEducationSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  useSearch(searchText, () => educationSearchWithDelay());

  const educationSearch = (searchQuery) => {
    if (!searchQuery) {
      loadEducation(1, searchQuery);
    }
    setEducationSearching(true);
    setSearchText(searchQuery);
  };

  const educationSearchWithDelay = () => {
    if (!searchText) {
      return;
    }

    loadEducation(1, searchText);
  };

  useEffect(() => {
    loadEducation(currentPage, searchText);
  }, []);

  const loadEducation = (page = 1, title = "") => {
    setIsLoading(true);
    ListEducationPaginate(page, { title: title }).then((r) => {
      setIsLoading(false);
      setEducationSearching(false);
      if (r.status != "success") {
        alert(r.message);
      }
      setEducation(r.data.data);
      setCurrentPage(page);
      setPagination(
        r.data.links.map((link) => {
          link.page_number = null;
          if (link.label == "&laquo; Previous") {
            link.label = "Önceki";
          }

          if (link.label == "Next &raquo;") {
            link.label = "Sonraki";
          }

          if (!link.url) {
            return link;
          }

          const page_number = link.url.split("=")[1];
          link.page_number = page_number;
          return link;
        })
      );
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Eğitimler" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col md={5}>
              <Form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Arama"
                    value={searchText}
                    onChange={(e) => educationSearch(e.target.value)}
                    style={{ backgroundColor: "white" }}
                  />
                  {educationSearching && "Arama yapılıyor..."}
                  <span className="ri-search-line"></span>
                </div>
              </Form>
            </Col>
          </Row>
          {isSuperAdmin() && (
            <Row>
              <Col>
                <div>
                  <Button
                    color="primary"
                    onClick={() => setIsCreateEducationModalOpen(true)}
                    className="mb-2"
                  >
                    <i className="mdi mdi-plus mr-2"></i>Eğitim Ekle
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          {!isLoading &&
            education &&
            education.length > 0 &&
            education.map((item, index) => (
              <EducationPost key={index} {...item} />
            ))}
          {isLoading && (
            <Row className="justify-content-center mb-3">
              <Spinner className="font-size-20" color="primary" />
            </Row>
          )}
          {!isLoading && pagination && pagination.length > 3 && (
            <Row>
              <Pagination aria-label="Page navigation example">
                {pagination.map((page) => (
                  <PaginationItem
                    onClick={() => {
                      if (!page.page_number) return;
                      if (page.page_number == currentPage) return;
                      loadEducation(page.page_number, searchText);
                    }}
                    disabled={!page.page_number}
                    className={page.page_number == currentPage && `active`}
                  >
                    <PaginationLink>{page.label}</PaginationLink>
                  </PaginationItem>
                ))}
              </Pagination>
            </Row>
          )}
        </Container>
      </div>
      <CreateEducationModal
        isOpen={isCreateEducationModalOpen}
        toggle={() =>
          setIsCreateEducationModalOpen(!isCreateEducationModalOpen)
        }
      />
    </React.Fragment>
  );
};

export default Education;
