import api from "../../config/API";

const UpdateAssociation = async (payload, id) => {
  return api
    .patch(`/associations/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default UpdateAssociation;
