import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardImg,
  Button,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";

//Import Components

//Import Image
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/companies/img-4.png";

import { Link } from "react-router-dom";
import ListPosts from "../../api/posts/ListPosts";
import FeedPost from "../../components/Common/FeedPost";
import GetDashboardStats from "../../api/GetDashboardStats";
import isSuperAdmin from "../../utils/isSuperAdmin";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Bölgesel İş Ağları", link: "#" }],
      posts: [],
      federationCount: 0,
      associationCount: 0,
      userCount: 0,
      PendingUserCount:  0,
      PendingPostCount: 0,
      CorporatesPostsCount: 0,
      UsersPostsCount: 0,
      currentPostPage: 1,
      lastPostPage: 1,
      isLoading: false,
    };

    this.loadPost(this.state.currentPostPage);

    GetDashboardStats().then((r) => {
      if (r.status != "success") {
        alert(r.message);
      }
      this.setState({
        federationCount: r.data.federation_count,
        associationCount: r.data.association_count,
        userCount: r.data.user_count,
        PendingUserCount:  r.data.pending_user_count,
        PendingPostCount: r.data.pending_post_count,
        CorporatesPostsCount: r.data.corporates_posts_count,
        UsersPostsCount: r.data.users_posts_count,
      });
    });
  }

  loadPost(nextPage) {
    this.setState({ isLoading: true });
    ListPosts(nextPage).then((r) => {
      this.setState({ isLoading: false });
      if (r.status != "success") {
        alert(r.message);
      }

      this.setState({
        posts: [...this.state.posts, ...r.data.data],
        currentPostPage: r.data.current_page,
        lastPostPage: r.data.last_page,
      });
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Ana Sayfa"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {isSuperAdmin() && (
              <>
              <Row>
           
                <Col md={4}>
             <Link to="/onay-bekleyen-kullanicilar">
                  <Card>
                    <CardBody>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Onay Bekleyen Kullanıcılar
                          </p>
                          <h4 className="mb-0">{this.state.PendingUserCount}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-add-line font-size-24"></i>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  </Link>
                </Col>

                <Col md={4}>
                <Link to="/onay-bekleyen-bireysel-duyurular">
                  <Card>
                    <CardBody>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Onay Bekleyen Bireysel Duyurular
                          </p>
                          <h4 className="mb-0">
                            {this.state.PendingPostCount}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-chat-new-line font-size-24"></i>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  </Link>
                </Col>
                <Col md={2}>
                 <Card>
                   <CardBody>
                     <div
                       className="d-flex"
                       style={{ justifyContent: "space-between" }}
                     >
                       <div className="flex-1 overflow-hidden">
                         <p className="text-truncate font-size-14 mb-2" alt="Toplam Federasyon Sayısı">
                           Federasyonlar
                         </p>
                         <h4 className="mb-0">
                           {this.state.federationCount}
                         </h4>
                       </div>
                       <div className="text-primary">
                         <i className="ri-stack-line font-size-24"></i>
                       </div>
                     </div>
                   </CardBody>
                 </Card>
               </Col>
               <Col md={2}>
                 <Card>
                   <CardBody>
                     <div
                       className="d-flex"
                       style={{ justifyContent: "space-between" }}
                     >
                       <div className="flex-1 overflow-hidden">
                         <p className="text-truncate font-size-14 mb-2">
                           Dernekler
                         </p>
                         <h4 className="mb-0">
                           {this.state.associationCount}
                         </h4>
                       </div>
                       <div className="text-primary">
                         <i className="ri-stack-line font-size-24"></i>
                       </div>
                     </div>
                   </CardBody>
                 </Card>
               </Col>
               <Col md={4}>
                 <Card>
                   <CardBody>
                     <div
                       className="d-flex"
                       style={{ justifyContent: "space-between" }}
                     >
                       <div className="flex-1 overflow-hidden">
                         <p className="text-truncate font-size-14 mb-2">
                           Kurumsal Duyurular
                         </p>
                         <h4 className="mb-0">{this.state.CorporatesPostsCount}</h4>
                       </div>
                       <div className="text-primary">
                         <i className="ri-chat-quote-line font-size-24"></i>
                       </div>
                     </div>
                   </CardBody>
                 </Card>
               </Col>
                <Col md={4}>
                  <Card>
                    <CardBody>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Bireysel Duyurular
                          </p>
                          <h4 className="mb-0">{this.state.UsersPostsCount}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-chat-4-line font-size-24"></i>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
               <Col md={4}>
                 <Card>
                   <CardBody>
                     <div
                       className="d-flex"
                       style={{ justifyContent: "space-between" }}
                     >
                       <div className="flex-1 overflow-hidden">
                         <p className="text-truncate font-size-14 mb-2">
                           Toplam Aktif Kullanıcı
                         </p>
                         <h4 className="mb-0">{this.state.userCount}</h4>
                       </div>
                       <div className="text-primary">
                         <i className="ri-user-line font-size-24"></i>
                       </div>
                     </div>
                   </CardBody>
                 </Card>
               </Col>
             </Row>
             </>
              
            )}
            {this.state.posts &&
              this.state.posts.map((post, index) => (
                <FeedPost key={index} {...post} />
              ))}
            {this.state.isLoading && (
              <Row className="justify-content-center mb-3">
                <Spinner className="font-size-20" color="primary" />
              </Row>
            )}
            {this.state.currentPostPage != this.state.lastPostPage && (
              <Row className="justify-content-center mb-4">
                <Button
                  onClick={() => this.loadPost(this.state.currentPostPage + 1)}
                >
                  daha fazla
                </Button>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
