import { create } from "apisauce";
import SERVER_URL from "./SERVER_URL";

const api = create({
  baseURL: `${SERVER_URL}/api`,
  headers: {
    Accept: `application/json`,
  },
});

api.axiosInstance.interceptors.request.use((config) => {
  const method = config.method.toUpperCase();
  if (method === "PUT" || method === "DELETE" || method === "PATCH") {
    config.headers = {
      ...config.headers,
      "X-HTTP-Method-Override": method,
    };
    config.method = "post";
    config.params = {
      ...config.params,
      _method: method,
    };
  }
  return config;
});

export const setToken = (token) =>
  api.setHeader("Authorization", `Bearer ${token}`);

export default api;
