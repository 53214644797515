import styled from "styled-components";
const SpecialSection2 = styled.section`
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
`;

export default SpecialSection2;
