import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import isSuperAdmin from "../../utils/isSuperAdmin";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canShow: isSuperAdmin(),
    };
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/platform" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">Ana Sayfa</span>
              </Link>
            </li>
            <li>
              <Link to="/turkonfed" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">TÜRKONFED</span>
              </Link>
            </li>
            <li>
              <Link to="/federasyonlar" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">Federasyonlar</span>
              </Link>
            </li>
            <li>
              <Link to="/dernekler" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">Dernekler</span>
              </Link>
            </li>
            <li>
              <Link to="/bireysel-duyurular" className="waves-effect">
                <i class="ri-article-fill"></i>
                <span className="ml-1">Bireysel Duyurular</span>
              </Link>
            </li>
            <li>
              <Link to="/egitimler" className="waves-effect">
                <i className=" ri-book-2-fill"></i>
                <span className="ml-1">Eğitimler</span>
              </Link>
            </li>
            <li>
              <Link to="/arama" className="waves-effect">
                <i className="ri-search-line"></i>
                <span className="ml-1">Detaylı Arama</span>
              </Link>
            </li>

            {this.state.canShow && (
              <>
                <li>
                  <Link to="/anasayfa-haberleri" className="waves-effect">
                    <i className="ri-article-line"></i>
                    <span className="ml-1">Anasayfa Haberleri</span>
                  </Link>
                </li>
                <li>
                  <Link to="/tum-kullanicilar" className="waves-effect">
                    <i className="ri-folder-download-line"></i>
                    <span className="ml-1">Tüm Kullanıcılar</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-lock-2-line"></i>
                    <span className="ml-1">Yetkilendirmeler</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link
                        to="/turkonfed-yetkili-kullanicilari"
                        className="waves-effect"
                      >
                        <i className="ri-admin-line"></i>
                        <span className="ml-1">
                          Türkonfed Yetkili Kullanıcıları
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/federasyon-yetkili-kullanicilari"
                        className="waves-effect"
                      >
                        <i className="ri-admin-line"></i>
                        <span className="ml-1">
                          Federasyon Yetkili Kullanıcıları
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dernek-yetkili-kullanicilari"
                        className="waves-effect"
                      >
                        <i className="ri-admin-line"></i>
                        <span className="ml-1">
                          Dernek Yetkili Kullanıcıları
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/onay-bekleyen-kullanicilar"
                        className="waves-effect"
                      >
                        <i className="ri-user-add-line"></i>
                        <span className="ml-1">Onay Bekleyen Kullanıcılar</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/onay-bekleyen-bireysel-duyurular"
                        className="waves-effect"
                      >
                        <i class="ri-chat-new-line"></i>
                        <span className="ml-1">
                          Onay Bekleyen Bireysel Duyurular
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {this.state.canShow && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-share-line"></i>
                  <span className="ml-1">Listeler</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <Link to="/sektor-secenekleri">Sektör Seçenekleri</Link>
                  </li>
                  <li>
                    <Link to="/bolge-secenekleri">Bölge Seçenekleri</Link>
                  </li>
                  <li>
                    <Link to="/ulke-secenekleri">Ülke Seçenekleri</Link>
                  </li>
                  <li>
                    <Link to="/sehir-secenekleri">Şehir Seçenekleri</Link>
                  </li>
                  <li>
                    <Link to="/ilce-secenekleri">İlçe Seçenekleri</Link>
                  </li>
                  <li>
                    <Link to="/federasyon-secenekleri">
                      Federasyon Seçenekleri
                    </Link>
                  </li>
                  <li>
                    <Link to="/dernek-secenekleri">Dernek Seçenekleri</Link>
                  </li>
                  <li>
                    <Link to="/gorev-secenekleri">Görev Seçenekleri</Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
