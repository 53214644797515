import api from "../../config/API";

const ListAssociationsPaginate = async (page, payload) => {
  return api
    .post(`/associationsPaginate?page=${page}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListAssociationsPaginate;
