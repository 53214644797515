import api from "../../config/API";

const ShowFederation = async (id = 0) => {
  return api
    .get(`/federations/${id}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ShowFederation;
