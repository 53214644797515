import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Alert,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

//api
import createAdminUser from "../../api/adminUser/CreateAdminUser";
import GetAdminUser from "../../api/adminUser/GetAdminUser";
import DeleteAdminUser from "../../api/adminUser/DeleteAdminUser";

// Redux
import { connect } from "react-redux";

class FederationAuthorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Yetkilendirme", link: "#" },
        { title: "Türkonfed Yetkili Kullanıcıları", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      data: [],
    };
    this.addCustomer.bind(this);
    this.handleDelete.bind(this);

    GetAdminUser().then((r) => {
      if (r.status === "success") {
        console.log(r);
        this.setState({
          data: r.data,
        });
      }
    });
  }
  addCustomer = (event, values) => {
    //Assign values to the variables
    if (values) {
      createAdminUser(values).then((r) => {
        if (r.status === "success") {
          alert(r.message);
          this.setState({
            data: [...this.state.data, r.data],
            modal_static: false,
          });
        }
      });
    }
  };

  handleDelete = (user) => {
    const answer = window.confirm(
      "Seçilen kullanıcı, kalıcı olarak silinecektir. Bu işlemi onaylıyor musunuz?"
    );

    if (!answer) {
      return;
    }

    if (user) {
      DeleteAdminUser({ id: user.id }).then((r) => {
        if (r.status === "success") {
          alert(r.message);
          var array = [...this.state.data];
          this.setState({
            data: array.filter((arr, i) => arr.id !== user.id),
          });
        }
      });
    }
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="TÜRKONFED YETKİLİ KULLANICILARI"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.setState({
                            modal_static: true,
                            isAlertOpen: false,
                          })
                        }
                        className="mb-2"
                      >
                        <i className="mdi mdi-plus mr-2"></i>Türkonfed Yetkili
                        Kullanıcısı Ekle
                      </Button>
                    </div>
                    <div className="table-responsive mt-3">
                      <Table
                        className="table-centered datatable dt-responsive nowrap "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>İsim Soyisim</th>
                            <th>E-posta</th>

                            <th style={{ width: "120px" }}>İşlemler</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((customerData, key) => (
                            <tr key={key}>
                              <td>{customerData.name}</td>
                              <td>{customerData.email}</td>

                              <td>
                                <Button
                                  id={"delete" + key}
                                  color="danger"
                                  type="button"
                                  className="waves-effect waves-light mr-1"
                                  size="sm"
                                  onClick={() =>
                                    this.handleDelete(customerData)
                                  }
                                >
                                  <i className="mdi mdi-trash-can"></i>
                                </Button>
                                <UncontrolledTooltip
                                  target={"delete" + key}
                                  placement="top"
                                >
                                  Sil
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
              >
                Türkonfed Yetkili Kullanıcısı Ekle
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.addCustomer}>
                  <Row>
                    <Col lg={12}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Başarıyla Eklendi...!
                      </Alert>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="name">İsim Soyisim</Label>
                        <AvField
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder=""
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="email">E-posta</Label>
                        <AvField
                          name="email"
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder=""
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      İptal
                    </Button>
                    <Button type="submit" color="primary">
                      Ekle
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(FederationAuthorization);
