import api from "../config/API";

const GetDashboardStats = async () => {
  return api
    .get(`/dashboardStats`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default GetDashboardStats;
