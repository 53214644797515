import api from "../../config/API";

const ShowAssociation = async (id = 0) => {
  return api
    .get(`/associations/${id}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ShowAssociation;
