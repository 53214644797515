import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  CardImg,
} from "reactstrap";
import Avatar from "react-avatar-edit";
import Select from "react-select";
import { Link } from "react-router-dom";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserPostList from "../Posts/UserPostList";

//Import Images
import img1 from "../../assets/images/product/img-1.png";
import img2 from "../../assets/images/product/img-2.png";
import ListOptions from "../../api/ListOptions";
import updateUserRequest from "../../api/user/updateUserRequest";
import Dropzone from "react-dropzone";

const Profile = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Profil", link: "#" },
    { title: "Düzenleme", link: "#" },
  ]);
  const [activeTab, setActiveTab] = useState(1);
  const [user, setUser] = useState(null);

  const [sectorOptions, setSectorOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [associationOptions, setAssociationOptions] = useState([]);

  //TAB 1 - FORM STATES
  const [name, setName] = useState(null);
  const [title, setTitle] = useState(null);
  const [email, setEmail] = useState(null);
  const [workPhone, setWorkPhone] = useState(null);
  const [internalPhone, setInternalPhone] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [fax, setFax] = useState(null);
  const [assistantName, setAssistantName] = useState(null);
  const [assistantEmail, setAssistantEmail] = useState(null);
  const [note, setNote] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [croppedAvatar, setCroppedAvatar] = useState();
  const [oldAvatar, setOldAvatar] = useState();
  const [CroppedPreview, setCroppedPreview] = useState(null);
  const [AvatarModal, setAvatarModal] = useState(false);

  //TAB 2  - FORM STATES
  const [sectorId, setSectorId] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [employeeCount, setEmployeeCount] = useState(null);
  const [website, setWebsite] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [subSector, setSubSector] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [address, setAddress] = useState(null);

  //TAB 3 - FORM STATES
  const [federation, setFederation] = useState(null);
  const [association, setAssociation] = useState(null);
  const [associationTitle, setAssociationTitle] = useState(null);
  const [associationTitleId, setAssociationTitleId] = useState(null);
  const [federationTitle, setFederationTitle] = useState(null);

  const [associations, setAssociations] = useState([]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab);
      }
    }
  };

  const resetForms = () => {
    transformUserToStates(user);
  };
  const transformUserToStates = (user) => {
    //TAB 1
    setName(user.name);
    setTitle(user.title);
    setEmail(user.email);
    setWorkPhone(user.work_phone);
    setInternalPhone(user.internal_phone);
    setPhone(user.phone);
    setFax(user.fax);
    setAssistantName(user.assistant_name);
    setAssistantEmail(user.assistant_email);
    setNote(user.note);
    setOldAvatar(user.avatar_link);

    setPassword(null);
    setPasswordConfirmation(null);

    //TAB 2
    setSectorId(user.sector_id);
    setCountryId(user.country_id);
    setSubSector(user.sub_sector);

    setCityId(user.city_id);
    setCitiesOptions([user.city]);

    setEmployeeCount(user.employee_count);
    setWebsite(user.website);
    setCompanyName(user.company_name);
    setPostalCode(user.postal_code);
    setAddress(user.address);

    //TAB 3
    if (user.associations.length > 0) {
      let mainAssociation = null;
      user.associations.map((association) => {
        if (association.is_main == 1) {
          mainAssociation = association;
          setAssociation(association);
          setFederation(association.federation);
          if (association.association_title) {
            setAssociationTitle(association.association_title.label);
            setAssociationTitleId(association.association_title.id);
            if (association.federation_title) {
              setFederationTitle(association.federation_title.label);
            }
          }
        }
      });

      if (mainAssociation) {
        setAssociations(
          user.associations
            .filter((a) => a.id != mainAssociation.id)
            .map((a) => ({ label: a.title, value: a }))
        );
      }
    }
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedAvatar(files[0]);
    setAvatarModal(true);
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  const handleCrop = (crop) => {
    // get crop data and create a file to upload as file
    const croppedImage = new File([dataURItoBlob(crop)], "cropped.png", {
      type: "image/PNG",
    });
    setCroppedAvatar(croppedImage);
    setCroppedPreview(crop);
  };

  const handleCloseCropper = () => {
    setCroppedPreview(null);
    setCroppedAvatar(null);
    setSelectedAvatar(null);
    setAvatarModal(false);
  };

  const validation = () => {
    const response = { hasError: false };
    const errors = [];

    //password confirmation control
    if (password) {
      if (password != passwordConfirmation) {
        alert("Girilen şifreler birbirinin aynısı değil.");
        response.hasError = true;
        return response;
      }
    }

    if (!name) errors.push("İsim Soyisim");
    //if (!title) errors.push("Unvan");
    if (!email) errors.push("E-posta");
    if (!phone) errors.push("Cep Telefonu");
    //if (!workPhone) errors.push("İş Telefonu");
    //if (!fax) errors.push("Fax");
    //if (!internalPhone) errors.push("Dahili Numarası");
    //if (!assistantName) errors.push("Asistan İsim Soyisim");
    //if (!assistantEmail) errors.push("Asistan E-posta");

    if (!companyName) errors.push("Firma İsmi");
    //if (!employeeCount) errors.push("Çalışan Sayısı");
    if (!sectorId) errors.push("Sektör");
    //if (!countryId) errors.push("Ülke");
    if (!cityId) errors.push("Şehir");
    //if (!address) errors.push("Adres");
    //if (!postalCode) errors.push("Posta Kodu");
    //if (!website) errors.push("Web Sitesi");

    if (errors.length > 0) {
      response.hasError = true;
      alert("Lütfen, " + errors.join(", ") + " alan(lar)ını doldurunuz.");
    }

    return response;
  };

  const handleSubmit = () => {
    if (validation().hasError) {
      return;
    }
    const data = {
      id: user.id,
      name: name,
      title: title,
      email: email,
      work_phone: workPhone,
      internal_phone: internalPhone,
      phone: phone,
      fax: fax,
      assistant_name: assistantName,
      assistant_email: assistantEmail,
      sector_id: sectorId,
      country_id: countryId,
      city_id: cityId,
      employee_count: employeeCount,
      website: website,
      sub_sector: subSector,
      company_name: companyName,
      postal_code: postalCode,
      note: note,
      address: address,
      association_id: association.id,
      association_title_id: associationTitleId,
    };

    if (password) {
      data.password = password;
      data.password_confirmation = passwordConfirmation;
    }

    if (data) {
      const formData = new FormData();
      for (var key in data) {
        if (data[key]) formData.append(key, data[key]);
      }

      if (croppedAvatar != null) {
        formData.append("avatar_image", croppedAvatar);
      }
      if (associations) {
        associations.map((a) => {
          formData.append("other_associations[]", a.value.id);
        });
      }
  
      updateUserRequest(formData)
        .then((r) => {
          alert(r.message);
          if (r.status === "success") {
            localStorage.setItem("authUser", JSON.stringify(r.data));
            window.location.reload();
          }
        })
        .catch((e) => {
          alert("Tespit edilemeyen bir sorun oluştu. Lütfen tekrar deneyiniz.");
          window.location.reload();
        });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUser(obj);
      transformUserToStates(obj);
    }
    ListOptions().then((r) => {
      if (r.status == "success") {
        setSectorOptions(r.data.sectors);
        setCountriesOptions(r.data.countries);
        setAssociationOptions(r.data.associations);
      }
    });
  }, []);

  useEffect(() => {
    if (countriesOptions.length == 0) return;
    setCitiesOptions(
      countriesOptions.filter((c) => c.id == countryId)[0].cities
    );
  }, [countriesOptions]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profil" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <h4>Profil Düzenleme</h4>
                    <br />
                    <h5 className="card-title">Bilgilendirme</h5>
                    Aşağıdaki sekmelerden güncellemek istediğiniz alana
                    gidebilirsiniz.
                  </div>
                  <div
                    id="checkout-nav-pills-wizard"
                    className="twitter-bs-wizard"
                  >
                    <Nav pills justified className="twitter-bs-wizard-nav">
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggleTab(1);
                          }}
                          className={classnames({
                            active: activeTab === 1,
                          })}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Kişisel Bilgiler</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggleTab(2);
                          }}
                          className={classnames({
                            active: activeTab === 2,
                          })}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Firma Bilgileri</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggleTab(3);
                          }}
                          className={classnames({
                            active: activeTab === 3,
                          })}
                        >
                          <span className="step-number">03</span>
                          <span className="step-title">
                            Federasyon/Dernek Bilgileri
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <TabPane tabId={1}>
                        <Form>
                          <div>
                            <div>
                              <Row>
                                <Col lg={4}>
                                  {oldAvatar && (
                                    <div className="text-center">
                                      <CardImg
                                        src={oldAvatar}
                                        className="rounded-circle img-fluid mb-2"
                                        style={{ width: 200, height: 200 }}
                                      />
                                    </div>
                                  )}
                                  {!selectedAvatar && (
                                    <Dropzone
                                      accept="image/*"
                                      multiple={false}
                                      onDrop={(acceptedFiles) =>
                                        handleAcceptedFiles(acceptedFiles)
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          className="dropzone"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            className="dz-message needsclick mt-2"
                                            style={{ padding: "10px" }}
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />

                                            <div className="mb-3">
                                              {selectedAvatar ? (
                                                <>
                                                  <br />
                                                  <p>
                                                    Aşağıdaki "Profili Güncelle"
                                                    butonuna basarak seçtiğiniz
                                                    görseli yükleyebilirsiniz.
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                  <p>Profil Fotoğrafı Yükle</p>
                                                </>
                                              )}
                                            </div>

                                            <div style={{ fontSize: 12 }}>
                                              Bu alana tıklayarak görseli
                                              seçebilir ya da yüklemek
                                              istediğiniz görseli buraya
                                              sürükleyerek yükleyebilirsiniz.
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                  )}

                                  <FormGroup className="mt-4">
                                    <Label htmlFor="personal-note">
                                      Kişisel Profil Notunuz
                                    </Label>
                                    <textarea
                                      className="form-control"
                                      id="personal-note"
                                      rows="6"
                                      placeholder="Profilinizi ziyaret edenlerin görebileceği kişisel notunuzu/tanıtımınızı bırakabilirsiniz."
                                      value={note}
                                      onChange={(e) => {
                                        if(e.target.value.length<1) setNote(" "); else setNote(e.target.value)}
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      İsim Soyisim
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder="İsim Soyisim giriniz"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </FormGroup>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      E-posta
                                    </Label>
                                    <Input
                                      type="email"
                                      className="form-control"
                                      id="billing-email-address"
                                      placeholder="abc@mail.com"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </FormGroup>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      İş Telefonu
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-email-address"
                                      placeholder=""
                                      value={workPhone}
                                      onChange={(e) =>
                                        setWorkPhone(e.target.value)
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup className="mb-4">
                                    <Label htmlFor="assistname">
                                      Asistan İsim Soyisim
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="assistname"
                                      placeholder=""
                                      value={assistantName}
                                      onChange={(e) => {
                                       
                                        if(e.target.value.length<1) setAssistantName(" "); else setAssistantName(e.target.value); }}                                    
                                    />
                                  </FormGroup>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="assistmail">
                                      Asistan E-posta
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="assistmail"
                                      autocomplete="off"
                                      placeholder=""
                                      value={assistantEmail}
                                      onChange={(e) => {
                                        if(e.target.value.length<1) setAssistantEmail(" "); else setAssistantEmail(e.target.value)}
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">Unvan</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder="Unvan giriniz"
                                      value={title}
                                      onChange={(e) => {
                                        if(e.target.value.length<1) setTitle(" "); else setTitle(e.target.value)}
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      Cep Telefonu
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-email-address"
                                      placeholder="(5xx) 123 1234"
                                      value={phone}
                                      onChange={(e) => setPhone(e.target.value)}
                                    />
                                  </FormGroup>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      Dahili Numaranız
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-email-address"
                                      placeholder=""
                                      value={internalPhone}
                                      onChange={(e) => {

                                        if(e.target.value.length<1) setInternalPhone(" "); else setInternalPhone(e.target.value)}
                                      }                                      
                                    />
                                  </FormGroup>

                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      Fax
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-email-address"
                                      placeholder=""
                                      value={fax}
                                      onChange={(e) => {
                                        if(e.target.value.length<1) setFax(" "); else setFax(e.target.value)}
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <br />
                                  <p className="card-title-desc">
                                    Şifrenizi güncellemek istemiyorsanız boş
                                    bırakabilirsiniz.
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="passw">Şifre</Label>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      id="passw"
                                      autocomplete="new-password"
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="passww">Şifre Tekrar</Label>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      id="passww"
                                      autocomplete="off"
                                      value={passwordConfirmation}
                                      onChange={(e) =>
                                        setPasswordConfirmation(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Modal
                                isOpen={AvatarModal}
                              
                                centered={true}
                                size="xl"
                              >
                                <ModalHeader
                                  toggle={() => handleCloseCropper()}
                                >
                                  Fotoğrafı Boyutla/Kırp
                                </ModalHeader>
                                <ModalBody>
                                  <div className="text-center">
                                    {CroppedPreview && (
                                      <CardImg
                                        src={CroppedPreview}
                                        className="rounded-circle img-fluid mb-2"
                                        style={{ width: 200, height: 200 }}
                                      />
                                    )}

                                    {selectedAvatar && (
                                      
                                      <Avatar
                                        height={390}
                                        onCrop={(crop) => handleCrop(crop)}
                                        src={selectedAvatar.preview}
                                        onClose={() => handleCloseCropper()}
                                      />
                                    )}
                                    {selectedAvatar && (
                                     <div className="mt-4 text-center">
                                     <Link
                                       to="#"
                                       className="btn btn-warning"
                                       onClick={() => handleSubmit()}
                                     >
                                       Profili Güncelle
                                     </Link>
                                   </div>
                                    )}
                                  </div>
                                </ModalBody>
                              </Modal>

                              <div className="mt-4 text-center">
                                <Link
                                  to="#"
                                  className="btn btn-warning"
                                  onClick={() => handleSubmit()}
                                >
                                  Profili Güncelle
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </TabPane>
                      <TabPane tabId={2}>
                        <Form>
                          <div>
                            <div>
                              <Row>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Firma İsmi
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder="Çalıştığınız firmanın ismini giriniz"
                                      value={companyName}
                                      onChange={(e) =>
                                        setCompanyName(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Çalışan Sayısı
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder="Çalıştığınız firmadaki çalışan sayısını giriniz"
                                      value={employeeCount}
                                      onChange={(e) =>
                                        setEmployeeCount(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      Sektör
                                    </Label>
                                    <Select
                                      //hasError={this.state.company_sector_error}
                                      id="company_sector"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      value={sectorOptions.filter(
                                        (option) => option.id === sectorId
                                      )}
                                      name="company_sector"
                                      placeholder="Sektör seçiniz"
                                      options={sectorOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        setSectorId(option.id)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      Ülke
                                    </Label>
                                    <Select
                                      //hasError={this.state.company_sector_error}
                                      id="company_sector"
                                      classNamePrefix="select"
                                      value={countriesOptions.filter(
                                        (option) => option.id === countryId
                                      )}
                                      isSearchable={true}
                                      name="company_country"
                                      placeholder="Ülke seçiniz"
                                      options={countriesOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) => {
                                        setCountryId(option.id);
                                        setCitiesOptions(option.cities);
                                        setCityId(
                                          option.cities[0]
                                            ? option.cities[0].id
                                            : 0
                                        );
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-email-address">
                                      Şehir
                                    </Label>
                                    <Select
                                      //hasError={this.state.company_sector_error}
                                      id="company_sector"
                                      classNamePrefix="select"
                                      value={citiesOptions.filter(
                                        (option) => option.id === cityId
                                      )}
                                      isSearchable={true}
                                      name="company_area"
                                      placeholder="Şehir seçiniz"
                                      options={citiesOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        setCityId(option.id)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Alt Sektör
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder=""
                                      value={subSector}
                                      onChange={(e) =>
                                        setSubSector(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="billing-address">Adres</Label>
                                <textarea
                                  className="form-control"
                                  id="billing-address"
                                  rows="3"
                                  placeholder="Firma Adres Bilgileri"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                              </FormGroup>
                              <Row>
                                <Col lg={6}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Posta Kodu
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder=""
                                      value={postalCode}
                                      onChange={(e) =>
                                        setPostalCode(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Web Sitesi
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder="Başında http:// olmadan giriş yapınız. (Örnek: www.turkonfed.org)"
                                      value={website}
                                      onChange={(e) =>
                                        setWebsite(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className="mt-4 text-center">
                                <Link
                                  to="#"
                                  className="btn btn-warning"
                                  onClick={() => handleSubmit()}
                                >
                                  Profili Güncelle
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </TabPane>
                      <TabPane tabId={3}>
                        <h5 className="card-title">Bilgilendirme</h5>
                        <p className="card-title-desc">
                          Ana Federasyon ve Dernek bilgileriniz yalnızca kayıt
                          esnasında güncellenebilir.
                        </p>

                        <Form>
                          <div>
                            <div>
                              <Row>
                                <Col>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Federasyon
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder="İsim Soyisim giriniz"
                                      value={federation ? federation.title : ""}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">Dernek</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder="İsim Soyisim giriniz"
                                      value={
                                        association ? association.title : ""
                                      }
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Dernekteki Görevi
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="billing-name"
                                      placeholder=""
                                      value={associationTitle}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              {federationTitle && (
                                <Row>
                                  <Col>
                                    <FormGroup className="mb-4">
                                      <Label htmlFor="billing-name">
                                        Federasyondaki Görevi
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="billing-name"
                                        placeholder=""
                                        value={federationTitle}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="billing-name">
                                      Bulunduğu Diğer Dernekler
                                    </Label>
                                    <Select
                                      value={associations}
                                      placeholder="Üyesi olduğunuz diğer dernekleri seçebilirsiniz."
                                      isClearable={false}
                                      isMulti={true}
                                      onChange={(selectedAssociations) =>
                                        setAssociations(
                                          selectedAssociations || []
                                        )
                                      }
                                      options={associationOptions
                                        .filter(
                                          (a) =>
                                            ![
                                              association,
                                              ...associations.map(
                                                (a0) => a0.value
                                              ),
                                            ].some((a1) => a1.id == a.id)
                                        )
                                        .map((association) => ({
                                          label: association.title,
                                          value: association,
                                        }))}
                                      classNamePrefix="select2-selection"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className="mt-4 text-center">
                                <Link
                                  to="#"
                                  className="btn btn-warning"
                                  onClick={() => handleSubmit()}
                                >
                                  Profili Güncelle
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </TabPane>
                    </TabContent>

                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          className="btn"
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                          }}
                        >
                          Önceki
                        </Link>
                      </li>
                      <li
                        className={activeTab === 3 ? "next disabled" : "next"}
                      >
                        <Link
                          className="btn"
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab + 1);
                          }}
                        >
                          Sonraki
                        </Link>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {user && (
              <Col lg={12}>
                <UserPostList user_id={user.id} />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
