import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Spinner,
  Button,
} from "reactstrap";


import ReactExport from "react-export-excel";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";


// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

//api
import confirmUserRequest from "../../api/user/confirmUserRequest";
import DeleteAdminUser from "../../api/adminUser/DeleteAdminUser";

// Redux
import { connect } from "react-redux";

import ViewUser from "../users/ViewUser";
import ListAllUsers from "../../api/adminUser/ListAllUsers";
import ListOptions from "../../api/ListOptions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Kullanıcılar", link: "#" },
        { title: "Tüm Kullanıcılar", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      waiting_admin_users: [],
      waiting_email_users: [],
      isViewOpen: false,
      viewed: {},
      SectorOptions: [],
      FederationTitles: [],
      AssociationTitles: [],
      toExcel: [],
      isLoading: true,
    };
    this.handleConfirmation.bind(this);
    this.handleDelete.bind(this);

    ListOptions().then((r) => {
        if (r.status == "success") {
          this.setState({
            SectorOptions: r.data.sectors,
            FederationTitles: r.data.federationTitles,
            AssociationTitles: r.data.associationTitles,
          });
        }
      });

    ListAllUsers().then((r) => {
      if (r.status === "success" && this.state.AssociationTitles.length > 0 && this.state.FederationTitles.length > 0) {
        console.log(r);
        this.setState({
          waiting_admin_users: r.data,
          //set toExcel's associations column
          toExcel: r.data.map((data) => {
            return {
              ...data,
              city: data.city.title,
              country: data.country.title,
              sector: data.sector.title,
              associations: data.associations
                .map((association) => association.title)
                .toString(),
              federations: data.associations
                .map((a) => a.federation.title)
                .toString(),
             association_title: 
                this.state.AssociationTitles.filter(
                    (a) => (a.id == data.associations[0].pivot.association_title_id)
                ).map((a) => a.label).toString(),
                federation_title: 
                this.state.FederationTitles.filter(
                    (a) => (a.id == data.associations[0].pivot.federation_title_id)
                ).map((a) => a.label).toString(),
                created_at: (data.created_at).slice(0, 10),
                updated_at: (data.updated_at).slice(0, 10),

            };
          }),
          isLoading: false,
        });
      }
    });
  }
  handleConfirmation = (user, confirmation) => {
    const answer = window.confirm(
      confirmation == 1
        ? "Seçilen kullanıcının, sistemi kullanması için onay veriyor musunuz?"
        : "Seçilen kullanıcının, sistemi kullanması engellenecektir ve tekrar aynı e-posta adresi ile giriş yapamayacaktır. Bu işlemi onaylıyor musunuz?"
    );

    if (!answer) {
      return;
    }

    if (user) {
      console.log(user, confirmation);

      confirmUserRequest({ id: user.id, confirmation: confirmation }).then(
        (r) => {
          alert(r.message);
          if (r.status === "success") {
            var array = [...this.state.waiting_admin_users];
            this.setState({
              waiting_admin_confirmation: array.filter(
                (arr, i) => arr.id !== user.id
              ),
            });
            window.location.reload();
          }
        }
      );
    }
  };

  handleViewOpen = (row) => {
    this.setState({
      viewed: row,
      isViewOpen: true,
    });
  };

  handleDelete = (user) => {
    const answer = window.confirm(
      "Seçilen başvuru, e-posta onayı beklenmeden kalıcı olarak silinecektir. Bu işlemi onaylıyor musunuz?"
    );

    if (!answer) {
      return;
    }

    if (user) {
      DeleteAdminUser({ id: user.id }).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          var array = [...this.state.waiting_admin_users];
          this.setState({
            waiting_admin_confirmation: array.filter(
              (arr, i) => arr.id !== user.id
            ),
          });
          window.location.reload();
        }
      });
    }
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="TÜM KULLANICILAR"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.isLoading ? (
        <Row className="justify-content-center">
            <Col lg="12" className="text-center mb-4">
          <Spinner className="font-size-20" color="primary" /></Col>
          <Col lg="12">
          <p className="text-center">Tüm kullanıcılar listeleniyor. Bu işlem uzun sürebilir. Lütfen bekleyiniz.</p>
          </Col>
        </Row>
      ):(
        <Row>
          <Col lg={12}>
            <Col lg={12} className="text-center">
              <ExcelFile
              filename="BİA - Tüm Kullanıcılar"
                element={
                  <Button
                    color="secondary btn-lg"
                    className="mb-2 mt-4 mr-2"
                  >
                    <i className="fa fa-download mr-2"></i>
                    Excel Olarak İndir
                  </Button>
                }
              >
                <ExcelSheet
                  data={this.state.toExcel}
                  name="BİA - Tüm Kullanıcılar"
                >
                  <ExcelColumn label="İsim Soyisim" value="name" />
                  <ExcelColumn label="E-posta" value="email" />
                  <ExcelColumn label="Cep Telefonu" value="phone" />
                  <ExcelColumn label="İş Telefonu" value="work_phone" />
                  <ExcelColumn label="Dahili" value="internal_phone" />
                  <ExcelColumn label="Fax" value="fax" />
                  <ExcelColumn
                    label="Asistan İsim Soyisim"
                    value="assistant_name"
                  />
                  <ExcelColumn
                    label="Asistan E-posta"
                    value="assistant_email"
                  />
                  <ExcelColumn label="Kullanıcı Notu" value="note" />
                  <ExcelColumn label="Firma İsmi" value="company_name" />
                  <ExcelColumn label="Unvan" value="title" />
                  <ExcelColumn label="Sektör" value="sector" />
                  <ExcelColumn label="Alt Sektör" value="sub_sector" />
                  <ExcelColumn label="Çalışan Sayısı" value="employee_count" />
                  <ExcelColumn label="Adres" value="address" />
                  <ExcelColumn label="Şehir" value="" />
                  <ExcelColumn label="Ülke" value="country" />
                  <ExcelColumn label="Posta Kodu" value="postal_code" />
                  <ExcelColumn label="Website" value="website" />
                  <ExcelColumn label="Federasyonlar" value="federations" />
                  <ExcelColumn label="Federasyondaki Görevi" value="federation_title" />
                  <ExcelColumn label="Dernekler" value="associations" />
                  <ExcelColumn label="Dernekteki Görevi" value="association_title" />
                  <ExcelColumn label="Profil Son Güncelleme Zamanı" value="updated_at" />
                  <ExcelColumn label="Profilin Oluşturulma Zamanı" value="created_at"/>
                </ExcelSheet>
              </ExcelFile>
            </Col>
            <Card>
              <CardBody>
                <div className="table-responsive mt-3">
                  <Table
                    className="table-centered datatable dt-responsive nowrap "
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "137px" }}>İşlemler</th>
                        <th>İsim Soyisim</th>
                        <th>E-posta</th>
                        <th>Telefon</th>
                        <th>Federasyon</th>
                        <th>Dernek</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.waiting_admin_users.map(
                        (customerData, key) => (
                          <tr key={key}>
                            <td style={{}}>
                              <Button
                                id={"view" + key}
                                color="warning"
                                type="button"
                                className="waves-effect waves-light mr-1 mb-1 mt-1"
                                size="sm"
                                onClick={() =>
                                  this.handleViewOpen(customerData)
                                }
                              >
                                <i className="ri-eye-line align-middle"></i>
                              </Button>
                              <UncontrolledTooltip
                                target={"view" + key}
                                placement="top"
                              >
                                Görüntüle
                              </UncontrolledTooltip>
                              <Button
                                id={"reject" + key}
                                color="danger"
                                type="button"
                                className="waves-effect waves-light mr-1 mb-1 mt-1"
                                size="sm"
                                onClick={() =>
                                  this.handleDelete(customerData)
                                }
                              >
                                <i className="mdi mdi-trash-can align-middle"></i>
                              </Button>
                              <UncontrolledTooltip
                                target={"reject" + key}
                                placement="top"
                              >
                                Sil
                              </UncontrolledTooltip>
                            </td>

                            <td>{customerData.name || ""}</td>
                            <td>{customerData.email || ""}</td>
                            <td>{customerData.phone || ""}</td>
                            <td>
                              {customerData.associations.length > 0
                                ? customerData.associations.filter(
                                    (a) => a.pivot.is_main == 1
                                  )[0].federation.title
                                : ""}
                            </td>
                            <td>
                              {customerData.associations.length > 0
                                ? customerData.associations.filter(
                                    (a) => a.pivot.is_main == 1
                                  )[0].title
                                : ""}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>)}

            <ViewUser
              data={this.state.viewed}
              isOpen={this.state.isViewOpen}
              toggle={() =>
                this.setState({ isViewOpen: !this.state.isViewOpen })
              }
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(DownloadUsers);
