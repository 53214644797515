import api from "../../config/API";

const CreateCountry = async (payload) => {
  return api
    .post(`/countries`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default CreateCountry;
