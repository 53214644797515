import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import SolidRegister from "../pages/Authentication/SolidRegister";
import Register from "../pages/Authentication/Register";
import Register2 from "../pages/Authentication/Register2";
import Register3 from "../pages/Authentication/Register3";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component
import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
import Products from "../pages/Ecommerce/Products";
import ProductDetail from "../pages/Ecommerce/ProductDetail";
import Orders from "../pages/Ecommerce/Orders";
import Customers from "../pages/Ecommerce/Customers";
import Cart from "../pages/Ecommerce/Cart";
import CheckOut from "../pages/Ecommerce/CheckOut";
import Shops from "../pages/Ecommerce/Shops";
import AddProduct from "../pages/Ecommerce/AddProduct";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
import KanbanBoard from "../pages/KanbanBoard/index";
import Sector from "../pages/Options/Sectors/Sector";
import Region from "../pages/Options/Regions/Region";
import Country from "../pages/Options/Countries/Country";
import City from "../pages/Options/Cities/City";
import Federation from "../pages/Options/Federations/Federation";
import Federations from "../pages/Federation/Federation";
import Association from "../pages/Options/Associations/Association";
import Associations from "../pages/Association/Association";
import Verify from "../pages/Authentication/Verify";
import Authorization from "../pages/Authorization/index";
import PageAuthorization from "../pages/PageAuthorization/index";
import ResetPassword from "../pages/Authentication/ResetPassword";
import UserConfirmation from "../pages/UserConfirmation/index";
import PostConfirmation from "../pages/Posts/PostConfirmation";
import SearchPage from "../pages/users/SearchPage";
import FederationAuthorization from "../pages/Authorization/Federations/FederationAuthorization";
import AssociationAuthorization from "../pages/Authorization/Associations/AssociationAuthorization";
import Profile from "../pages/Profile/Profile";
import Title from "../pages/Options/Titles/Title";
import UserProfile from "../pages/UserProfile/index";
import FederationDetails from "../pages/Federation/Details";
import AsoociationDetails from "../pages/Association/Details";
import District from "../pages/Options/Districts/District";
import Landing from "../pages/Landing/Landing";
import NewsPage from "../pages/News/NewsPage";
import Details from "../pages/Confederation/Details";
import Education from "../pages/Education/Education";
import UserPosts from "../pages/Posts/UserPosts";
import DownloadUsers from "../pages/users/DownloadUsers";

const authProtectedRoutes = [
  { path: "/profil", component: Profile, title: "Profil" },
  { path: "/kullanici/:id?", component: UserProfile, title: "BİA Kullanıcı Profili Görüntüleme" },
  {
    path: "/federasyon/:id?",
    component: FederationDetails,
    title: "Federasyon Detay",
  },
  {
    path: "/dernek/:id?",
    component: AsoociationDetails,
    title: "Dernek Detay",
  },

  {
    path: "/anasayfa-haberleri",
    component: NewsPage,
    onlySuperAdmin: true,
    title: "Anasayfa Haberleri",
  },
  //Options
  {
    path: "/sektor-secenekleri",
    component: Sector,
    onlySuperAdmin: true,
    title: "Sektör Seçenekleri",
  },
  {
    path: "/bolge-secenekleri",
    component: Region,
    onlySuperAdmin: true,
    title: "Bölge Seçenekleri",
  },
  {
    path: "/ulke-secenekleri",
    component: Country,
    onlySuperAdmin: true,
    title: "Ülke Seçenekleri",
  },
  {
    path: "/sehir-secenekleri",
    component: City,
    onlySuperAdmin: true,
    title: "Şehir Seçenekleri",
  },
  {
    path: "/ilce-secenekleri",
    component: District,
    onlySuperAdmin: true,
    title: "İlçe Seçenekleri",
  },
  {
    path: "/federasyon-secenekleri",
    component: Federation,
    onlySuperAdmin: true,
    title: "Federasyon Seçenekleri",
  },
  {
    path: "/dernek-secenekleri",
    component: Association,
    onlySuperAdmin: true,
    title: "Dernek Seçenekleri",
  },
  {
    path: "/gorev-secenekleri",
    component: Title,
    onlySuperAdmin: true,
    title: "Görev Seçenekleri",
  },

  {
    path: "/turkonfed-yetkili-kullanicilari",
    component: Authorization,
    onlySuperAdmin: true,
    title: "Türkonfed Yetkili Kullanıcıları",
  },
  {
    path: "/federasyon-dernek-yetkili-kullanicilari",
    component: PageAuthorization,
    onlySuperAdmin: true,
  },
  {
    path: "/federasyon-yetkili-kullanicilari",
    component: FederationAuthorization,
    onlySuperAdmin: true,
    title: "Federasyon Yetkili Kullanıcıları",
  },
  {
    path: "/dernek-yetkili-kullanicilari",
    component: AssociationAuthorization,
    onlySuperAdmin: true,
    title: "Dernek Yetkili Kullanıcıları",
  },
  {
    path: "/onay-bekleyen-kullanicilar",
    component: UserConfirmation,
    onlySuperAdmin: true,
    title: "Onay Bekleyen Kullanıcılar",
  },
  {
    path: "/onay-bekleyen-bireysel-duyurular",
    component: PostConfirmation,
    onlySuperAdmin: true,
    title: "Onay Bekleyen Bireysel Duyurular",
  },
  {
    path: "/tum-kullanicilar",
    component: DownloadUsers,
    onlySuperAdmin: true,
    title: "Tüm Kullanıcılar",
  },

  { path: "/arama", component: SearchPage, title: "Arama" },
  { path: "/turkonfed", component: Details, title: "TÜRKONFED" },
  { path: "/federasyonlar", component: Federations, title: "Federasyonlar" },
  { path: "/dernekler", component: Associations, title: "Dernekler" },
  { path: "/egitimler", component: Education, title: "Eğitimler" },
  { path: "/bireysel-duyurular", component: UserPosts, title: "Bireysel Duyurular" },


  //Kanban Board
  { path: "/apps-kanban-board", component: KanbanBoard },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-roundslider", component: UIRoundSlider },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  //Utility
  { path: "/pages-starter", component: StarterPage },
  { path: "/pages-timeline", component: Timeline },
  { path: "/pages-faqs", component: FAQs },
  { path: "/pages-pricing", component: Pricing },

  //Icons
  { path: "/icons-remix", component: RemixIcons },
  { path: "/icons-materialdesign", component: MaterialDesign },
  { path: "/icons-dripicons", component: DripiIcons },
  { path: "/icons-fontawesome", component: FontAwesome },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },

  //Charts
  { path: "/charts-apex", component: ChartApex },
  { path: "/charts-chartjs", component: ChartjsChart },
  { path: "/charts-sparkline", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },

  //Ecommerce
  { path: "/ecommerce-products", component: Products },
  { path: "/ecommerce-product-detail", component: ProductDetail },
  { path: "/ecommerce-orders", component: Orders },
  { path: "/ecommerce-customers", component: Customers },
  { path: "/ecommerce-cart", component: Cart },
  { path: "/ecommerce-checkout", component: CheckOut },
  { path: "/ecommerce-shops", component: Shops },
  { path: "/ecommerce-add-product", component: AddProduct },

  //chat
  { path: "/apps-chat", component: Chat },

  //calendar
  { path: "/calendar", component: Calendar },

  { path: "/platform", component: Dashboard },
  { path: "/", component: Landing, title: "Ana Sayfa", homepage: true },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/platform" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout, title: "Çıkış" },
  { path: "/giris", component: Login, title: "Giriş" },
  { path: "/sifremi-unuttum", component: ForgetPwd, title: "Şifremi Unuttum" },
  {
    path: "/sifremi-degistir",
    component: ResetPassword,
    title: "Şifremi Değiştir",
  },
  { path: "/kayit", component: SolidRegister, title: "Kayıt Formu" },
  { path: "/kayit-formu-1", component: Register, title: "Kayıt Formu" },
  { path: "/kayit-formu-2", component: Register2, title: "Kayıt Formu" },
  { path: "/kayit-formu-3", component: Register3, title: "Kayıt Formu" },
  { path: "/auth-lock-screen", component: AuthLockScreen },
  { path: "/hesap-dogrula", component: Verify, title: "Hesap Doğrulama" },

  // Authentication Inner
  { path: "/auth-login", component: Login1 },
  { path: "/auth-register", component: Register1 },
  { path: "/auth-recoverpw", component: ForgetPwd1 },

  { path: "/pages-maintenance", component: Maintenance },
  { path: "/pages-comingsoon", component: CommingSoon },
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
