import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import { AvForm, AvField } from "availity-reactstrap-validation";

import ViewCountry from "./ViewCountry";
//api
import ListCountries from "../../../api/countries/ListCountries";
import CreateCountry from "../../../api/countries/CreateCountry";
import DeleteCountry from "../../../api/countries/DeleteCountry";

function Country() {
  const [breadcrumbItems] = useState([{ title: "BİA", link: "#" }]);

  const [title, setTitle] = useState("");
  const [order, setOrder] = useState(1);
  const [countries, setCountries] = useState([]);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [viewed, setViewed] = useState({});
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ListCountries(currentPage).then((r) => {
      if (r.status === "success") {
        setCountries(r.data.data);

        setPagination(
          r.data.links.map((link) => {
            link.page_number = null;
            if (link.label == "&laquo; Previous") {
              link.label = "Önceki";
            }

            if (link.label == "Next &raquo;") {
              link.label = "Sonraki";
            }

            if (!link.url) {
              return link;
            }

            const page_number = link.url.split("=")[1];
            link.page_number = page_number;
            return link;
          })
        );
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleDelete = (id) => {
    const confirmation = window.confirm(
      "Veri kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );
    if (!confirmation) return;

    DeleteCountry(id).then((r) => {
      if (r.status === "success") {
        alert(r.message);
        var array = [...countries];
        setCountries(array.filter((arr, i) => arr.id !== id));
      }
    });
  };
  const columns = [
    {
      name: "İşlemler",
      cell: (row) => (
        <>
          <Button
            color="warning"
            type="button"
            className="waves-effect waves-light mr-1"
            size="sm"
            onClick={() => handleViewOpen(row)}
          >
            <i className="ri-eye-line align-middle"></i>
          </Button>
          <Button
            color="primary"
            type="button"
            className="waves-effect waves-light mr-1"
            size="sm"
            onClick={() => handleDelete(row.id)}
          >
            <i className="ri-close-circle-line align-middle"></i>
          </Button>
        </>
      ),
      grow: 0,
    },
    { name: "ID", selector: "id", sortable: true, width: "100px" },
    { name: "Ülke", selector: "title", sortable: true },
    { name: "Sıra", selector: "order", sortable: true },
  ];
  const handleSubmit = (event, values) => {
    if (values) {
      CreateCountry({ title, order }).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          setCountries((oldCountries) => [...countries, r.data]);
          setTitle("");
          setOrder("");
          window.location.reload();
        }
      });
    }
  };
  const handleViewOpen = (row) => {
    setViewed(row);
    setIsViewOpen(true);
  };
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="ÜLKE SEÇENEKLERİ"
          breadcrumbItems={breadcrumbItems}
        />
        <Row>
          <Col>
            <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
              <Row>
                <Card style={{ width: "100%" }}>
                  <CardBody>
                    <FormGroup>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-12 col-form-label"
                      >
                        Ülke
                      </Label>
                      <Col md={12}>
                        <AvField
                          name="title"
                          value={title}
                          validate={{ required: true }}
                          type="text"
                          className="form-control"
                          onChange={(e) => setTitle(e.target.value.trim())}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-12 col-form-label"
                      >
                        Sıra
                      </Label>
                      <Col md={12}>
                        <AvField
                          name="order"
                          value={order}
                          validate={{ required: true }}
                          type="text"
                          className="form-control"
                          onChange={(e) => setOrder(e.target.value.trim())}
                        />
                      </Col>
                      <Col md={6}>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light mr-1 mt-1"
                          type="submit"
                        >
                          Ekle
                        </Button>{" "}
                      </Col>{" "}
                    </FormGroup>
                  </CardBody>
                </Card>
              </Row>
            </AvForm>
            <Row>
              <Card style={{ width: "100%" }}>
                <CardBody>
                  {isLoading && (
                    <Row>
                      <Spinner className="font-size-20" color="primary" />
                    </Row>
                  )}
                  {!isLoading && (
                    <Table
                      noHeader
                      showTable={true}
                      title="Sektörler"
                      columns={columns}
                      data={countries}
                      defaultSortField="name"
                      progressPending={false}
                    />
                  )}
                  <ViewCountry
                    data={viewed}
                    isOpen={isViewOpen}
                    toggle={() => setIsViewOpen(!isViewOpen)}
                  />
                </CardBody>
              </Card>
            </Row>
            {pagination && pagination.length > 3 && (
              <Row>
                <Pagination aria-label="Page navigation example">
                  {pagination.map((page) => (
                    <PaginationItem
                      onClick={() => {
                        if (!page.page_number) return;
                        if (page.page_number == currentPage) return;

                        setCurrentPage(page.page_number);
                      }}
                      disabled={!page.page_number}
                      className={page.page_number == currentPage && `active`}
                    >
                      <PaginationLink>{page.label}</PaginationLink>
                    </PaginationItem>
                  ))}
                </Pagination>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Country;
