import api from "../config/API";

const ListFilterOptions = async () => {
  return api
    .get(`/getFilterOptionList`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListFilterOptions;
