import api from "../../config/API";

const ListUserPendingPosts = async () => {
  return api
    .get(`/listUserPendingPosts`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListUserPendingPosts;
