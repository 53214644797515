import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

function ViewUser({ data, isOpen, toggle, ...props }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <Col md="12">
          <AvForm
            /* onValidSubmit={handleSubmit}  */ className="form-horizontal"
          >
            <FormGroup>
              <Col md={12}>
                <Label htmlFor="name">İsim Soyisim</Label>
                <AvField
                  id="name"
                  name="name"
                  value={(data && data.name) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="email">E-posta</Label>
                <AvField
                  id="email"
                  name="email"
                  value={(data && data.email) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="title">Title</Label>
                <AvField
                  id="title"
                  name="title"
                  value={(data && data.title) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="phone">Telefon</Label>
                <AvField
                  id="phone"
                  name="phone"
                  value={(data && data.phone) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="country">Ülke</Label>
                <AvField
                  id="country"
                  name="country"
                  value={data && data.country ? data.country.title : ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="city">Şehir</Label>
                <AvField
                  id="city"
                  name="city"
                  value={data && data.city ? data.city.title : ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="address">Adres</Label>
                <AvField
                  id="address"
                  name="address"
                  value={(data && data.address) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>

              <Col md={12}>
                <Label htmlFor="postal_code">Posta Kodu</Label>
                <AvField
                  id="postal_code"
                  name="postal_code"
                  value={(data && data.postal_code) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="website">Website</Label>
                <AvField
                  id="website"
                  name="website"
                  value={(data && data.website) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>

              <Col md={12}>
                <Label htmlFor="company_name">Firma İsmi</Label>
                <AvField
                  id="company_name"
                  name="company_name"
                  value={(data && data.company_name) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="sector">Sektör</Label>
                <AvField
                  id="sector"
                  name="sector"
                  value={data && data.sector ? data.sector.title : ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="sub_sector">Alt Sektör</Label>
                <AvField
                  id="sub_sector"
                  name="sub_sector"
                  value={(data && data.sub_sector) || ""}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="employee_count">Çalışan Sayısı</Label>
                <AvField
                  id="employee_count"
                  name="employee_count"
                  value={(data && data.employee_count) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="internal_phone">Dahili Numara</Label>
                <AvField
                  id="internal_phone"
                  name="internal_phone"
                  value={(data && data.internal_phone) || ""}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="work_phone">İş Telefonu</Label>
                <AvField
                  id="work_phone"
                  name="work_phone"
                  value={(data && data.work_phone) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="fax">Fax</Label>
                <AvField
                  id="fax"
                  name="fax"
                  value={(data && data.fax) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="assistant_name">Asistan İsim Soyisim</Label>
                <AvField
                  id="assistant_name"
                  name="assistant_name"
                  value={(data && data.assistant_name) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>
              <Col md={12}>
                <Label htmlFor="assistant_email">Asistan E-posta</Label>
                <AvField
                  id="assistant_email"
                  name="assistant_email"
                  value={(data && data.assistant_email) || ""}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>

              <Col md={12}>
                <Label htmlFor="associations">Dernek(ler)</Label>
                <AvField
                  id="associations"
                  name="associations"
                  value={
                    data && data.associations && data.associations.length > 0
                      ? data.associations
                          .map(
                            (a) => a.title + " ( " + a.federation.title + " )"
                          )
                          .join(",\n")
                      : ""
                  }
                  validate={{ required: true }}
                  type="textarea"
                  className="form-control"
                  /* onChange={(e) => setTitle(e.target.value.trim())} */
                  disabled
                />
              </Col>

              {/* <Col md={6}>
                <Button
                  color="primary"
                  className=" waves-effect waves-light mr-1 mt-1"
                  type="submit"
                >
                  Güncelle
                </Button>{" "}
              </Col>{" "} */}
            </FormGroup>
          </AvForm>
        </Col>
      </ModalBody>
    </Modal>
  );
}
export default ViewUser;
