import api from "../../config/API";

const resetPasswordRequest = async (data) => {
  return api
    .post(`/forgotten/password/reset`, data)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default resetPasswordRequest;
