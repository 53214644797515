import api from "../../config/API";

const ListDistricts = async (page) => {
  return api
    .get(`/districts?page=${page}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListDistricts;
