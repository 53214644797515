import { CALL_DATA, UPDATE_DATA, UPDATE_USER } from "./actionTypes";

export const callData = () => ({
  type: CALL_DATA,
  payload: null,
});

export const updateData = (data) => ({
  type: UPDATE_DATA,
  payload: data,
});
export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});
