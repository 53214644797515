import api from "../../config/API";

const simpleFilterRequest = async (payload) => {
  return api
    .post(`/simpleListUsers`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default simpleFilterRequest;
/*
"id"                -- (int)
"name"              -- //User's name
"email"             --
"title"             --
"company_name"      --

*/
