import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// Reactstrap
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import Select from "react-select";
//i18n
import { withNamespaces } from "react-i18next";

//Import Images
import megamenuImg from "../../assets/images/megamenu-img.png";

import ListOptions from "../../api/ListOptions";
const StyledSelect = styled(Select)`
  .select__control {
  }
  .select__value-container {
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;
const StyledLabel = styled(Label)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 12px;
`;
class MegaMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      megaMenu: false,
      name: "",
      title: "",
      company_name: "",
      loading: "",
      sectorOptions: [],
      regionOptions: [],
    };
    ListOptions().then((r) => {
      if (r.status == "success") {
        this.setState({
          sectorOptions: r.data.sectors,
          regionOptions: r.data.regions,
        });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          className="dropdown-mega d-none d-lg-block ml-2"
          isOpen={this.state.megaMenu}
          toggle={() => {
            this.setState({ megaMenu: !this.state.megaMenu });
          }}
        >
          <DropdownToggle
            tag="button"
            type="button"
            caret
            className="btn header-item waves-effect"
          >
            DETAYLI ARAMA
            <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-megamenu">
            <Row>
              <Col sm={6}>
                <Row>
                  <Col md={12}>
                    <h5 className="font-size-14 mt-0">DETAYLI ARAMA</h5>{" "}
                    <FormGroup>
                      <Label htmlFor="name" className="col-md-6 col-form-label">
                        İsim Soyisim
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="name"
                          name="name"
                          onChange={(e) =>
                            this.setState({
                              name: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        htmlFor="title"
                        className="col-md-6 col-form-label"
                      >
                        Unvan
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.title}
                          id="title"
                          name="title"
                          onChange={(e) =>
                            this.setState({
                              title: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        htmlFor="company_name"
                        className="col-md-6 col-form-label"
                      >
                        Firma İsmi
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.company_name}
                          id="company_name"
                          name="company_name"
                          onChange={(e) =>
                            this.setState({
                              company_name: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      {" "}
                      <Label
                        htmlFor="company_sector"
                        className="col-md-6 col-form-label"
                      >
                        Sektör
                      </Label>
                      {/*  <StyledLabel htmlFor="company_sector">
                        Sektör *
                      </StyledLabel> */}
                      <Col md={10}>
                        {" "}
                        <StyledSelect
                          id="company_sector"
                          classNamePrefix="select"
                          defaultValue={this.state.company_sector}
                          isSearchable={true}
                          name="company_sector"
                          placeholder=""
                          options={this.state.sectorOptions}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.title}
                          onChange={(option) =>
                            this.setState({
                              company_sector: option.title,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        htmlFor="company_business_line"
                        className="col-md-6 col-form-label"
                      >
                        Bölge
                      </Label>
                      <Col md={10}>
                        <StyledSelect
                          id="company_area"
                          classNamePrefix="select"
                          hasError={this.state.company_area_error}
                          defaultValue={this.state.company_area}
                          isSearchable={true}
                          name="company_area"
                          placeholder=""
                          options={this.state.regionOptions}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.title}
                          onChange={(option) =>
                            this.setState({
                              companty_area: option.title,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        color="primary"
                        className="w-md waves-effect waves-light"
                        type="submit"
                      >
                        {this.props.loading ? "Yükleniyor ..." : "Ara"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(MegaMenu);
