import api from "../../config/API";

const ListPosts = async (page) => {
  return api
    .get(`/posts?page=${page}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListPosts;
