import api from "../../config/API";

const CreateAssociation = async (payload) => {
  return api
    .post(`/associations`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default CreateAssociation;
