import React from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
const GMap = (props) => (
  <Map
    google={props.google}
    zoom={16}
    style={{
      width: "100%",
      height: "300px",
      position: "relative",
    }}
    containerStyle={{
      position: "relative",
      width: "100%",
      height: "100%",
    }}
    initialCenter={{
      lat: 41.031048,
      lng: 28.972246,
    }}
  >
    <Marker name={"Türkonfed"} />
  </Map>
);

export default GoogleApiWrapper((props) => ({
  apiKey: "AIzaSyC6BpAr7XJGRUGxb3KOw0M5xT8lCW_vL1w", //turkonfed api key
  language: "tr",
  v: "3",
}))(GMap);
