import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/tr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import img4 from "../../assets/images/companies/img-4.png";

//components
import EditFederationModal from "./EditFederationModal";

//api
import ShowFederation from "../../api/federations/ShowFederation";
import CreateFeedModal from "./CreateFeedModal";
import hasAccess from "../../utils/hasAccess";
import FederationPostList from "./FederationPostList";
import EditFederationAdmins from "./EditFederationAdmins";
import ShowConfederation from "../../api/federations/ShowConfederation";
import isSuperAdmin from "../../utils/isSuperAdmin";
import DetailAvatar from "../../components/Common/DetailAvatar";

const Details = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Türkonfed", link: "#" },
  ]);
  const [federation, setFederation] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateFeedModalOpen, setIsCreateFeedModalOpen] = useState(false);
  const [isEditAdminsModalOpen, setIsEditAdminsModalOpen] = useState(false);
  const [hasAdminPermissions, sethasAdminPermissions] = useState(false);
  useEffect(() => {
    ShowConfederation().then((r) => {
      setFederation(r.data);
      sethasAdminPermissions(hasAccess(r.data.admins));
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Türkonfed" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <DetailAvatar cover={federation.cover_link} />
                    <Media body>
                      <h5 className="text-truncate">{federation.title}</h5>
                    </Media>
                  </div>
                  {isSuperAdmin() && (
                    <>
                      <hr className="my-4" />
                      <Row className="text-center">
                        <Col lg={12}>
                          <Button
                            color="primary"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => setIsEditModalOpen(true)}
                          >
                            <i className="mdi mdi-pencil mr-2"></i>Düzenle
                          </Button>
                          {/*  <Button
                            color="primary"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => setIsEditAdminsModalOpen(true)}
                          >
                            <i className="mdi mdi-pencil mr-2"></i>
                            Federasyon Yetkilileri
                          </Button> */}
                          <Button
                            color="warning"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => setIsCreateFeedModalOpen(true)}
                          >
                            <i className="mdi mdi-bullhorn mr-2"></i>
                            Duyuru Yap
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  <hr className="my-4" />
                  <Row className="text-center">
                    <Col lg={12}>
                      <Label htmlFor="billing-name">Kuruluş Tarihi</Label>
                      <p className="card-title-desc">
                        {federation.foundation_date &&
                          moment(federation.foundation_date).format(
                            "DD.MM.YYYY"
                          )}
                        {}
                      </p>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col lg={6}>
                      <Label htmlFor="billing-name">
                        Yönetim Kurulu Başkanı
                      </Label>
                      <p className="card-title-desc">
                        {federation.president_name}
                      </p>
                    </Col>
                    <Col lg={6}>
                      <Label htmlFor="billing-name">Genel Sekreteri</Label>
                      <p className="card-title-desc">
                        {federation.secretary_name}
                      </p>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <Row className="text-center">
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Ülke</Label>
                      <p className="card-title-desc">
                        {federation &&
                          federation.country &&
                          federation.country.title}
                      </p>
                    </Col>

                    <Col lg={4}>
                      <Label htmlFor="billing-name">Şehir</Label>
                      <p className="card-title-desc">
                        {" "}
                        {federation && federation.city && federation.city.title}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <Label htmlFor="billing-name">İlçe</Label>
                      <p className="card-title-desc">
                        {federation &&
                          federation.district &&
                          federation.district.title}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4 text-center">
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Posta Kodu</Label>
                      <p className="card-title-desc">
                        {federation.postal_code}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <Label htmlFor="billing-name">Adres Bilgisi</Label>
                      <p className="card-title-desc">{federation.address}</p>
                    </Col>

                    <Col lg={4}>
                      <Label htmlFor="billing-name">Telefon</Label>
                      <p className="card-title-desc">{federation.phone}</p>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col lg={3}>
                      <Label htmlFor="billing-name">E-posta</Label>
                      <p className="card-title-desc">{federation.email}</p>
                    </Col>
                    <Col lg={3}>
                      <Label htmlFor="billing-name">Cep Telefonu</Label>
                      <p className="card-title-desc">
                        {federation.mobile_phone}
                      </p>
                    </Col>
                    <Col lg={3}>
                      <Label htmlFor="billing-name">Fax</Label>
                      <p className="card-title-desc">{federation.fax}</p>
                    </Col>
                    <Col lg={3}>
                      <Label htmlFor="billing-name">Web Adresi</Label>
                      <p className="card-title-desc">{federation.website}</p>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Breadcrumbs title="Duyurular" breadcrumbItems={[]} />
            </Col>
            <Col lg={12}>
              <FederationPostList federation_id={federation.id} />
            </Col>
          </Row>
        </Container>
      </div>

      <EditFederationModal
        data={federation}
        isOpen={isEditModalOpen}
        toggle={() => setIsEditModalOpen(!isEditModalOpen)}
      />
      <CreateFeedModal
        data={federation}
        isOpen={isCreateFeedModalOpen}
        toggle={() => setIsCreateFeedModalOpen(!isCreateFeedModalOpen)}
      />
      <EditFederationAdmins
        data={federation}
        isOpen={isEditAdminsModalOpen}
        toggle={() => setIsEditAdminsModalOpen(!isEditAdminsModalOpen)}
      />
    </React.Fragment>
  );
};

export default Details;
