import styled from "styled-components";
const GridRow = styled.div`
  width: 1200px;

  @media (max-width: 1200px) {
    width: 900px;
  }
  @media (max-width: 992px) {
    width: 750px;
  }
  @media (max-width: 768px) {
    width: 700px;
  }
`;
export default GridRow;
