import api from "../../config/API";

const ListFederations = async () => {
  return api
    .get(`/federations`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListFederations;
