const isSuperAdmin = () => {
  try {
    const superAdminRole = "admin";

    const user = JSON.parse(localStorage.getItem("authUser"));

    return user.role === superAdminRole;
  } catch (error) {
    return false;
  }
};

export default isSuperAdmin;
