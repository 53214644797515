import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  CardImg,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Dropzone
import Dropzone from "react-dropzone";

import Select from "react-select";

import UpdateAssociation from "../../api/associations/UpdateAssociation";
import useSearch from "../../hooks/useSearch";
import SearchCities from "../../api/search/SearchCities";
import uniqueMergeArrays from "../../utils/uniqueMergeArrays";
import customFilterForSelect from "../../utils/customFilterForSelect";
import SearchFederations from "../../api/search/SearchFederations";
import SearchDistricts from "../../api/search/SearchDistricts";

function EditAssociationModal({ data, isOpen, toggle, ...props }) {
  const [association, setAssociation] = useState(null);
  const [federationId, setFederationId] = useState(null);
  const [federationsOptions, setFederationsOptions] = useState("");
  const [title, setTitle] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [foundationDate, setFoundationDate] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [address, setAddress] = useState(null);
  const [district, setDistrict] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [fax, setFax] = useState(null);
  const [website, setWebsite] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [presidentName, setPresidentName] = useState(null);
  const [secretaryName, setSecretaryName] = useState(null);

  const [citiesOptions, setCitiesOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);

  const [companyCityError, setCompanyCityError] = useState(false);

  const [citySearching, setCitySearching] = useState(false);
  const [federationSearching, setFederationSearching] = useState(false);
  const [districtSearching, setDistrictSearching] = useState(false);

  const [searchText, setSearchText] = useState("");
  useSearch(searchText, () => citySearchWithDelay());

  const [searchFedText, setSearchFedText] = useState("");
  useSearch(searchFedText, () => cityFederationWithDelay());

  const [searchDistrictText, setSearchDistrictText] = useState("");
  useSearch(searchDistrictText, () => districtSearchWithDelay());

  const citySearch = (searchQuery) => {
    if (!searchQuery) return;
    setCitySearching(true);
    setSearchText(searchQuery);
  };

  const citySearchWithDelay = () => {
    SearchCities({
      title: searchText,
    }).then((r) => {
      setCitySearching(false);

      if (r.status !== "success") return;
      setCitiesOptions(uniqueMergeArrays(citiesOptions, r.data));
    });
  };

  const federationSearch = (searchQuery) => {
    if (!searchQuery) return;
    setFederationSearching(true);
    setSearchFedText(searchQuery);
  };

  const cityFederationWithDelay = () => {
    SearchFederations({
      title: searchFedText,
    }).then((r) => {
      setFederationSearching(false);

      if (r.status !== "success") return;
      setFederationsOptions(uniqueMergeArrays(federationsOptions, r.data));
    });
  };

  const districtSearch = (searchQuery) => {
    if (!searchQuery) return;
    setDistrictSearching(true);
    setSearchDistrictText(searchQuery);
  };

  const districtSearchWithDelay = () => {
    if (!searchDistrictText) return;
    SearchDistricts({
      title: searchDistrictText,
      city_id: cityId,
    }).then((r) => {
      setDistrictSearching(false);

      if (r.status !== "success") return;
      setDistrictOptions(uniqueMergeArrays(districtOptions, r.data));
    });
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedLogo(files[0]);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const transformUserToStates = (association) => {
    setAssociation(association);
    setFederationId(association.federation_id);
    setTitle(association.title);
    setCountryId(association.country_id);

    setCityId(association.city_id);
    if (association.city) {
      setCitiesOptions([association.city]);
      setDistrictOptions(association.city ? association.city.districts : []);
    }
    if (association.federation) setFederationsOptions([association.federation]);

    setDistrictId(association.district_id);
    if (association.district) {
      setDistrictOptions(
        uniqueMergeArrays(districtOptions, [association.district])
      );
    }

    setFoundationDate(association.foundation_date);
    setAddress(association.address);
    setPostalCode(association.postal_code);
    setEmail(association.email);
    setPhone(association.phone);
    setMobilePhone(association.mobile_phone);
    setFax(association.fax);
    setWebsite(association.website);
    setPresidentName(association.president_name);
    setSecretaryName(association.secretary_name);
  };

  const validation = () => {
    const response = { hasError: false };
    const errors = [];
    if (!federationId) errors.push("Federasyon");
    if (!title) errors.push("Dernek Adı");
    /*  if (!countryId) errors.push("Ülke");
    if (!cityId) errors.push("Şehir");
    if (!districtId) errors.push("İlçe");
    if (!postalCode) errors.push("Posta Kodu");
    if (!address) errors.push("Adres");
    if (!foundationDate) errors.push("Kuruluş Tarihi");
    if (!email) errors.push("E-posta");
    if (!phone) errors.push("Telefon");
    if (!mobilePhone) errors.push("Cep Telefonu");
    if (!fax) errors.push("Fax");
    if (!website) errors.push("Web Adresi");
    if (!presidentName) errors.push("Dernek Yönetim Kurulu Başkanı");
    if (!secretaryName) errors.push("Dernek Genel Sekreter"); */

    if (errors.length > 0) {
      response.hasError = true;
      alert("Lütfen, " + errors.join(", ") + " alan(lar)ını doldurunuz.");
    }

    return response;
  };

  const handleSubmit = (event, values) => {
    if (validation().hasError) {
      return;
    }
    const data = {
      id: association.id,
      federation_id: federationId,
      title: title,
      country_id: countryId,
      city_id: cityId,
      district_id: districtId,
      district: district,
      postal_code: postalCode,
      address: address,
      foundation_date: foundationDate,
      email: email,
      phone: phone,
      mobile_phone: mobilePhone,
      fax: fax,
      website: website,
      president_name: presidentName,
      secretary_name: secretaryName,
    };

    if (data) {
      const formData = new FormData();
      for (var key in data) {
        if (data[key]) formData.append(key, data[key]);
      }

      if (selectedLogo != null) {
        formData.append("cover_image", selectedLogo);
      }
      UpdateAssociation(formData, data.id).then((r) => {
        if (r.status === "success") {
          alert(r.message);
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      transformUserToStates(data);
    }
  }, [data]);

  return (
    <Modal
      unmountOnClose={true}
      isOpen={isOpen}
      toggle={toggle}
      centered={true}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Dernek Güncelle</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
          <Row>
            <Card style={{ width: "100%" }}>
              <CardBody>
                <FormGroup>
                  <Col>
                    <Label
                      htmlFor="federasyon"
                      className="col-md-12 col-form-label"
                    >
                      Federasyon
                    </Label>
                    <AvField
                      name="federation"
                      value={
                        federationId &&
                        federationsOptions &&
                        federationsOptions.filter(
                          (option) => option.id === federationId
                        )[0].title
                      }
                      type="text"
                      className="form-control"
                      id="federation"
                      placeholder=""
                      /* onChange={(e) => setTitle(e.target.value)} */
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="title" className="col-md-12 col-form-label">
                    Dernek Adı
                  </Label>
                  <Col md={12}>
                    <AvField
                      name="title"
                      value={title}
                      validate={{ required: true }}
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder=""
                      /* onChange={(e) => setTitle(e.target.value)} */
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col>
                    <Label htmlFor="city" className="col-md-12 col-form-label">
                      Şehir
                    </Label>
                    <Select
                      id="city_error"
                      classNamePrefix="select"
                      hasError={companyCityError}
                      value={
                        cityId &&
                        citiesOptions.filter((option) => option.id === cityId)
                      }
                      placeholder={"Aramak istediğiniz şehri giriniz"}
                      noOptionsMessage={() =>
                        "arama kriterlerinize uygun sonuç bulunamadı"
                      }
                      loadingMessage={() => "arama yapılıyor..."}
                      isSearchable={true}
                      isLoading={citySearching}
                      onInputChange={(e) => citySearch(e)}
                      filterOption={(option, searchText) =>
                        customFilterForSelect(option, searchText, "title")
                      }
                      name="city_error"
                      options={citiesOptions}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.title}
                      onChange={(option) => {
                        setCompanyCityError(false);
                        setCityId(option.id);
                        setCountryId(option.country_id);
                        setDistrictId(null);
                      }}
                    />
                  </Col>
                </FormGroup>
                {cityId && (
                  <FormGroup>
                    <Col>
                      <Label
                        htmlFor="district"
                        className="col-md-12 col-form-label"
                      >
                        İlçeler
                      </Label>
                      <Select
                        id="district_error"
                        classNamePrefix="select"
                        value={
                          districtId &&
                          districtOptions.filter(
                            (option) => option.id === districtId
                          )
                        }
                        placeholder={"Aramak istediğiniz ilçeyi giriniz"}
                        noOptionsMessage={() =>
                          "arama kriterlerinize uygun sonuç bulunamadı"
                        }
                        loadingMessage={() => "arama yapılıyor..."}
                        isSearchable={true}
                        isLoading={districtSearching}
                        onInputChange={(e) => districtSearch(e)}
                        filterOption={(option, searchText) =>
                          customFilterForSelect(option, searchText, "title")
                        }
                        name="district_error"
                        options={districtOptions}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.title}
                        onChange={(option) => {
                          setDistrictId(option.id);
                          //setCountryId(option.country_id);
                        }}
                      />
                    </Col>
                  </FormGroup>
                )}
                <Label htmlFor="district" className="col-md-12 col-form-label">
                  İlçe
                </Label>
                <Col md={12}>
                  <AvField
                    name="district"
                    value={district}
                    type="text"
                    className="form-control"
                    id="district"
                    placeholder=""
                    onChange={(e) => {
                      setDistrict(e.target.value);
                    }}
                  />
                </Col>
                <Label htmlFor="address" className="col-md-12 col-form-label">
                  Adres
                </Label>
                <Col md={12}>
                  <AvField
                    name="address"
                    value={address}
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder=""
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </Col>
                <Label
                  htmlFor="postal_code"
                  className="col-md-12 col-form-label"
                >
                  Posta Kodu
                </Label>
                <Col md={12}>
                  <AvField
                    name="postal_code"
                    value={postalCode}
                    type="text"
                    className="form-control"
                    id="postal_code"
                    placeholder=""
                    onChange={(e) => {
                      setPostalCode(e.target.value);
                    }}
                  />
                </Col>
                <FormGroup>
                  <Label
                    htmlFor="example-date-input"
                    className="col-md-12 col-form-label"
                  >
                    Kuruluş Tarihi
                  </Label>
                  <Col md={12}>
                    <Input
                      className="form-control"
                      type="date"
                      value={foundationDate}
                      id="example-date-input"
                      onChange={(e) => {
                        setFoundationDate(e.target.value);
                      }}
                    />
                  </Col>
                </FormGroup>
                <Label htmlFor="email" className="col-md-12 col-form-label">
                  E-posta
                </Label>
                <Col md={12}>
                  <AvField
                    name="email"
                    value={email}
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder=""
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Col>
                <Label htmlFor="phone" className="col-md-12 col-form-label">
                  Telefon
                </Label>
                <Col md={12}>
                  <AvField
                    name="phone"
                    value={phone}
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder=""
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </Col>
                <Label
                  htmlFor="mobile_phone"
                  className="col-md-12 col-form-label"
                >
                  Cep Telefonu
                </Label>
                <Col md={12}>
                  <AvField
                    name="mobile_phone"
                    value={mobilePhone}
                    type="text"
                    className="form-control"
                    id="mobile_phone"
                    placeholder=""
                    onChange={(e) => {
                      setMobilePhone(e.target.value);
                    }}
                  />
                </Col>
                <Label htmlFor="fax" className="col-md-12 col-form-label">
                  Fax
                </Label>
                <Col md={12}>
                  <AvField
                    name="fax"
                    value={fax}
                    type="text"
                    className="form-control"
                    id="fax"
                    placeholder=""
                    onChange={(e) => {
                      setFax(e.target.value);
                    }}
                  />
                </Col>
                <Label htmlFor="website" className="col-md-12 col-form-label">
                  Web Adresi
                </Label>
                <Col md={12}>
                  <AvField
                    name="website"
                    value={website}
                    type="text"
                    className="form-control"
                    id="website"
                    placeholder=""
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                  />
                </Col>
                <Label
                  htmlFor="president_name"
                  className="col-md-12 col-form-label"
                >
                  Dernek Yönetim Kurulu Başkanı
                </Label>
                <Col md={12}>
                  <AvField
                    name="president_name"
                    value={presidentName}
                    type="text"
                    className="form-control"
                    id="president_name"
                    placeholder=""
                    onChange={(e) => {
                      setPresidentName(e.target.value);
                    }}
                  />
                </Col>
                <Label
                  htmlFor="secretary_name"
                  className="col-md-12 col-form-label"
                >
                  Dernek Genel Sekreter
                </Label>
                <Col md={12}>
                  <AvField
                    name="secretary_name"
                    value={secretaryName}
                    type="text"
                    className="form-control"
                    id="secretary_name"
                    placeholder=""
                    onChange={(e) => {
                      setSecretaryName(e.target.value);
                    }}
                  />
                </Col>
                <Label
                  htmlFor="secretary_name"
                  className="col-md-12 col-form-label"
                >
                  Logo
                </Label>
                <Col md={12}>
                  <Dropzone
                    accept="image/*"
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFiles(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="dropzone"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="dz-message needsclick mt-2"
                          style={{ padding: "10px" }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                          </div>
                          <h4>
                            Görseli güncellemek için tıklayın veya sürükleyin
                          </h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {selectedLogo && (
                    <CardImg src={selectedLogo.preview} className="img-fluid" />
                  )}
                  {!selectedLogo && data && data.cover_link && (
                    <CardImg src={data.cover_link} className="img-fluid" />
                  )}
                </Col>
                <Col md={6}>
                  <Button
                    color="primary"
                    className=" waves-effect waves-light mr-1 mt-2"
                    type="submit"
                  >
                    Güncelle
                  </Button>{" "}
                </Col>{" "}
              </CardBody>
            </Card>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
export default EditAssociationModal;
