import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

//api
import ListOptions from "../../api/ListOptions";
import UserRegister from "../../api/UserRegister";

// Redux
import { connect } from "react-redux";
import history from "../../history";

//Images
import BiaLogo from "../../assets/images/bia-logo.png";
import ListOptionsWithParam from "../../api/ListOptionsWithParam";
import { scaleService } from "chart.js";

const CustomButton = styled(Button)`
  background: #5a2d91;
  border-color: #5a2d91;
  border-radius: 0;
  &:hover,
  :active {
    background: #5a2d91 !important;
    border-color: #5a2d91 !important;
  }
`;

const StyledSelect = styled(Select)`
  .select__control {
    height: 60px !important;
    border-color: ${(props) => (props.hasError ? "red" : "#ced4da")};
  }
  .select__value-container {
    margin-left: 60px;
    top: 10px;
    padding: 0;
  }
  .select__single-value {
    margin-left: 0;
  }
  .select__menu {
    z-index: 3;
  }
`;
const StyledLabel = styled(Label)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 14.4px;
`;

class Register3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      federation_name: null,
      association_name: null,
      federation_title_id: null,
      association_title_id: null,
      confirmed_kvkk: false,
      confirmed_kvkk_abroad: false,
      confirmed_privacy: false,

      confirmed_kvkk_error: false,
      confirmed_kvkk_abroad_error: false,
      confirmed_privacy_error: false,

      federationsOptions: [],
      associationsOptions: [],
      associationTitleOptions: [],
      federationTitleOptions: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    ListOptionsWithParam().then((r) => {
      if (r.status === "success") {
        this.setState({
          associationsOptions: r.data.associations,
          associationTitleOptions: r.data.associationTitles,
          federationTitleOptions: r.data.federationTitles,
        });
      }
    });
  }

  hasInputError() {
    let errors = 0;

    if (!this.state.association_name) {
      errors++;
      this.setState({
        association_name_error: true,
      });
    }
    if (!this.state.association_title_id) {
      errors++;
      this.setState({
        association_title_error: true,
      });
    }
    if (!this.state.confirmed_kvkk) {
      errors++;
      this.setState({
        confirmed_kvkk_error: true,
      });
    }
    if (!this.state.confirmed_kvkk_abroad) {
      errors++;
      this.setState({
        confirmed_kvkk_abroad_error: true,
      });
    }

    if (!this.state.confirmed_privacy) {
      errors++;
      this.setState({
        confirmed_privacy_error: true,
      });
    }

    return errors == 0;
  }
  handleSubmit(event, values) {
    if (!this.hasInputError()) {
      return;
    }

    this.setState({
      is_loading: true,
    });

    const data = {
      ...this.props.location.state,
      association_id: this.state.association_name,
      association_title_id: this.state.association_title_id,
      federation_title_id: this.state.federation_title_id,
      confirmed_kvkk: this.state.confirmed_kvkk ? 1 : 0,
      confirmed_kvkk_abroad: this.state.confirmed_kvkk_abroad ? 1 : 0,
      confirmed_privacy: this.state.confirmed_privacy ? 1 : 0,
    };
    UserRegister(data).then((r) => {
      alert(r.message);

      this.setState({
        is_loading: false,
      });
      if (r.status === "success") {
        history.push("/");
        window.location.reload();
      }
    });
  }

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100 h-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={BiaLogo} alt="bia" width="200" />
                              </Link>
                            </div>
                            <p className="text-muted">3/3</p>
                            <h4 className="font-size-18 mt-4">
                              Kullanıcı Kayıt Formu
                            </h4>
                            <p className="text-muted">
                              Federasyon/Dernek Bilgileri
                            </p>
                          </div>
                          <Row className="justify-content-center">
                            <Col lg={6}>
                              {this.props.user && (
                                <Alert color="success">
                                  Registration Done Successfully.
                                </Alert>
                              )}

                              {this.props.registrationError && (
                                <Alert color="danger">
                                  {this.props.registrationError}
                                </Alert>
                              )}
                            </Col>
                          </Row>

                          <div className="p-2 mt-5">
                            <AvForm
                              onValidSubmit={this.handleSubmit}
                              className="form-horizontal"
                            >
                              <Row className="justify-content-center">
                                <Col lg={6}>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="association_name">
                                      Bağlı Olduğu Dernek *
                                    </StyledLabel>
                                    <StyledSelect
                                      hasError={
                                        this.state.association_name_error
                                      }
                                      id="association_name"
                                      classNamePrefix="select"
                                      defaultValue={this.state.association_name}
                                      isSearchable={true}
                                      name="association_name"
                                      placeholder=""
                                      options={this.state.associationsOptions}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(option) =>
                                        this.setState({
                                          association_name_error: false,
                                          association_name: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="association_title">
                                      Dernekteki Görevi *
                                    </StyledLabel>
                                    <StyledSelect
                                      hasError={
                                        this.state.association_title_error
                                      }
                                      id="association_title"
                                      classNamePrefix="select"
                                      defaultValue={
                                        this.state.association_title_id
                                      }
                                      isSearchable={true}
                                      name="association_title"
                                      placeholder=""
                                      options={
                                        this.state.associationTitleOptions
                                      }
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(option) =>
                                        this.setState({
                                          association_title_error: false,
                                          association_title_id: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="federation_name">
                                      Bağlı Olduğu Federasyon
                                    </StyledLabel>
                                    <Input
                                      disabled
                                      value={
                                        this.state.association_name
                                          ? this.state.associationsOptions.filter(
                                              (a) =>
                                                a.id ==
                                                this.state.association_name
                                            )[0].federation.title
                                          : ""
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{ position: "relative" }}
                                  >
                                    {" "}
                                    <i
                                      className="ri-information-line auti-custom-input-icon"
                                      style={{ zIndex: 1 }}
                                    ></i>
                                    <StyledLabel htmlFor="federation_title">
                                      Federasyondaki Görevi 
                                    </StyledLabel>
                                    <StyledSelect
                                      id="federation_title"
                                      classNamePrefix="select"
                                      defaultValue={
                                        this.state.federation_title_id
                                      }
                                      isSearchable={true}
                                      name="federation_title"
                                      placeholder=""
                                      options={
                                        this.state.federationTitleOptions
                                      }
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(option) =>
                                        this.setState({
                                          federation_title_id: option.id,
                                        })
                                      }
                                    />
                                  </FormGroup>

                                  <FormGroup
                                    className="auth-form-group-custom"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <b
                                      style={{
                                        textAlign: "left",
                                        fontSize: "13px",
                                      }}
                                    >
                                      KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN RIZA
                                      BEYANI
                                    </b>
                                    <span style={{ textAlign: "justify" }}>
                                      Kişisel verilerinizin ve özel nitelikli
                                      kişisel verilerinizin Türk Girişim ve İş
                                      Dünyası Konfederasyonu{" "}
                                      <b>(“TÜRKONFED”)</b> tarafından
                                      işlenmesine ilişkin Aydınlatma Metni için
                                      lütfen{" "}
                                      <u>
                                        <b>
                                          {" "}
                                          <a
                                            href="aydinlatma-metni.pdf"
                                            target="_blank"
                                            referrerPolicy="no-referrer"
                                            style={{
                                              textDecoration: "none",
                                              color: "inherit",
                                            }}
                                          >
                                            tıklayınız
                                          </a>
                                        </b>
                                      </u>
                                      .
                                    </span>
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      border: this.state.confirmed_kvkk_error
                                        ? "1px solid red"
                                        : "",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={this.state.confirmed_kvkk}
                                      onChange={() =>
                                        this.setState({
                                          confirmed_kvkk:
                                            !this.state.confirmed_kvkk,
                                          confirmed_kvkk_error: false,
                                        })
                                      }
                                      style={{
                                        marginTop: "4px",
                                        transform: "scale(1.4)",
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                      (*) Özel nitelikli kişisel verilerimin
                                      Aydınlatma Metni’nde belirtilen amaçlarla
                                      ve kapsamda işlenmesine rıza veriyorum.
                                    </span>
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      border: this.state
                                        .confirmed_kvkk_abroad_error
                                        ? "1px solid red"
                                        : "",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={this.state.confirmed_kvkk_abroad}
                                      onChange={() =>
                                        this.setState({
                                          confirmed_kvkk_abroad:
                                            !this.state.confirmed_kvkk_abroad,
                                          confirmed_kvkk_abroad_error: false,
                                        })
                                      }
                                      style={{
                                        marginTop: "4px",
                                        transform: "scale(1.4)",
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                      (*) Kişisel verilerimin ve özel nitelikli
                                      kişisel verilerimin Aydınlatma Metni’nde
                                      belirtilen amaçlarla ve kapsamda yurt
                                      dışına aktarılmasına rıza veriyorum.
                                    </span>
                                  </FormGroup>
                                  <FormGroup
                                    className="auth-form-group-custom mb-4"
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      border: this.state
                                        .confirmed_kvkk_abroad_error
                                        ? "1px solid red"
                                        : "",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={this.state.confirmed_privacy}
                                      onChange={() =>
                                        this.setState({
                                          confirmed_privacy:
                                            !this.state.confirmed_privacy,
                                          confirmed_kvkk_abroad_error: false,
                                        })
                                      }
                                      style={{
                                        marginTop: "4px",
                                        transform: "scale(1.4)",
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                      (*){" "}
                                      <u>
                                        <b>
                                          {" "}
                                          <a
                                            href="platform_kullanim_sartlari_ve_gizlilik.pdf"
                                            target="_blank"
                                            referrerPolicy="no-referrer"
                                            style={{
                                              textDecoration: "none",
                                              color: "inherit",
                                            }}
                                          >
                                            Bölgesel İş Ağları Platformu
                                            Kullanım Koşulları ve Gizlilik
                                            Politikası
                                          </a>
                                        </b>
                                      </u>{" "}
                                      şartlarını kabul ediyorum
                                    </span>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <div className="text-center">
                                <CustomButton
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                  disabled={this.state.is_loading}
                                >
                                  {this.state.is_loading
                                    ? "Kaydediliyor ..."
                                    : "Kaydet"}
                                </CustomButton>
                              </div>

                              <div className="mt-4 text-center">
                                {/*  <p className="mb-0">
                                  By registering you agree to the Nazox{" "}
                                  <Link to="#" className="text-primary">
                                    Terms of Use
                                  </Link>
                                </p> */}
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              Zaten bir hesabınız var mı?{" "}
                              <Link
                                to="/giris"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Giriş Yap
                              </Link>{" "}
                            </p>
                            {/*    <p>
                              © 2020 Nazox. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger"></i> by
                              Themesdesign
                            </p> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/*  <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register3);
