import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_USER } from "./actionTypes";
import { forgetUserSuccessful, userForgetPasswordError } from "./actions";
import forgetPassword from "../../../api/user/forgottenPasswordRequest";

//If user is login then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(forgetPassword, {
      email: user.email,
    });

    if (response.status === "success") {
      yield put(forgetUserSuccessful(response.message));
    } else {
      yield put(userForgetPasswordError(response.message));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserForget() {
  yield takeEvery(FORGET_USER, forgetUser);
}

function* forgetSaga() {
  yield all([fork(watchUserForget)]);
}

export default forgetSaga;
