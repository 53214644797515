import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 150px;
  height: 150px;
`;
const StyledAvatar = styled.img`
  width: auto !important;
  height: auto !important;
  max-width: 150px;
  max-height: 150px;
`;

const DetailAvatar = ({ cover }) => (
  <Wrapper className="avatar-sm mt-2 mb-4">
    <StyledAvatar src={cover} alt="" className="" />
  </Wrapper>
);

export default DetailAvatar;
