import api from "../../config/API";

const DeletePostFile = async (id = 0) => {
  return api
    .post(`/deletePostFile/${id}`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default DeletePostFile;
