import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from "./actionTypes";
import { apiError, loginUserSuccessful, logoutUserSuccess } from "./actions";
import { updateUser } from "../../global/actions";

// AUTH related methods
import loginRequest from "../../../api/user/loginUserRequest";
import { setToken } from "../../../config/API";

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginRequest, user);
    if (response.status === "error") {
      alert(response.message);
    }
    if (response.status === "success") {
      localStorage.setItem("authUser", JSON.stringify(response.data));
      localStorage.setItem("token", response.access_token);
      yield call(setToken, response.access_token);
      yield put(loginUserSuccessful(response));
      yield put(updateUser(response.data));
      history.push("/platform");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    yield put(logoutUserSuccess(""));

    history.push("/giris");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default loginSaga;
