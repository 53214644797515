import React, { Component, useState } from "react";
import styled, { keyframes } from "styled-components";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";

import { Link } from "react-router-dom";
// Import menuDropdown
import ProfileMenu from "../../components/CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

import BiaLogo from "../../assets/images/bia-logo.png";
import isLoggedIn from "../../utils/isLoggedIn";

const PageTopbar = styled.header`
  border-bottom: 5px solid #5a2d91;
  box-shadow: unset !important;
`;

const NavberHeader = styled.div`
  height: 120px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  @media (max-width: 1200px) {
    max-width: 900px;
  }
  @media (max-width: 992px) {
    max-width: 750px;
  }
  @media (max-width: 768px) {
    max-width: 700px;
  }
  @media (max-width: 425px) {
  }
`;

const Logo = styled.img`
  top: 8px;
  position: relative;
  @media (max-width: 768px) {
    margin-left: 20px;
  }
`;

const MenuItem = styled.a`
  color: #5a2d91;
  font-weight: bold;
`;

const BurgerMenuButton = styled(MenuItem)`
  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const burgerAnimation = keyframes`
  from {
    left:100vw;
  }

  to {
    left:0vw;
  }
`;
const BurgerMenu = styled.div`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  position: fixed;
  left: 100vw;
  top: 0;
  background-color: white !important;
  height: 100%;
  width: 100vw;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
  overflow: auto;
  z-index: 10;
  animation: ${burgerAnimation} 0.2s linear forwards;
`;

const BurgerMenuItem = styled.a`
  color: #5a2d91;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const HeaderLanding = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  return (
    <React.Fragment>
      <PageTopbar id="page-topbar">
        <NavberHeader className="navbar-header">
          <Logo src={BiaLogo} alt="" width="150" />

          <MenuItem className="d-flex hideOnMobile" href="#">
            Ana Sayfa
          </MenuItem>
          <MenuItem className="d-flex hideOnMobile" href="#federations">
            Federasyonlar
          </MenuItem>
          <MenuItem className="d-flex hideOnMobile" href="#bia">
            BİA
          </MenuItem>
          <MenuItem className="d-flex hideOnMobile" href="#news">
            Haberler
          </MenuItem>
          <MenuItem className="d-flex hideOnMobile" href="#contact">
            İletişim
          </MenuItem>
          <div className="d-flex hideOnMobile">
            {isLoggedIn() ? (
              <>
                <Link to="/platform" className="waves-effect">
                  <Button color="primary">Platforma git</Button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/kayit" className="waves-effect">
                  <Button className="btn-outline-primary">Kayıt Ol</Button>
                </Link>
                &nbsp;{" "}
                <Link to="/giris" className="waves-effect">
                  <Button color="primary">Giriş</Button>
                </Link>
              </>
            )}
          </div>
          <BurgerMenuButton className="d-flex hideOnDesktop">
            <Button onClick={() => setIsMenuActive(!isMenuActive)}>
              <i className="ri-menu-fill font-size-20"></i>
            </Button>
          </BurgerMenuButton>
        </NavberHeader>
      </PageTopbar>
      <BurgerMenu isActive={isMenuActive}>
        <BurgerMenuItem
          className="d-flex"
          href="#"
          onClick={() => setIsMenuActive(false)}
        >
          Ana Sayfa
        </BurgerMenuItem>
        <BurgerMenuItem
          className="d-flex"
          href="#federations"
          onClick={() => setIsMenuActive(false)}
        >
          Federasyonlar
        </BurgerMenuItem>
        <BurgerMenuItem
          className="d-flex"
          href="#bia"
          onClick={() => setIsMenuActive(false)}
        >
          BİA
        </BurgerMenuItem>
        <BurgerMenuItem
          className="d-flex"
          href="#news"
          onClick={() => setIsMenuActive(false)}
        >
          Haberler
        </BurgerMenuItem>
        <BurgerMenuItem
          className="d-flex"
          href="#contact"
          onClick={() => setIsMenuActive(false)}
        >
          İletişim
        </BurgerMenuItem>
        <div className="d-flex" style={{ padding: "20px 0" }}>
          {isLoggedIn() ? (
            <>
              <Link to="/platform" className="waves-effect">
                <Button color="primary">Platforma git</Button>
              </Link>
            </>
          ) : (
            <>
              <Link to="/kayit" className="waves-effect">
                <Button className="btn-outline-primary">Kayıt Ol</Button>
              </Link>
              &nbsp;{" "}
              <Link to="/giris" className="waves-effect">
                <Button color="primary">Giriş</Button>
              </Link>
            </>
          )}
        </div>
      </BurgerMenu>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withNamespaces()(HeaderLanding)
);
