// @flow
import { UPDATE_DATA, UPDATE_USER } from "./actionTypes";

const INIT_STATE = {
  titles: [],
  universities: [],
  departments: [],
  demand_types: [],
  document_types: [],
  user: {},
};

const Initial = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
      };
      break;

    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
      break;
    default:
      return state;
  }
};

export default Initial;
