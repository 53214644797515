import React, { useState, useEffect } from "react";
import HeaderLanding from "./HeaderLanding";
import {
  Container,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Media,
  Label,
  Button,
} from "reactstrap";
import moment from "moment";
import "moment/locale/tr";
import "./landing.scss";

import ABLogo from "../../assets/images/ab_tr_isbirligi_logo.png";
import CFCULogo from "../../assets/images/cfcu.png";
import TurkonfedLogo from "../../assets/images/turkonfed.png";
import SivilToplumLogo from "../../assets/images/sivil-toplum-logo.png";
import ABBaskanlikLogo from "../../assets/images/avrupa_birligi_baskanligi_logo_tr.png";
import img2 from "../../assets/images/small/img-2.jpg";
import InteractiveMap from "./InteractiveMap";
import MainContent from "./MainContent";
import BigLogoWrapper from "./BigLogoWrapper";
import BigLogo from "./BigLogo";
import LogoRow from "./LogoRow";
import CardDetail from "./CardDetail";
import SpecialCard from "./SpecialCard";
import GridRow from "./GridRow";
import SpecialSection2 from "./SpecialSection2";
import SpecialSection from "./SpecialSection";
import InfoDetail from "./InfoDetail";
import SmallLogo from "./SmallLogo";
import SmallLogoWrapper from "./SmallLogoWrapper";
import GetLandingPageData from "../../api/landingPage/GetLandingPageData";
import FederationList from "./FederationList";
import Footer from "./Footer";
import NewsList from "../../components/Common/NewsList";

const Landing = () => {
  const [showFederationList, setShowFederationList] = useState("regional");
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    GetLandingPageData().then((r) => {
      if (r.status != "success") {
        alert(r.message);
      }
      setRegions(r.data.regions);
    });
  }, []);

  return (
    <React.Fragment>
      {/* <div id="preloader">
        <div id="status">
          <div className="spinner">
            <i className="ri-loader-line spin-icon"></i>
          </div>
        </div>
      </div> */}

      <div id="layout-wrapper">
        <HeaderLanding />
        <MainContent>
          <SpecialSection2>
            <GridRow>
              <Container fluid>
                <Row className="justify-content-center mt-5">
                  <BigLogoWrapper>
                    <BigLogo src={ABLogo} alt="" />
                  </BigLogoWrapper>
                </Row>
                <LogoRow>
                  <a
                    href="https://www.cfcu.gov.tr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SmallLogoWrapper>
                      <SmallLogo src={CFCULogo} alt="" />
                    </SmallLogoWrapper>
                  </a>
                  <a
                    href="https://turkonfed.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SmallLogoWrapper>
                      <SmallLogo src={TurkonfedLogo} alt="" />
                    </SmallLogoWrapper>
                  </a>
                  <a
                    href="http://siviltoplumsektoru.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SmallLogoWrapper>
                      <SmallLogo src={SivilToplumLogo} alt="" />
                    </SmallLogoWrapper>
                  </a>
                  <a
                    href="https://www.ab.gov.tr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SmallLogoWrapper>
                      <SmallLogo src={ABBaskanlikLogo} alt="" />
                    </SmallLogoWrapper>
                  </a>
                </LogoRow>
              </Container>
            </GridRow>
          </SpecialSection2>
          <SpecialSection id="federations" style={{ overflowY: "scroll" }}>
            <GridRow style={{ overflowY: "scroll" }}>
              <Container fluid>
                <Row
                  className="justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <Button
                    color="secondary"
                    onClick={() => setShowFederationList("regional")}
                  >
                    Bölgesel Federasyonlar
                  </Button>
                  &nbsp;
                  <Button
                    color="primary"
                    onClick={() => setShowFederationList("sectoral")}
                  >
                    Sektörel Federasyonlar
                  </Button>
                </Row>
                {showFederationList === "regional" && (
                  <Row
                    style={{
                      justifyContent: "center",
                      color: "#5a2d91",
                      fontSize: "40px",
                      fontWeight: "600",
                    }}
                  >
                    <InteractiveMap regionList={regions} />
                  </Row>
                )}
                {showFederationList === "sectoral" && <FederationList />}
              </Container>
            </GridRow>
          </SpecialSection>
          <SpecialSection2 id="bia">
            <GridRow>
              <Container
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <Row
                  style={{
                    justifyContent: "center",
                    color: "#5a2d91",
                    fontSize: "40px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  <Col>Bölgesel İş Ağları Nedir?</Col>
                </Row>
                <Row>
                  <Col md={3} sm={6}>
                    <div className="mt-4 maintenance-box">
                      <div className="p-3">
                        <div className="avatar-sm mx-auto">
                          <span className="avatar-title bg-soft-primary rounded-circle">
                            <i className="mdi mdi-account-multiple-check font-size-24 text-primary"></i>
                          </span>
                        </div>
                        <InfoDetail className="text-muted mb-0 text-center">
                          Federasyonlar, Dernekler ve <br />
                          Üyeleri arasında iletişimi artırır
                        </InfoDetail>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                    <div className="mt-4 maintenance-box">
                      <div className="p-3">
                        <div className="avatar-sm mx-auto">
                          <span className="avatar-title bg-soft-primary rounded-circle">
                            <i className="mdi mdi-swap-vertical-variant font-size-24 text-primary"></i>
                          </span>
                        </div>
                        <InfoDetail className="text-muted mb-0 text-center">
                          İş ağlarını güçlendirerek
                          <br /> potansiyel iş birlikleri yaratır
                        </InfoDetail>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                    <div className="mt-4 maintenance-box">
                      <div className="p-3">
                        <div className="avatar-sm mx-auto">
                          <span className="avatar-title bg-soft-primary rounded-circle">
                            <i className="mdi mdi-cloud-search-outline font-size-24 text-primary"></i>
                          </span>
                        </div>
                        <InfoDetail className="text-muted mb-0 text-center">
                          Platform içi arama yapma
                          <br /> fonksiyonu ile <br />
                          erişilebilirlik sağlar
                        </InfoDetail>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                    <div className="mt-4 maintenance-box">
                      <div className="p-3">
                        <div className="avatar-sm mx-auto">
                          <span className="avatar-title bg-soft-primary rounded-circle">
                            <i className="mdi mdi-notebook-multiple font-size-24 text-primary"></i>
                          </span>
                        </div>
                        <InfoDetail className="text-muted mb-0 text-center">
                          Eğitim modülü ile kurumsal
                          <br /> kapasite artırımına destek olur
                        </InfoDetail>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </GridRow>
          </SpecialSection2>
          <SpecialSection>
            <GridRow>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col md={10}>
                    <Row
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        color: "#5a2d91",
                        fontSize: "40px",
                        fontWeight: "600",
                        marginBottom: "20px",
                      }}
                    >
                      <Col>Bölgesel İş Ağları Nasıl Çalışır?</Col>
                    </Row>
                    <Row
                      style={{
                        justifyContent: "center",
                        color: "#5a2d91",
                      }}
                    >
                      <Col xl={10}>
                        <div className="timeline" dir="ltr">
                          <div className="timeline-item">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    1
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Ana sayfadaki “Kayıt Ol” butonundan üyelik
                                      sürecine başlanır
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                          <div className="timeline-item timeline-left">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    2
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Temel profil bilgileri girilerek üyelik
                                      talebi oluşturulur ve talep alındığına
                                      dair bir bilgi kullanıcı e-mail adresine
                                      iletilir
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    3
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Üyelik talebi sistem yöneticisi tarafından
                                      onaylandıktan sonra kullanıcının
                                      belirlediği bilgiler ile sisteme giriş
                                      yapılabilir
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                          <div className="timeline-item timeline-left">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    4
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Kurumsal ve bireysel profiller istenildiği
                                      gibi düzenlenip detaylandırılabilir
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    5
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Platform üzerinden bölgesel, kurumsal,
                                      bireysel ve sektörel filtreleme ve arama
                                      yapılarak iş ağları geliştirmeye imkan
                                      sağlanır
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                          <div className="timeline-item timeline-left">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    6
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Bireysel kullanıcıların sektörel veya
                                      bölgesel diğer sivil toplum kuruluşları
                                      hakkında bilgi edinmesini sağlayarak bir
                                      iletişim kanalı oluşturur
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    7
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Kurumlar tarafından yapılan duyuruları tüm
                                      üyeler platformun ana sayfasından takip
                                      edebilirler
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                          <div className="timeline-item timeline-left">
                            <div className="timeline-block">
                              <SpecialCard className="timeline-box">
                                <CardBody>
                                  <span className="timeline-icon"></span>
                                  <div className="timeline-date">
                                    <i className="mdi mdi-circle-medium circle-dot"></i>
                                    8
                                  </div>
                                  <div className="text-muted mt-3 ">
                                    <CardDetail className="mb-0">
                                      Platformda yayınlanan eğitim videoları ile
                                      kurumsal yetkinliği artırmak hedeflenir
                                    </CardDetail>
                                  </div>
                                </CardBody>
                              </SpecialCard>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </GridRow>
          </SpecialSection>
          <SpecialSection2 id="news">
            <GridRow>
              <Container
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <Row
                  style={{
                    justifyContent: "center",
                    color: "#5a2d91",
                    fontSize: "40px",
                    fontWeight: "600",
                    marginBottom: "30px",
                  }}
                >
                  Haberler
                </Row>
                <NewsList />
              </Container>
            </GridRow>
          </SpecialSection2>{" "}
          <SpecialSection id="contact">
            <GridRow>
              <Container fluid>
                <Row
                  style={{
                    justifyContent: "center",
                    color: "#5a2d91",
                    fontSize: "40px",
                    fontWeight: "600",
                    marginBottom: "30px",
                  }}
                >
                  İletişim Bilgileri
                </Row>
                <Row className="justify-content-center">
                  <Col>
                    <Row
                      style={{
                        justifyContent: "center",
                        color: "#5a2d91",
                      }}
                    >
                      <Footer />
                    </Row>
                  </Col>
                </Row>
              </Container>
            </GridRow>
          </SpecialSection>
        </MainContent>
      </div>
    </React.Fragment>
  );
};

export default Landing;
