import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import UpdateSector from "../../../api/sectors/UpdateSector";

function ViewSector({ data, isOpen, toggle, ...props }) {
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState(1);

  const handleSubmit = (event, values) => {
    if (values) {
      UpdateSector({ id: data.id, title, order }).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (data && data.title) {
      setTitle(data.title);
    }
    if (data && data.order) {
      setOrder(data.order);
    }
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Sektör Güncelle</ModalHeader>
      <ModalBody>
        <Col md="12">
          <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
            <FormGroup>
              <Label
                htmlFor="example-text-input"
                className="col-md-12 col-form-label"
              >
                Sektör
              </Label>
              <Col md={12}>
                <AvField
                  name="title"
                  value={title}
                  validate={{ required: true }}
                  type="text"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value.trim())}
                />
              </Col>
              <Label
                htmlFor="example-text-input"
                className="col-md-12 col-form-label"
              >
                Sıra
              </Label>
              <Col md={12}>
                <AvField
                  name="order"
                  value={order}
                  type="text"
                  className="form-control"
                  onChange={(e) => setOrder(e.target.value.trim())}
                />
              </Col>
              <Col md={6}>
                <Button
                  color="primary"
                  className=" waves-effect waves-light mr-1 mt-1"
                  type="submit"
                >
                  Güncelle
                </Button>{" "}
              </Col>{" "}
            </FormGroup>
          </AvForm>
        </Col>
      </ModalBody>
    </Modal>
  );
}
export default ViewSector;
