import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Alert,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import ViewPost from "./ViewPost";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

//api
import ConfirmPendingPost from "../../api/posts/ConfirmPendingPost";
import DeleteAdminUser from "../../api/adminUser/DeleteAdminUser";
import ListPendingUserPosts from "../../api/adminUser/ListPendingUserPosts";
import DeletePost from "../../api/posts/DeletePost";



// Redux
import { connect } from "react-redux";

import ConfirmationMenu from "../../components/VerticalLayout/ConfirmationMenu";
import ViewUser from "../users/ViewUser";

class PostConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Yetkilendirme", link: "#" },
        { title: "Onay Bekleyen Bireysel Duyurular", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      posts: [],
      ViewData: {},
      waiting_email_users: [],
      isViewOpen: false,
      viewed: {},
    };
    this.handleConfirmation.bind(this);
    this.handleDelete.bind(this);


    ListPendingUserPosts().then((r) => {
      if (r.status === "success") {
        console.log(r);
        this.setState({
          posts: r.data.data,
        });
      }
    });
  }

  handleConfirmation = (id, confirmation) => {
    const answer = window.confirm(
      confirmation == 1
        ? "Seçilen duyurunun, Bireysel Duyurular sayfasında herkese görünür olmasını onaylıyor musunuz?"
        : "Duyuru kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );

    if (!answer) {
      return;
    }

    if (id) {
      ConfirmPendingPost(id).then(
        (r) => {
          alert(r.message);
          if (r.status === "success") {
            window.location.reload();
          }
        }
      );
    }
  };

  handleViewOpen = (row) => {
    this.setState({
      isViewOpen: true,
    });
  };

  handleDelete = (id) => {
    const confirmation = window.confirm(
      "Duyuru kalıcı olarak silinecektir. Onaylıyor musunuz?"
    );
    if (!confirmation) return;

    DeletePost(id).then((r) => {
      alert(r.message);
      if (r.status === "success") {
        window.location.reload();
      }
    });
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="ONAY BEKLEYEN BİREYSEL DUYURULAR"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive mt-3">
                      <Table
                        className="table-centered datatable dt-responsive nowrap "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "137px" }}>İşlemler</th>
                            <th>Başlık</th>
                            <th>İsim Soyisim</th>
                            <th>Ünvan</th>
                            <th>Firma</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.posts.map(
                            (customerData, key) => (
                              <tr key={key}>
                                <td style={{}}>
                                  <Button
                                    id={"view" + key}
                                    color="warning"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleViewOpen(this.setState({ViewData: customerData}))
                                    }
                                  >
                                    <i className="ri-eye-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"view" + key}
                                    placement="top"
                                  >
                                    Görüntüle
                                  </UncontrolledTooltip>
                                  <Button
                                    id={"confirm1" + key}
                                    color="success"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleConfirmation(customerData.id, 1)
                                    }
                                  >
                                    <i className=" ri-check-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"confirm1" + key}
                                    placement="top"
                                  >
                                    Onayla
                                  </UncontrolledTooltip>
                                  <Button
                                    id={"reject" + key}
                                    color="primary"
                                    type="button"
                                    className="waves-effect waves-light mr-1 mb-1 mt-1"
                                    size="sm"
                                    onClick={() =>
                                      this.handleDelete(customerData.id)
                                    }
                                  >
                                    <i className="ri-close-line align-middle"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    target={"reject" + key}
                                    placement="top"
                                  >
                                    Reddet ve Duyuruyu Sil
                                  </UncontrolledTooltip>
                                </td>

                                <td>{customerData.title || ""}</td>
                                <td>{customerData.author.name || ""}</td>
                                <td>{customerData.author.title || ""}</td>
                                <td>{customerData.author.company_name || ""}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ViewPost
        data={this.state.ViewData}
        isOpen={this.state.isViewOpen}
        toggle={() => this.setState({isViewOpen: !this.state.isViewOpen})}
        isReadOnly={true}
      />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(PostConfirmation);
