import api from "../../config/API";

const CreateTitle = async (payload) => {
  return api
    .post(`/titles`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default CreateTitle;
