import React, { useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import * as ReactGA from "react-ga";

import ANALYTICS_KEY from "../../config/ANALYTICS_KEY";
const CookiePermissions = () => {
  const initGa = () => {
    ReactGA.initialize(ANALYTICS_KEY);
  };

  useEffect(() => {
    if (getCookieConsentValue()) {
      initGa();
    }
  }, []);

  const handleAcceptCookie = () => {
    initGa();
  };

  /*   const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
 */
  return (
    <CookieConsent
      onAccept={handleAcceptCookie}
      declineButtonText="Reddet"
      buttonText="Kabul Et"
      style={{ zIndex: 999999 }}
      buttonStyle={{
        background: "#f79325",
        color: "#ffffff",
      }}
    >
      <p>
        İnternet sitemizde, sitenin çalışmasını sağlamak, analizler yapmak,
        sitemizin performansıyla ilgili bilgi toplamak için bir takım çerezler
        kullanılmaktadır. İnternet sitemizde kullandığımız çerezler hakkında
        detaylı bilgi almak için {" "}
        <u>
          <b>
            <a
              href="cerez-politikasi.pdf"
              target="_blank"
              referrerPolicy="no-referrer"
              style={{ textDecoration: "none", color: "#ffffff" }}
            >
              Çerez Politikamızı
            </a>
          </b>
        </u>
         inceleyebilirsiniz. Kabul Et butonunu tıklamanız halinde, internet
        sitemizdeki tüm çerez kullanımlarını kabul etmiş olursunuz.
      </p>
    </CookieConsent>
  );
};
export default CookiePermissions;
