import api from "../config/API";

const ListOptionsWithParam = async (payload) => {
  return api
    .get(`/getOptionList?includeFedSpecs=1`)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default ListOptionsWithParam;
