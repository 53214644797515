import api from "../../config/API";

const UpdateSector = async ({ id, ...payload }) => {
  return api
    .patch(`/sectors/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default UpdateSector;
