import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;
const StyledAvatar = styled.img`
  width: auto !important;
  height: auto !important;
  max-width: 50px;
  max-height: 50px;
`;

const PostAvatar = ({ cover, ...props }) => (
  <Wrapper className="d-flex mr-3 avatar-sm">
    <StyledAvatar src={cover} {...props} alt="" />
  </Wrapper>
);

export default PostAvatar;
