import React, { Component } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

//Images
import BiaLogo from "../../assets/images/bia-logo.png";

const CustomButton = styled(Button)`
  background: #5a2d91;
  border-color: #5a2d91;
  border-radius: 0;
  &:hover,
  :active {
    background: #5a2d91 !important;
    border-color: #5a2d91 !important;
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    this.props.checkLogin(values, this.props.history);
  }

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={3}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={BiaLogo} alt="bia" width="300" />
                              </Link>
                            </div>

                            {/*  <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to continue to Nazox.
                            </p> */}
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <Col lg={12}>
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="email">E-posta</Label>
                                  <AvField
                                    name="email"
                                    value={this.state.email}
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    validate={{ email: true, required: true }}
                                    placeholder=""
                                  />
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="password">Şifre</Label>
                                  <AvField
                                    name="password"
                                    value={this.state.password}
                                    validate={{ required: true }}
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder=""
                                  />
                                </FormGroup>

                                {/*  <div className="custom-control custom-checkbox">
                                <Input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customControlInline"
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </Label>
                              </div> */}
                              </Col>

                              <div className="mt-4 text-center">
                                <CustomButton
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Giriş Yap
                                </CustomButton>
                              </div>

                              <div className="mt-4 text-center">
                                <Link
                                  to="/sifremi-unuttum"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Şifreni
                                  mi unuttun?
                                </Link>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              <Link
                                to="/kayit"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Kayıt Ol{" "}
                              </Link>{" "}
                            </p>
                            {/*  <p>
                              © 2020 Nazox. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger"></i> by
                              Themesdesign
                            </p> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/*    <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
