import React, { useEffect, useState } from "react";
import ListUserPosts from "../../api/posts/ListUserPosts";
import FeedPost from "../../components/Common/FeedPost";
import { Col, Row, Button, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const UserPostList = ({ user_id }) => {
  const [posts, setPosts] = useState([]);
  const [currentPostPage, setCurrentPostPage] = useState(1);
  const [lastPostPage, setLastPostPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const loadPost = (nextPage = 1, fed_id = null) => {
    setIsLoading(true);
    ListUserPosts(nextPage, { id: user_id }).then(
      (r) => {
        setIsLoading(false);
        if (r.status != "success") {
          alert(r.message);
          return;
        }
        setPosts([...posts, ...r.data.data]);
        setCurrentPostPage(r.data.current_page);
        setLastPostPage(r.data.last_page);
      }
    );
  };
  useEffect(() => {
    if (!user_id) return;
    loadPost(currentPostPage, user_id);
  }, [user_id]);
  return (
    <>
    {posts && posts.length > 0 && (
        <Col lg={12}>
    
       <Breadcrumbs title="Bireysel Duyurular" breadcrumbItems={[]} />
       
       </Col>)}
      {posts && posts.map((post, index) => (
      <>
     
      <FeedPost key={index} {...post} />
      </>
      ))}
      {isLoading && (
        <Row className="justify-content-center mb-3">
          <Spinner className="font-size-20" color="primary" />
        </Row>
      )}
      {currentPostPage != lastPostPage && (
        <Row className="justify-content-center mb-4">
          <Button onClick={() => loadPost(currentPostPage + 1, user_id)}>
            daha fazla
          </Button>
        </Row>
      )}
    </>
  );
};

export default UserPostList;
