import styled from "styled-components";
const MainContent = styled.div`
  margin: 120px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default MainContent;
