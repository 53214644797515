import api from "../../config/API";

const UpdateCountry = async ({ id, ...payload }) => {
  return api
    .patch(`/countries/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => new Error(error.getMessage()));
};

export default UpdateCountry;
