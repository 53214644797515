import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";

//api
import CreateEducation from "../../api/education/CreateEducation";

function CreateEducationModal({ isOpen, toggle, ...props }) {
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [weblink, setWeblink] = useState(null);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles(files);
  };
  const handleAcceptedThumbnail = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setSelectedThumbnail(files[0]);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const validation = () => {
    const response = { hasError: false };
    const errors = [];
    if (!title) errors.push("Başlık");
    if (!content) errors.push("İçerik");

    if (errors.length > 0) {
      response.hasError = true;
      alert("Lütfen, " + errors.join(", ") + " alan(lar)ını doldurunuz.");
    }

    return response;
  };

  const handleSubmit = (event, values) => {
    if (validation().hasError) {
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);

    if (weblink) {
      formData.append("weblink", weblink);
    }
    if (selectedThumbnail) {
      formData.append("thumbnail", selectedThumbnail);
    }
    //mapping filecontents
    selectedFiles.map((file) => {
      formData.append("file_contents[]", file);
    });

    if (formData) {
      CreateEducation(formData).then((r) => {
        alert(r.message);
        if (r.status === "success") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <Modal
      unmountOnClose={true}
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
    >
      <ModalHeader toggle={toggle}>TÜRKONFED Eğitimi Oluştur</ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
          <Row>
            <Card style={{ width: "100%" }}>
              <CardBody>
                <FormGroup>
                  <Label htmlFor="title" className="col-md-12 col-form-label">
                    Eğitim Başlığı
                  </Label>
                  <Col md={12}>
                    <AvField
                      name="title"
                      value={title}
                      validate={{ required: true }}
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder=""
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="title" className="col-md-12 col-form-label">
                    Eğitim İçeriği
                  </Label>
                  <Col md={12}>
                    <AvField
                      name="content"
                      value={content}
                      validate={{ required: true }}
                      type="textarea"
                      rows="8"
                      className="form-control"
                      id="content"
                      placeholder=""
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="weblink" className="col-md-12 col-form-label">
                    Eğitim Linki(varsa)
                  </Label>
                  <Col md={12}>
                    <AvField
                      name="weblink"
                      value={weblink}
                      type="text"
                      className="form-control"
                      id="weblink"
                      placeholder=""
                      onChange={(e) => setWeblink(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <br />
                  <Label htmlFor="title" className="col-md-12 col-form-label">
                    Eğitim Materyalleri(varsa)
                  </Label>
                  <Col md={12}>
                    <span>
                      Eğer dosya(görsel, video, vb) eklemek istiyorsanız,
                      aşağıdaki alanı kullanabilirsiniz{" "}
                    </span>
                  </Col>
                  <Col md={12}>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleAcceptedFiles(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                            </div>
                            <span>
                              Tıklayarak veya sürükleyerek dosya
                              yükleyebilirsiniz
                            </span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  {f.name}

                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <br />
                  <Label htmlFor="title" className="col-md-12 col-form-label">
                    Eğitim Kapak Görseli
                  </Label>
                  <Col md={12}>
                    <span>
                      Eğer kapak görseli eklemek istiyorsanız, aşağıdaki alanı
                      kullanabilirsiniz
                    </span>
                  </Col>
                  <Col md={12}>
                    <Dropzone
                      accept="image/*"
                      multiple={false}
                      onDrop={(acceptedFiles) =>
                        handleAcceptedThumbnail(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                            </div>
                            <span>
                              Tıklayarak veya sürükleyerek dosya
                              yükleyebilirsiniz
                            </span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedThumbnail && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={selectedThumbnail.name}
                                  src={selectedThumbnail.preview}
                                />
                              </Col>
                              <Col>
                                {selectedThumbnail.name}

                                <p className="mb-0">
                                  <strong>
                                    {selectedThumbnail.formattedSize}
                                  </strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}
                    </div>
                  </Col>
                </FormGroup>

                <Col md={6}>
                  <Button
                    color="primary"
                    className=" waves-effect waves-light mr-1 mt-2"
                    type="submit"
                  >
                    Ekle
                  </Button>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
export default CreateEducationModal;
